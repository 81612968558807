import React, { useEffect, useRef, useState } from "react";
import ToolBoxTalkDetail from "../component/toolBoxTalkDetail/toolBoxTalkDetail";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { useNavigate } from "react-router-dom";
import { API_STATUS, USER_ID } from "../../../constant/constant";
import SuccessScreen from "../../../library/custom/success/successScreen";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import { TOOL_BOX_LIST } from "../../../constant/routeContant";
import * as assetsAction from "../../../redux/actions/assetsAction";
import * as userAction from "../../../redux/actions/userAction";
import { fileUpload } from "../../../services/fileUploadService";
import { validateFileSize } from "../../../utils/helper";

const ToolBoxTalkDetailContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = window.location.search.slice(
    4,
    window.location.search?.length
  );
  const toolboxId = location?.state?.toolboxTalkId ?? queryString;
  const scheduleId = location?.state?.scheduleId;
  const assignedOn = location?.state?.assignedOn;
  const showDownloadBtn = location?.state?.showDownloadBtn;
  const fromAssignedTab = location?.state?.fromAssignedTab;
  const assignedOnAdmin = location?.state?.assignedOnAdmin;
  const toolboxtalkuyserlistPage = location?.state?.toolboxtalkuyserlist;
  const Tabvalue = location?.state?.tabvalue;
  const assignInstructorsUsersId = location?.state?.assignInstructorsUsersId;
  // const userInfo = USER_INFO();
  // const [completedBy, setcompletedBy] = useState(
  //   location?.state?.userName ??
  //     `${JSON.parse(userInfo).firstName ?? ""} ${
  //       JSON.parse(userInfo)?.lastName ?? ""
  //     }`
  // );

  const navigate = useNavigate();

  const showLoader = useSelector(
    (state) =>
      state.getToolBoxTalkView.isLoading ||
      state.fileUpload.isLoading ||
      state?.getUserGroupDetsilsData?.isLoading
  );
  const getToolBoxTalkDetail = useSelector((state) => state.getToolBoxTalkView);
  const deleteToolBoxTalkData = useSelector((state) => state.deleteToolBoxTalk);
  const userMarkedCompletedToolBox = useSelector(
    (state) => state.userMarkedCompletedToolBox
  );
  const userMarkedStatus = userMarkedCompletedToolBox?.status;
  const userMarkedIsloading = userMarkedCompletedToolBox?.isLoading;
  const [showVerify, setShowVerify] = useState(false);
  const [photos, setPhotos] = useState("");
  const [photosId, setphotosId] = useState("");

  /**Release 1.1 start  */

  const safetyDetail = location?.state?.safetyDetail;
  const isCurrentActiveContent = location?.state?.isCurrentActiveContent;
  const fromCompletedTab = location?.state?.fromCompletedTab;

  const [assetTypeError, setAssetTypeError] = useState(false);
  const [customAssetValue, setCustomAssetValue] = useState("");
  const [asset, SetAsset] = useState("");
  const [assetValue, setAssetValue] = useState("");
  const [showAssign, setShowAssign] = useState(false);
  const [userType, setUserType] = useState("0");

  const [projectName, SetProjectName] = useState("");
  const [projectValue, setprojectValue] = useState("");
  const [userId, setUserId] = useState("");
  const [isProfileSignature, setIsProfileSignature] = useState(false);
  const [isAttendeeSignature, setIsAttendeeSignature] = useState(false);
  const [fileUploadData, setfileUploadData] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [attendeeSignature, setAttendeeSignature] = useState("");
  const [imageCheck, setImageCheck] = useState(false);

  const [usersValue, setUsersValue] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("");
  const [selectedSignature, setSelectedSignature] = useState("");
  const [adduser, setAdduser] = useState("");
  const [isbtnClick, setisbtnClick] = useState(false);
  const [Filedata, setFiledata] = useState([]);
  const userInfo1 = JSON.parse(localStorage.getItem("userInfo"));
  const isSignatureProfile = userInfo1?.signatureUrl;

  /**
   * Use selector Value ====================================
   */
  const assetOption = useSelector((state) => state.getAssetDynomoList?.data);

  const allGroups = useSelector((state) => state.searchAllGroup);
  const getUserGroupDetsilsData = useSelector(
    (state) => state?.getUserGroupDetsilsData
  );

  const isLoading = useSelector(
    (state) =>
      state.getAssetList.isLoading ||
      state.searchAllUserProjectWise.isLoading ||
      state.getAssetDynomoList?.isLoading ||
      state.searchAllGroup?.isLoading
  );
  const getScheduleMeetingDetailData = useSelector(
    (state) => state.getScheduleMeetingDetail
  );

  const [assetType, setAssetType] = useState("");
  const [projectNameValue, setProjectNameValue] = useState(0);
  const instructorUserContractors = getScheduleMeetingDetailData?.users;
  const [alreadyScheduleDisable, setalreadyScheduleDisable] = useState(false);
  const [alreadyScheduleAssetName, setalreadyScheduleAssetName] =
    useState(false);
  const [signaureFileErrorMessage, setSignaureFileErrorMessage] = useState();
  const [signaureFileError, setSignaureFileError] = useState();
  const [signatureUrl, setSignatureUrl] = useState();
  const [inspectorSignUpload, setInspectorSignUpload] = useState(false);
  const [signatureError, setSignatureError] = useState(false);
  const [selectedContractorSignature, setSelectedContractorSignature] =
    useState("");
  useEffect(() => {
    dispatch(userAction.searchAllUsersClear());
  }, []);

  useEffect(() => {
    if (getScheduleMeetingDetailData) {
      setAssetType(getScheduleMeetingDetailData?.assetType);
      setCustomAssetValue({
        key: getScheduleMeetingDetailData?.assetId ?? "",
        value: getScheduleMeetingDetailData?.assetName ?? "",
      });
      setProjectNameValue({
        key: getScheduleMeetingDetailData?.assetId ?? "",
        value: getScheduleMeetingDetailData?.assetName ?? "",
      });
      SetProjectName(getScheduleMeetingDetailData?.assetId);
      SetAsset(getScheduleMeetingDetailData?.assetId);

      if (getScheduleMeetingDetailData?.assetType > 0) {
        setalreadyScheduleDisable(true);
      } else {
        setalreadyScheduleDisable(false);
      }
      if (
        getScheduleMeetingDetailData?.assetId &&
        getScheduleMeetingDetailData?.assetName
      ) {
        setalreadyScheduleAssetName(true);
      } else {
        setalreadyScheduleAssetName(false);
      }
    }
  }, [getScheduleMeetingDetailData]);

  const hiddenSignatureInput = useRef(null);
  const autoComp = useRef(null);
  const hiddenAttendeeSignatureInput = useRef([]);
  const allUsers = useSelector((state) => state.allUsers);
  const isAutoSearchDataLoading = allUsers?.isLoading;

  /**
   * Functions and useffect
   * @param {*} groupId
   */

  useEffect(() => {
    if (deleteToolBoxTalkData?.status === API_STATUS.SUCCESS) {
      dispatch(toolboxAction.clearToolBoxTalk());
      navigate(TOOL_BOX_LIST, {
        state: { toolboxId: toolboxId },
      });
    }
  }, [deleteToolBoxTalkData]);

  useEffect(() => {
    const data = {
      assignInstructorsUsersId:
        assignInstructorsUsersId ||
        location?.state?.safetyDetail?.instructorUserId,
      toolboxId: toolboxId || location?.state?.safetyDetail?.toolboxTalkId,
      toolboxTalkVersionId:
        isCurrentActiveContent && isCurrentActiveContent === true
          ? 0
          : location?.state?.toolboxTalkVersionId,
    };
    dispatch(toolboxAction.getToolBoxTalkDetails(data));
  }, []);

  useEffect(() => {
    if (fromAssignedTab) {
      {
        const dataSchedule = {
          scheduleDate: safetyDetail?.assignedOn || "",
          scheduleId: safetyDetail?.scheduleId ? safetyDetail?.scheduleId : "",
          userId: parseInt(USER_ID()),
          assignType: "",
        };
        dispatch(toolboxAction.getScheduleSafetyMeetingDetails(dataSchedule));
      }
      setisbtnClick(false);
    }
  }, []);

  const successScree = (id, message) => {
    return (
      <SuccessScreen
        isUpdate={message}
        route="/safety/meeting/user"
        id={id}
        label2="Congrats! You have successfully completed the Safety Meeting"
        label1="Safety ID"
        label4="Safety in "
      ></SuccessScreen>
    );
  };
  const getUserGroupDetail = (groupId) => {
    dispatch(userAction.getUserGroupDetails(groupId));
  };
  const deleteToolBoxTalks = (toolboxId) => {
    dispatch(toolboxAction.deleteToolBoxTalk(toolboxId));
  };

  const instructorMarkedToolBoxTalks = (data) => {
    dispatch(toolboxAction.UserMarkedCompletedToolBoxTalk(data));
  };

  const OnClickAssignAttendee = () => {
    setShowAssign(true);
  };
  const handleUserType = (e) => {
    setUserType(e);
    setUsersValue("");
    setUsers("");
  };
  const OnClickVerifyAll = () => {
    setShowVerify(true);
  };
  const handleFileChange = (photos, id, index) => {
    setPhotos(photos);
    const urlArray = photos.split("|");
    const data1 = id?.map((idval, index) => {
      let dt;
      return (dt = {
        fileId: id[index],
        filePath: urlArray[index],
        subrootId: 0,
      });
    });
    const uniqueFiles = [
      ...new Map(data1.map((file) => [file.fileId, file])).values(),
    ];
    setFiledata(uniqueFiles);
  };
  const deleteFiletrdata = (url) => {
    const filteredFiles = Filedata.filter((file) => file.filePath !== url);
    setFiledata(filteredFiles);
  };
  const handleDeleteImageURL = (urls, index) => {
    deleteFiletrdata(urls);

    const previousImg = photos.split("|");
    setPhotos(previousImg.filter((url) => url !== urls).join("|"));
    setphotosId("");
  };

  const onChangeAssetType = (event) => {
    setAssetType(event?.target?.value);
    setProjectNameValue("");
    setCustomAssetValue("");
    setUsersValue("");
    setUsersList([]);
    setCustomAssetValue("");
    setUsersGroup("");
    setAdduser("");
    setUsers("");
  };

  const searchAsset = (searchText, type) => {
    dispatch(assetsAction.getAssetDynomoList({ searchText, type }));
  };

  const onSelectSearchAsset = (event, value) => {
    setCustomAssetValue({ key: value?.key ?? "", value: value?.value ?? "" });
    setAssetValue("");
    SetAsset(value?.key);
    setUsersList([]);
  };

  const onSelectSearchProject = (event, value) => {
    setProjectNameValue({ key: value?.key ?? "", value: value?.value ?? "" });
    setprojectValue("");
    SetProjectName(value?.key);
    setUsersList([]);
  };

  const searchUsers = (
    searchText,
    searchType,
    divisionId,
    assetType,
    assetId,
    priviledgeId,
    productId,
    orgId,
    withSpace = true
  ) => {
    dispatch(
      userAction.searchAllUsers(
        searchText,
        searchType,
        divisionId,
        assetType,
        assetId,
        priviledgeId,
        productId,
        orgId,
        withSpace
      )
    );
  };
  const typeOnchange = (event, value, USER_TYPE) => {
    const nameOrAsset =
      assetType === 1 ? projectName : assetType === 4 ? asset : 0;
    const assetTypeFilter = assetType === 1 || assetType === 4 ? assetType : 0;
    {
      searchUsers(
        event.target.value,
        USER_TYPE,
        0,
        nameOrAsset,
        assetTypeFilter,
        0
      );
    }
  };

  const onSelectUsers = (value) => {
    setUsersValue({
      key: value?.key ?? "",
      value: value?.value ?? "",
    });
    setUsers(value);
  };
  /**
   * group Add Btn
   */
  const [groupUsersIds, setGroupUsersIds] = useState([]);
  const [userGroupList, setUsersGroupList] = useState([]);
  const [usersGroup, setUsersGroup] = useState("");
  const [groupUsers, setGroupUsers] = useState([]);

  const addGroupUserData = () => {
    const index = userList?.findIndex(
      (obect) => obect.groupId == usersGroup?.key
    );
    if (index === -1 && usersGroup) {
      setUsersGroup("");
      setUsersGroupList([...userGroupList, usersGroup]);
      setGroupUsersIds([...groupUsersIds, usersGroup?.key]);
      const groupsDatas =
        getUserGroupDetsilsData &&
        getUserGroupDetsilsData?.data?.result?.userIds.map((user) => {
          return {
            key: user?.key,
            value: user?.value,
            gpName: getUserGroupDetsilsData?.data?.result?.value,
            externalFirstName: "",
            externalLastName: "",
            groupId: getUserGroupDetsilsData?.data?.result?.groupId,
            file: { fileId: 0, filePath: "", subrootId: 0 },
            userId: parseInt(user?.key),
          };
        });
      if (groupsDatas) {
        setUsersList([...userList, ...groupsDatas]);
      }
      const ele = autoComp?.current?.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  /**
   * Add Indivituoal User Add Button
   */

  const [users, setUsers] = useState(1);
  const [userList, setUsersList] = useState([]);
  const [individualIds, setindividualIds] = useState([]);
  const autoCompUser = useRef(null);

  const addUserData = () => {
    if (!users?.key) return;
    const index = userList.findIndex((object) => object.key === users?.key);
    if (index === -1) {
      setindividualIds([...individualIds, users?.key]);

      const stateObj = {
        ...users,
        imgUrl: "",
        imgId: "",
        externalFirstName: "",
        externalLastName: "",
        userId: parseInt(users?.key),
        file: { fileId: 0, filePath: "", subrootId: 0 },
      };
      setUsersList([...userList, stateObj]);
    }
    const ele = autoCompUser?.current?.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele?.click();
  };
  const removeImage = (userKey) => {
    const cloneUser = [...userList];
    cloneUser.forEach((user) => {
      if (user.key === userKey) {
        user.imgUrl = "";
        user.file = { fileId: 0, filePath: "", subrootId: 0 };
        // user.file.filePath = "";
      }
    });
    setUsersList(cloneUser);
  };
  const attendeesignatureUpload = (event, keyId) => {
    setUserId(keyId);
    const fileUploaded = event.target.files[0];

    handleAttendeeSignatureUploadMethod(fileUploaded);
  };
  const handleAttendeeSignatureUploadMethod = (data) => {
    setIsAttendeeSignature(true);
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setfileUploadData(files);
      })
      .catch((error) => { });
  };
  const deleteData = (id) => {
    const updateList = userList.filter((list) => list.key !== id);
    const updateAssignIds = individualIds.filter((list) => list !== id);
    setUsersList(updateList);
    setindividualIds(updateAssignIds);
    setUsersGroupList(updateList);
    setGroupUsersIds(updateAssignIds);
  };
  function handleAllGroupSearch(
    serachText,
    divisionId,
    alldivision,
    assetType,
    assetId,
    withSpace = true
  ) {
    dispatch(
      userAction.searchAllGroup(
        serachText,
        divisionId,
        alldivision,
        "",
        assetType,
        assetId,
        withSpace
      )
    );
  }
  const onChangeUsersGroup = (value) => {
    setUsersGroup(value);
  };
  useEffect(() => {
    if (usersGroup) {
      getUserGroupDetail(usersGroup?.key);
    }
  }, [usersGroup]);

  useEffect(() => {
    if (isProfileSignature) {
      if (fileUploadData && imageCheck) {
        setSelectedSignature(fileUploadData?.files[0]?.fileUrl);
      }
    } else {
      if (fileUploadData && imageCheck) {
        setSelectedProfile(fileUploadData?.files[0]?.fileUrl);
      }
    }
  }, [fileUploadData, imageCheck]);

  useEffect(() => {
    if (isAttendeeSignature) {
      const cloneUser = [...userList];
      if (fileUploadData) {
        cloneUser.forEach((user) => {
          if (user?.key === userId) {
            user.imgUrl = fileUploadData?.files[0]?.fileUrl;
            user.imgId = fileUploadData?.files[0]?.id;
            user.file.filePath = fileUploadData?.files[0]?.fileUrl;
            user.file.fileId = fileUploadData?.files[0]?.id;
          }
        });
      }
      setUsersList(cloneUser);
    }
  }, [fileUploadData]);

  const onChangeAdduser = (event) => {
    setAdduser(event?.target?.value);
  };
  const addAdditionalUser = () => {
    if (adduser) {
      var id = "id" + Math.random().toString(16).slice(2);
      setUsersList([
        ...userList,
        {
          key: id,
          value: adduser,
          externalFirstName: adduser,
          externalLastName: "",
          file: { fileId: 0, filePath: "", subrootId: 0 },
          userId: 0,
        },
      ]);
    }
    setAdduser("");
  };

  const mappedData = () => {
    let errors = false;

    if (errors) {
      return false;
    }
    const finalUserListData = userList?.map((val) => {
      return {
        externalFirstName: val?.externalFirstName,
        externalLastName: val?.externalLastName,
        userId: val?.userId,
        file: val?.file,
      };
    });
    const data = {
      instructorUserId: safetyDetail?.instructorUserId ?? null,
      scheduleId: safetyDetail?.scheduleId ?? 0,
      assetId: assetType === 1 ? projectName : assetType === 4 ? asset : 0,
      assetType: assetType === 1 || assetType === 4 ? assetType : assetType === "N/A" ? 0 : 0,
      //assetType: assetType === 1 || assetType === 4 ? assetType : 0,
      attendeesList: finalUserListData,
      file: Filedata,
      createdBy: parseInt(USER_ID()),
      toolboxTalkId: safetyDetail?.toolboxTalkId ?? toolboxId,
      toolboxTalkVersionId: safetyDetail?.toolboxTalkVersionId,
      signatureUrl: isSignatureProfile ? isSignatureProfile : signatureUrl,
    };
    return data;
  };
  const submitData = () => {
    setisbtnClick(true);
    const data = mappedData();
    if (!data) {
    } else {
      instructorMarkedToolBoxTalks(data);
    }
  };
  /**
   *
   * @param {signature upload } data
   * @returns
   */

  const uploadInspectorsignatureFile = (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setSignaureFileErrorMessage(validation.errorMessage);
      setSignaureFileError(true);
      return;
    }
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setSignatureUrl(files?.files[0]?.fileUrl);
        setSignaureFileError(false);
        setInspectorSignUpload(true);
        setSignatureError(false);
      })
      .catch((error) => {
        setSignaureFileErrorMessage("Error while uploading files.");
        setSignaureFileError(true);
      });
  };

  const inspectorsignatureUpload = (event) => {
    const fileUploaded = event.target.files[0];

    handleInspectorsignatureUploadMethod(fileUploaded);
  };
  const handleInspectorsignatureUploadMethod = (data) => {
    uploadInspectorsignatureFile(data);
  };
  const getScheduleAttendeesDetailsData123 = useSelector(
    (state) => state.getScheduleAttendeesDetails
  );
  return (
    <>
      {(showLoader || isLoader) && <Loader />}
      {userMarkedIsloading && <Loader />}
      {userMarkedStatus == API_STATUS.SUCCESS && isbtnClick ? (
        successScree(
          safetyDetail?.scheduleId,
          "Safety Meeting Completed Successfully"
        )
      ) : (
        <>
          <ToolBoxTalkDetail
            getToolBoxTalkDetail={getToolBoxTalkDetail}
            toolboxId={toolboxId}
            deleteToolBoxTalks={deleteToolBoxTalks}
            scheduleId={scheduleId}
            instructorUserContractors={getScheduleMeetingDetailData}
            assignedOn={assignedOn}
            assignedOnAdmin={assignedOnAdmin}
            assignInstructorsUsersId={assignInstructorsUsersId}
            toolboxtalkuyserlistPage={toolboxtalkuyserlistPage}
            Tabvalue={Tabvalue}
            showAssign={showAssign}
            OnClickAssignAttendee={OnClickAssignAttendee}
            userType={userType}
            setUserType={setUserType}
            handleUserType={handleUserType}
            showVerify={showVerify}
            OnClickVerifyAll={OnClickVerifyAll}
            photos={photos}
            handleFileChange={handleFileChange}
            handleDeleteImageURL={handleDeleteImageURL}
            setAssetTypeError={setAssetTypeError}
            assetTypeError={assetTypeError}
            onChangeAssetType={onChangeAssetType}
            assetType={assetType}
            setAssetType={setAssetType}
            searchAsset={searchAsset}
            assetOption={assetOption}
            isLoading={isLoading}
            customAssetValue={customAssetValue}
            onSelectSearchAsset={onSelectSearchAsset}
            assetValue={assetValue}
            onSelectSearchProject={onSelectSearchProject}
            setProjectNameValue={setProjectNameValue}
            projectNameValue={projectNameValue}
            safetyDetail={safetyDetail}
            isAutoSearchDataLoading={isAutoSearchDataLoading}
            typeOnchange={typeOnchange}
            allUsers={allUsers && allUsers?.data}
            users={users}
            setUsers={setUsers}
            autoCompUser={autoCompUser}
            onSelectUsers={onSelectUsers}
            setUsersValue={setUsersValue}
            usersValue={usersValue}
            addUserData={addUserData}
            userList={userList}
            setUsersList={setUsersList}
            hiddenAttendeeSignatureInput={hiddenAttendeeSignatureInput}
            removeImage={removeImage}
            attendeesignatureUpload={attendeesignatureUpload}
            deleteData={deleteData}
            userGroups={allGroups?.data}
            handleAllGroupSearch={handleAllGroupSearch}
            usersGroup={usersGroup}
            onChangeUsersGroup={onChangeUsersGroup}
            autoComp={autoComp}
            addGroupUserData={addGroupUserData}
            attendeeSignature={attendeeSignature}
            setImageCheck={setImageCheck}
            isProfileSignature={isProfileSignature}
            adduser={adduser}
            onChangeAdduser={onChangeAdduser}
            addAdditionalUser={addAdditionalUser}
            submitData={submitData}
            alreadyScheduleDisable={alreadyScheduleDisable}
            projectName={projectName}
            asset={asset}
            alreadyScheduleAssetName={alreadyScheduleAssetName}
            fromCompletedTab={fromCompletedTab}
            showDownloadBtn={showDownloadBtn}
            //completedBy={completedBy}
            isSignatureProfile={isSignatureProfile}
            inspectorsignatureUpload={inspectorsignatureUpload}
            signatureUrl={signatureUrl}
            inspectorSignUpload={inspectorSignUpload}
            selectedContractorSignature={selectedContractorSignature}
            getScheduleAttendeesDetailsData123={
              getScheduleAttendeesDetailsData123
            }
            signaureFileError={signaureFileError}
            signaureFileErrorMessage={signaureFileErrorMessage}
          />
        </>
      )}
    </>
  );
};

export default ToolBoxTalkDetailContainer;