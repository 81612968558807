import React, { useState } from "react";
import Buttons from "../../../library/custom/button/Button";
import SearchIcon from "@mui/icons-material/Search";
import GridTable from "../../../library/custom/gridTable/GridTable";
import SettingTable from "../../incidents/incidentList/SettingTable";
import {
  HBNEXT_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
} from "../../../constant/constant";
import ReportRowManipulte from "../../incidents/incidentList/ReportRowManipulte";
import NORow from "../../../library/custom/noRow/noRow";
import { useNavigate } from "react-router-dom";
import { SETUP_ADD_CREDENTIAL } from "../../../constant/routeContant";
import { TableLoader } from "../../../library/common/Loader";
import FilterListIcon from "@mui/icons-material/FilterList";
import { checkPrivileges } from "../../../utils/rolesHelper";
import UserFilterList from "../../admin/shared/UserFilterList ";
import downloadIcon from "../../../assets/downloadIcon.svg";

export default function SetupCredentialList({
  NavigateAddCredentials,
  credentialListData,
  isLoading,
  getCredentials,
  credentialTypes,
}) {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [anchorEl, setAnchorEl] = useState(null);
  const [credentialListApiData, setCredentialListApiData] = useState({
    organisationId: ORG_ID(),
    page: 1,
    pageSize: pageSize,
    search: "",
    credentialTypeId: "",
    usedId: "",
    isSendMail: false,
  });

  const [isTableLoading, setisTableLoading] = useState(false);

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnclick = (rows) => {
    navigate(SETUP_ADD_CREDENTIAL, {
      state: { credentialNameId: rows.row.id, editMode: true },
    });
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      ...credentialListApiData,
      search: searchText ? searchText : "",
      page: 1,
      isSendMail: false,
    };
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setCredentialListApiData({
      ...credentialListApiData,
      search: searchText ? searchText : "",
      isSendMail: false,
    });

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getCredentials(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      ...credentialListApiData,
      page: newPage + 1,
      isSendMail: false,
    };
    setCredentialListApiData({
      ...credentialListApiData,
      page: newPage + 1,
      isSendMail: false,
    });
    getCredentials(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      ...credentialListApiData,
      page: 1,
      pageSize: newPazeSize,
      isSendMail: false,
    };
    setCredentialListApiData(data);

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getCredentials(data);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [credentialType, setCredentialType] = useState();

  const onChangeType = (item) => {
    setCredentialType(item.target.value.toString());
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const handleSearch = (data) => {
    setAnchorEl(null);
    getCredentials(data);
  };

  const handleApply = (state) => {
    setCredentialListApiData({
      ...credentialListApiData,
      credentialTypeId: credentialType ? credentialType : 0,
      isSendMail: false,
    });
    setPage(1);

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setAnchorEl(null);
    const data = {
      ...credentialListApiData,
      page: 1,
      pageSize: pageSize,
      credentialTypeId: credentialType ? credentialType : 0,
      organisationId: ORG_ID(),
      isSendMail: false,
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getCredentials(data);
  };

  const handleClear = () => {
    setCredentialType("");
    setSearch("");
    setFilter({ searchType: [] });
    setCredentialListApiData({
      ...credentialListApiData,
      credentialTypeId: "",
      isSendMail: false,
    });
    setPage(1);

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    const data = {
      page: 1,
      pageSize: pageSize,
      credentialTypeId: "",
      organisationId: ORG_ID(),
      search: "",
      isSendMail: false,
    };
    setCredentialListApiData({
      ...credentialListApiData,
      page: 1,
      pageSize: pageSize,
      search: "",
      credentialTypeId: "",
    });
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    handleSearch(data);
  };

  const [filter, setFilter] = useState({
    searchType: [],
  });

  const credcloumns = [
    { field: "id", headerName: "Credential Type ID", flex: 3 },
    { field: "credentialName", headerName: "Credential Type Name", flex: 4 },
    { field: "issuingBodies", headerName: "Issuing Body", flex: 5 },
    { field: "expirationPeriod", headerName: "Expiry Period", flex: 4 },
    { field: "credentialType", headerName: "Credential Type", flex: 4 },
    {
      field: "",
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          (parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) ||
            !rows?.row?.isGlobal) &&
          checkPrivileges([12, 13]) && (
            <ReportRowManipulte
              rows={rows}
              credentialLists={true}
              editOnClick={() => handleOnclick(rows)}
              edit={checkPrivileges([12, 13])}
              editLabel="Edit Credential Type"
              vie
              download={false}
              view={false}
              deleted={false}
              deleteLabel="Delete Credential"
            />
          )
        );
      },
    },
  ];

  const sendEmail = () => {
    const data = {
      ...credentialListApiData,
      page: page,
      pageSize: pageSize,
      isSendMail: true,
    };
    setCredentialListApiData(data);
    getCredentials(data);
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Credential Types</div>
          <div>
            <div className="filter_btn">
              <Buttons
                variant="contained"
                type="button"
                name="btn"
                label={"Add Credential Type"}
                varientAddIconBlue={true}
                onClick={NavigateAddCredentials}
              ></Buttons>
            </div>
          </div>
        </div>
        <div className="grid_main_body">
          <div className="div-filter-container">
            <div className="filter_of_caselist">
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>

              <div className="filter_btn">
                <button
                  aria-describedby={id}
                  variant="contained"
                  type="button"
                  name="btn"
                  onClick={(e) => handleClick(e)}
                >
                  <FilterListIcon id="icn" /> Filters
                </button>
                <UserFilterList
                  firstLabel="Credential Type"
                  secondLabel="Created By"
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  handleApply={handleApply}
                  handleClear={handleClear}
                  firstLebelFilter={credentialTypes}
                  isShow={true}
                  firstLebelOnChanges={onChangeType}
                  firstLebelValue={credentialType}
                  resetBtn={true}
                />
              </div>
              {/* <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>

              <FilterCommonStatusSelect
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleApply={handleApply}
                handleClear={handleClear}
                filter={filter}
                statusType={credentialTypes}
                onChangeType={onChangeType}
                statusIds={credentialType}
                filterStatus={true}
                resetBtn={true}
                label={"Credential Type"}
              />
            </div> */}
            </div>
            <div className="div-download-email-right" onClick={sendEmail}>
              <span>
                <img
                  src={downloadIcon}
                  className="DownLoadLogo"
                  alt="DownLoad Logo"
                />
              </span>
              Download Data
            </div>
          </div>
          {isTableLoading ? (
            ""
          ) : (
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.id}
                  rows={credentialListData?.credentialData ?? []}
                  columns={credcloumns}
                  rowCount={credentialListData?.noOfRecords ?? 0}
                  loading={isLoading}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  page={page}
                  pagination
                  pageSize={pageSize}
                  onPageChange={handlePagignation}
                  checkboxSelection={false}
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
