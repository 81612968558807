import React, { useEffect, useState } from "react";
import ResourceList from "../component/resources/resourceList";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import * as resourceAction from "../../../redux/actions/resourceAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as clientAction from "../../../redux/actions/clientAction";
import * as assetsAction from "../../../redux/actions/assetsAction";
import {
  API_STATUS,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  ORG_NAME,
} from "../../../constant/constant";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_DENIED,
  ADMIN_ADD_RESOURCE,
  ADMIN_EDIT_RESOURCE,
} from "../../../constant/routeContant";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { toast, ToastContainer } from "react-toastify";
export default function ResourceContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [sendEmail, setSendEmail] = useState(false);
  const resources = useSelector((state) => state.resourceList);
  const projectId = location?.state?.projectId;
  const projectName = location?.state?.projectName;
  const customerId = location?.state?.customerId;
  const customerName = location?.state?.customerName;
  const [clientsValue, setClientsValue] = useState({
    key: customerId ?? "",
    value: customerName ?? "",
  });
  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    resourceTypes: "",
    startDate: "",
    endDate: "",
    catId: 0,
    search: "",
    userId: 0,
    orgId: customerId ? customerId : parseInt(ORG_ID()),
    assetId: projectId ? projectId : 0, // assetId is project id
    assetTypeId: parseInt(PRODUCT_ID()) === 2 ? 5 : 0, // assetTypeId ==5 for storm water ,0 for safety
    isSendMail: false,
  };

  useEffect(() => {
    getResourceListData(defaultData);
  }, []);

  const getResourceListData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(resourceAction.getResourceList(data));
  };

  const addResource = () => {
    navigate(ADMIN_ADD_RESOURCE, {
      state: { addMode: true },
    });
  };
  const allProject = useSelector((state) => state.getAssetDynomoList);
  const isProjectLoading = allProject?.isLoading;

  const deleteResource = useSelector((state) => state.deleteResource);
  useEffect(() => {
    if (deleteResource?.status === API_STATUS.SUCCESS) {
      getResourceListData(defaultData);
    }
  }, [deleteResource]);
  const handleDeleteOnClick = (resourceId) => {
    dispatch(resourceAction.deleteResource(resourceId));
  };
  const hbnextdata = {
    key: parseInt(ORG_ID()),
    value: ORG_NAME(),
  };
  const handleEditOnClick = (row) => {
    navigate(ADMIN_EDIT_RESOURCE, {
      state: {
        resourceId: row?.id,
        resourceType: row?.resourceTypeId,
        editMode: true,
        clientsValue: clientsValue.key ? clientsValue : hbnextdata,
      },
    });
  };

  const isLoading = useSelector(
    (state) => resources?.isLoading || state.deleteResource?.isLoading
  );

  const resourceCategory = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.resourceCategories
  );
  const resourceType = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.resourceTypes
  );
  useEffect(() => {
    const getDropdownDataKeyValueData = {
      orgId: LOGIN_ORG_ID() === ORG_ID() ? LOGIN_ORG_ID() : ORG_ID(),
      flags: [14, 15],
      productId: PRODUCT_ID(),
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  const searchAllClientsReducerData = useSelector(
    (state) => state.searchAllClientsReducerData
  );
  const autoSearchDataLoading = searchAllClientsReducerData.isLoading;
  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };

  function handleAllProjectSearch(event, value, customerId) {
    dispatch(
      assetsAction.getAssetDynomoList({
        searchText: event?.target?.value.trim(),
        type: 5,
        orgId: customerId,
      })
    );
  }

  useEffect(() => {
    if (resources?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Resource has been sent to registered email ID");
    }
  }, [resources]);

  return (
    <>
      {checkPrivileges([12, 73, 74, 75, 139]) ? (
        <>
          <ToastContainer />
          <ResourceList
            isLoading={isLoading}
            resources={isLoading ? [] : resources?.data?.result}
            getResourceListData={getResourceListData}
            addResource={addResource}
            isShow={true}
            filterShow={true}
            handleDeleteOnClick={handleDeleteOnClick}
            handleEditOnClick={handleEditOnClick}
            resourceCategory={resourceCategory}
            resourceType={resourceType}
            projectId={projectId}
            searchClient={searchClient}
            autoSearchDataLoading={autoSearchDataLoading}
            searchAllClientsReducerData={searchAllClientsReducerData}
            isProjectLoading={isProjectLoading}
            allProjects={allProject?.data}
            handleAllProjectSearch={handleAllProjectSearch}
            customerName={customerName}
            customerId={customerId}
            projectName={projectName}
            setClientsValue={setClientsValue}
            clientsValue={clientsValue}
            isShowDowdownloadButton={true}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}