import React, { useState } from "react";
import "../../../incidents/cases/addCaseList.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewcCasetable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { useNavigate } from "react-router-dom";
import UserFilterList from "../../shared/UserFilterList ";
import { TableLoader } from "../../../../library/common/Loader";
import downloadIcon from "../../../../assets/downloadIcon.svg";
import {
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import {
  ADD_ADMIN_USER,
  ADMIN_SAFETY_LOG,
} from "../../../../constant/routeContant";
import DialogBox from "../../../../library/common/DialogBox";
import NORow from "../../../../library/custom/noRow/noRow";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { encodeSearchText } from "../../../../utils/helper";

export default function UserList({
  NavigateSingleUser,
  userListData,
  loading,
  getuserLists,
  jobTitles,
  deleteList,
  isDivision = true,
  division,
  isShowDowdownloadButton = true,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const [search, setSearch] = useState("");
  const [jobTitle, setjobTitle] = useState("");
  const [divisionfilter, setDivisionFilter] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [isTableLoading, setisTableLoading] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isProcoreClient = userInfo?.isProcoreConnected;
  const ProcoreClientId = localStorage.getItem("IsProcoreConnected");
  const isClientSwitch = parseInt(ORG_ID()) !== parseInt(LOGIN_ORG_ID());

  let ShowField = false;

  if (isClientSwitch) {
    if (ProcoreClientId === "true") {
      ShowField = true;
    }
  } else {
    if (isProcoreClient) {
      ShowField = true;
    }
  }

  const navigate = useNavigate();

  const deletehandle = () => {
    setOpenModal(false);
    deleteList(selectedRow.userid);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openAdd = Boolean(anchorElAdd);
  const idAdd = openAdd ? "simple-popover" : undefined;

  const handleClickAdd = (event) => {
    NavigateSingleUser();
  };

  const handleautoSearch = (event) => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      pageSize: pageSize,
      search: searchText ? encodeSearchText(searchText) : "",
      orgId: ORG_ID(),
      designation: jobTitle,
      divisionId: divisionfilter ? divisionfilter : 0,
      IsProcoreUsers: false,
      isSendMail: false,
    };
    getuserLists(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getuserLists({
      page: newPage + 1,
      pageSize: pageSize,
      search: search ? encodeSearchText(search) : "",
      orgId: ORG_ID(),
      designation: jobTitle ? jobTitle : "",
      divisionId: divisionfilter ? divisionfilter : 0,
      IsProcoreUsers: false,
      isSendMail: false,
    });
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    getuserLists({
      page: page,
      pageSize: newPazeSize,
      search: search ? encodeSearchText(search) : "",
      orgId: ORG_ID(),
      designation: jobTitle ? jobTitle : "",
      divisionId: divisionfilter ? divisionfilter : 0,
      IsProcoreUsers: false,
      isSendMail: false,
    });
  };

  const handleViewOnClick = (rows) => {
    navigate(ADMIN_SAFETY_LOG, {
      state: { userId: rows.row, userSafetyLog: false },
    });
  };

  const handleOnclick = (rows) => {
    navigate(ADD_ADMIN_USER, {
      state: { userId: rows.row.userid, editMode: true },
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteIncidents = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const columns = [
    { field: "userid", headerName: "User ID", flex: 1.5 },
    { field: "userName", headerName: "Name", flex: 2 },
    { field: "jobtitle", headerName: "Job Title", flex: 2.3 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1.7 },
    { field: "email", headerName: "Email ID", flex: 2.3 },
    ...(ShowField
      ? [
          {
            field: "procoreUserId",
            headerName: "Procore User",
            flex: 3.5,
            renderCell: (params) => {
              return params.row.procoreUserId === null ? "No" : "Yes";
            },
          },
        ]
      : []),
    isDivision && {
      field: "",
      width: 68,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          isDivision &&
          rows?.row?.isOrganiztion === false && (
            <ReportRowManipulte
              rows={rows}
              safetyLogOnClick={() => handleViewOnClick(rows)}
              editOnClick={() => handleOnclick(rows)}
              deleteOnClick={() => deleteIncidents(rows?.row)}
              edit={checkPrivileges([64, 65, 12])}
              editLabel="Edit User"
              safetyLog={checkPrivileges([64, 65, 66, 12])}
              safetyLogLabel="Safety Log"
              deleted={checkPrivileges([64, 12])}
              deleteLabel="Delete User"
            />
          )
        );
      },
    },
  ];

  const handleSearch = (event) => {
    getuserLists({
      page: page,
      pageSize: pageSize,
      search: encodeSearchText(search),
      designation: jobTitle ? jobTitle : "",
      IsProcoreUsers: false,
      isSendMail: false,
      divisionId: divisionfilter ? divisionfilter : 0,
    });
  };
  const [designation, setDesignation] = useState("");
  const designationOnChanges = (e) => {
    let title = jobTitles?.find((x) => x.key === e.target.value)?.value;
    setjobTitle(title);
    setDesignation(e.target.value);
  };

  const divisionFilterOnChanges = (e) => {
    setDivisionFilter(e.target.value);
  };

  const onRowClick = (e) => {
    console.log("ROW CLICK");
  };

  const handleClear = () => {
    setDesignation("");
    setAnchorEl(null);
    setDivisionFilter("");
    setSearch("");
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    window.close();
    const data = {
      page: 1,
      pageSize: pageSize,
      search: "",
      designation: "",
      IsProcoreUsers: false,
      divisionId: 0,
      isSendMail: false,
    };
    getuserLists(data);
  };
  const handleApply = () => {
    setAnchorEl(null);
    window.close();
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    const data = {
      page: 1,
      pageSize: pageSize,
      search: search ? encodeSearchText(search) : "",
      designation: jobTitle,
      IsProcoreUsers: false,
      isSendMail: false,
      divisionId: divisionfilter ? divisionfilter : 0,
    };
    getuserLists(data);
  };

  const sendEmail = () => {
    const data = {
      page: page,
      pageSize: pageSize,
      search: encodeSearchText(search),
      designation: jobTitle ? jobTitle : "",
      IsProcoreUsers: false,
      divisionId: divisionfilter ? divisionfilter : 0,
      isSendMail: true,
    };
    getuserLists(data);
  };

  return (
    <>
      <section className="grid_main_sec">
        {isDivision && (
          <div className="grid_main_header two_grid">
            <div className="grid_heading_style">User Management</div>
            {openModal && (
              <DialogBox
                open={openModal}
                rows={[]}
                header={"Confirm Deletion"}
                deletehandle={deletehandle}
                handleCloseModal={() => setOpenModal(false)}
                content={"Are you sure you want to delete this User?"}
                view
              />
            )}
            <div>
              <div className="filter_btn">
                {checkPrivileges([12, 64, 65]) && (
                  <Buttons
                    id="btnAddUsers"
                    aria-describedby={idAdd}
                    varientAddIconBlue={true}
                    label={"Add User"}
                    onClick={(e) => handleClickAdd(e)}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="grid_main_body">
          <div className="div-filter-container">
            <div className="filter_of_caselist">
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>

              {isDivision && (
                <div className="filter_btn">
                  <button
                    aria-describedby={id}
                    variant="contained"
                    type="button"
                    name="btn"
                    onClick={(e) => handleClick(e)}
                  >
                    <FilterListIcon id="icn" /> Filters
                  </button>
                  <UserFilterList
                    firstLabel="Division"
                    secondLabel="Job Title"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleSearch={handleSearch}
                    handleApply={handleApply}
                    secondLebelFilter={jobTitles}
                    firstLebelFilter={division}
                    resetBtn={true}
                    handleClear={handleClear}
                    secondLebelOnChanges={designationOnChanges}
                    secondLebelValue={designation}
                    isShow={true}
                    firstLebelOnChanges={divisionFilterOnChanges}
                    firstLebelValue={divisionfilter}
                  />
                </div>
              )}
            </div>
            {isShowDowdownloadButton && (
              <div className="div-download-email-right" onClick={sendEmail}>
                <span>
                  <img
                    src={downloadIcon}
                    className="DownLoadLogo"
                    alt="DownLoad Logo"
                  />
                </span>
                Download Data
              </div>
            )}
          </div>
          {isTableLoading ? (
            ""
          ) : (
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <ViewcCasetable
                  getRowId={(r) => r.userid}
                  rows={userListData?.userData || []}
                  rowCount={userListData?.itemsCount}
                  onRowClick={onRowClick}
                  columns={columns}
                  loading={loading}
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                  checkboxSelection={false}
                  page={page}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
