import React from "react";
import Grid from "@mui/material/Grid";
import "./backBtn.scss";
import { useNavigate } from "react-router-dom";
import commonImages from "../../../assets";

const BackBtn = (props) => {
  const navigate = useNavigate();

  return (
    <Grid
      className="backBtn"
      onClick={() =>
        navigate(props?.dataBack?.route, {
          state: props?.dataBack?.state
            ? props?.dataBack?.state
            : {
                backTabId: props?.dataBack?.backTabId ?? "",
                projectName: props?.dataBack?.projectName,
                projectNumber: props?.dataBack?.projectNumber,
                projectid: props?.dataBack?.projectid,
                stateId: props?.dataBack?.stateId,
                customerName: props?.dataBack?.customerName,
                editMode: props?.dataBack?.editMode
                  ? props?.dataBack?.editMode
                  : false,
                islesson: props?.dataBack?.islesson,
                iscourseNavigatePage:
                  props?.dataBack?.iscourseNavigatePage ?? null,
              },
        })
      }
    >
      <Grid className="backImg">
        <svg
          width="10"
          height="17"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.5 1.5L1.5 8.5L8.5 15.5"
            stroke="#666666"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Grid>
      <Grid className="backTitle">{props?.dataBack?.title}</Grid>
    </Grid>
  );
};

export default BackBtn;
