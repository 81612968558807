import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GridTable from "../../../library/custom/gridTable/GridTable";
import Buttons from "../../../library/custom/button/Button";
import SettingTable from "../../incidents/incidentList/SettingTable";
import Loader, { TableLoader } from "../../../library/common/Loader";
import {
  EXPIRATION_TYPE,
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
  USERS_TYPE,
} from "../../../constant/constant";
import ReportRowManipulte from "../../incidents/incidentList/ReportRowManipulte";
import NORow from "../../../library/custom/noRow/noRow";
import downloadIcon from "../../../assets/downloadIcon.svg";
import {
  ADMIN_ADD_CREDENTIAL,
  VIEW_ADMIN_CREDENTIAL,
} from "../../../constant/routeContant";
import SearchIcon from "@mui/icons-material/Search";
import DialogBox from "../../../library/common/DialogBox";
import UserFilterList from "../../admin/shared/UserFilterList ";
import FilterListIcon from "@mui/icons-material/FilterList";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { isDateExpired } from "../../../utils/helper";

function AdminCredentialList({
  navigateAddCredential,
  credentialListData,
  isLoading,
  getUserCredentials,
  isProfile = false,
  credentialTypes,
  deleteCredentials,
}) {
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedrow, setselectedRow] = useState([]);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [credentialsType, setcredentialType] = useState("");
  const [expirationType, setExpirationType] = useState("");
  const [userTypes, setUserTypes] = useState("");
  const [isTableLoading, setisTableLoading] = useState(false);
  const [credentialListApiData, setCredentialListApiData] = useState({
    orgId: ORG_ID(),
    page: 1,
    pageSize: pageSize,
    search: "",
    credentialType: "",
    userId: 0,
    userType: 0,
    isSendMail: false,
  });

  const credentialTypeOnChanges = (e) => {
    setcredentialType(String(e.target.value));
  };

  const expirationTypeOnChanges = (e) => {
    setExpirationType(e.target.value);
  };

  const userTypeOnChanges = (e) => {
    setUserTypes(e.target.value);
  };

  const deletehandle = () => {
    setOpenModal(false);
    deleteCredentials(selectedrow);
  };

  const deleteCredential = (rows) => {
    setOpenModal(true);
    setselectedRow(rows);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const navigate = useNavigate();
  const handleEditOnclick = (rows) => {
    navigate(ADMIN_ADD_CREDENTIAL, {
      state: {
        credentialId: rows.row.id,
        editMode: true,
      },
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearch = (data) => {
    setAnchorEl(null);

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getUserCredentials(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      ...credentialListApiData,
      isSendMail: false,
      search: searchText ? searchText : "",
      page: 1,
    };
    setPage(1);

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 300);
    setCredentialListApiData({
      ...credentialListApiData,
      isSendMail: false,
      search: searchText ? searchText : "",
      page: 1,
    });
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getUserCredentials(data);
  };

  const handleApply = (state) => {
    setCredentialListApiData({
      ...credentialListApiData,
      credentialType: credentialsType,
      isSendMail: false,
      userType: userTypes ? userTypes : 0,
      orgId: ORG_ID(),
      expirationType: expirationType === "all" ? 0 : expirationType,
      page: 1,
    });
    setAnchorEl(null);
    setPage(1);

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 300);
    const data = {
      ...credentialListApiData,
      page: 1,
      pageSize: pageSize,
      isSendMail: false,
      credentialType: credentialsType,
      userType: userTypes ? userTypes : 0,
      expirationType: expirationType === "all" ? 0 : expirationType,
      orgId: ORG_ID(),
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getUserCredentials(data);
  };

  const handleClear = () => {
    setcredentialType("");
    setUserTypes("");
    setCredentialListApiData({
      ...credentialListApiData,
      credentialType: "",
      expirationType: 0,
      userType: 0,
      orgId: ORG_ID(),
      isSendMail: false,
    });
    setPage(1);

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 300);
    const data = {
      page: 1,
      pageSize: pageSize,
      search: "",
      credentialType: "",
      userId: 0,
      userType: 0,
      expirationType: 0,
      orgId: ORG_ID(),
      isSendMail: false,
    };
    setSearch("");
    setCredentialListApiData({
      ...credentialListApiData,
      page: 1,
      pageSize: pageSize,
      search: "",
      credentialType: "",
      userId: 0,
      expirationType: 0,
      userType: 0,
      isSendMail: false,
    });
    handleSearch(data);
  };

  const handleViewOnclick = (rows) => {
    navigate(VIEW_ADMIN_CREDENTIAL, {
      state: { credentialId: rows.row.id, editMode: true },
    });
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      navigate(VIEW_ADMIN_CREDENTIAL, {
        state: { credentialId: rows.row.id, editMode: true },
      });
    }
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      ...credentialListApiData,
      page: newPage + 1,
      isSendMail: false,
    };
    setCredentialListApiData({
      ...credentialListApiData,
      page: newPage + 1,
      isSendMail: false,
    });
    getUserCredentials(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      ...credentialListApiData,
      pageSize: newPazeSize,
      page: 1,
      isSendMail: false,
    };
    setCredentialListApiData(data);

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getUserCredentials(data);
  };

  const sendEmail = () => {
    const data = {
      ...credentialListApiData,
      pageSize: pageSize,
      page: page,
      isSendMail: true,
    };
    getUserCredentials(data);
  };

  const columns = [
    { field: "id", headerName: "Credential ID", flex: 2.5 },
    { field: "userName", headerName: "User Name", flex: 3.5 },
    { field: "credentialName", headerName: "Credential Name", flex: 3.5 },
    { field: "issuingBody", headerName: "Issuing Body", flex: 3 },
    { field: "credentialType", headerName: "Credential Type", flex: 3.5 },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      flex: 3.5,
      renderCell: (rows) => {
        return rows?.row?.expirationDate === null ? (
          "None"
        ) : (
          <div
            className={
              isDateExpired(rows?.row?.expirationDate) ? "expDate" : ""
            }
          >
            {rows?.row?.expirationDate}
          </div>
        );
      },
    },
    {
      field: "",
      width: 70,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            credentialLists={true}
            edit={checkPrivileges([12, 13, 47])}
            editOnClick={() => handleEditOnclick(rows)}
            viewOnClick={() => handleViewOnclick(rows)}
            deleteOnClick={(row) => deleteCredential(row?.id)}
            editLabel={"Edit Credential"}
            configure={false}
            download={false}
            viewLabel="View Credential"
            view={checkPrivileges([12, 13, 47, 48])}
            deleted={checkPrivileges([12, 13])}
            deleteLabel="Delete Credential"
          />
        );
      },
    },
  ];

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Credential List</div>
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={"Are you sure you want to delete this Credential?"}
              view
            />
          )}
          <div>
            <div className="filter_btn">
              {checkPrivileges([12, 13, 47]) && (
                <Buttons
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add Credential"}
                  varientAddIconBlue={true}
                  onClick={navigateAddCredential}
                ></Buttons>
              )}
            </div>
          </div>
        </div>
        <div className="div-filter-container">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>

            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              <UserFilterList
                firstLabel="Credential Type"
                secondLabel="Created By"
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleApply={handleApply}
                handleClear={handleClear}
                firstLebelFilter={credentialTypes}
                isShow={true}
                secondLebelFilter={USERS_TYPE}
                firstLebelOnChanges={credentialTypeOnChanges}
                secondLebelOnChanges={userTypeOnChanges}
                firstLebelValue={credentialsType}
                secondLebelValue={userTypes}
                resetBtn={true}
                expirationTypeList={EXPIRATION_TYPE}
                expirationType={expirationType}
                expirationTypeOnChanges={expirationTypeOnChanges}
              />
            </div>
          </div>
          <div className="div-download-email-right" onClick={sendEmail}>
            <span>
              <img
                src={downloadIcon}
                className="DownLoadLogo"
                alt="DownLoad Logo"
              />
            </span>
            Download Data
          </div>
        </div>
        {isTableLoading ? (
          ""
        ) : (
          <div className="grid_main_body">
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.id}
                  columns={columns}
                  rows={
                    credentialListData?.credentialData
                      ? credentialListData?.credentialData.map((x, index) => {
                          x.expirationDate = x.expirationDate
                            ? x.expirationDate
                            : "None";
                          x.credentialNumber = x.credentialNumber
                            ? x.credentialNumber
                            : "None";
                          return x;
                        })
                      : []
                  }
                  onClick={(rows) => onRowClick(rows)}
                  rowCount={credentialListData?.noOfRecords ?? 0}
                  loading={isLoading}
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  page={page}
                  pagination
                  pageSize={pageSize}
                  onPageChange={handlePagignation}
                  checkboxSelection={false}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                />
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default AdminCredentialList;
