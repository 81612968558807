import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as contractorAction from "../../../redux/actions/contractorAction";
import ViewContractorDetails from "../component/contractor/viewContractorDetails";
import { ACCESS_DENIED, ADMIN_PERSONAL_CONTRACTOR } from "../../../constant/routeContant";
import { API_STATUS } from "../../../constant/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { toast, ToastContainer } from "react-toastify";

const ContractorContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const rows = location?.state?.rowsss;
  const [sendEmail, setSendEmail] = useState(false);

  const addSubContractor = () => {
    navigate(ADMIN_PERSONAL_CONTRACTOR, {
      state: {
        contractorId: rows?.id,
        companyName: rows?.contractorName,
        editMode: false,
        contractorData: rows,
      },
    });
  };

  const dispatch = useDispatch();
  const getContractorListData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(contractorAction.getPersonalContractorList(data));
  };

  const personalContractorListData = useSelector(
    (state) => state.getPersonalContractorListData
  );
  const defaultData = {
    page: 1,
    pageSize: 50,
    search: "",
    contractorId: rows?.id,
    isSendMail: false,
  };
  useEffect(() => {
    getContractorListData(defaultData);
  }, []);

  const deletePersonalContractorDatas = useSelector(
    (state) => state.deletePersonalContractorData
  );
  const deleteList = (id) => {
    dispatch(contractorAction.deletePersonalContractor(id));
  };

  const handleSendEmail = () => {
    getContractorListData({ ...defaultData, isSendMail: true });
  };

  useEffect(() => {
    if (deletePersonalContractorDatas.status === API_STATUS.SUCCESS) {
      getContractorListData({ ...defaultData, isSendMail: false });
    }
  }, [deletePersonalContractorDatas]);

  useEffect(() => {
    if (
      personalContractorListData?.status === API_STATUS.SUCCESS &&
      sendEmail
    ) {
      toast("Personal contractor has been sent to registered email ID");
    }
  }, [personalContractorListData]);

  return (
    <>
      {checkPrivileges([12, 43, 44, 45, 145, 146, 147]) ? (
        <>
          <ToastContainer />

          <ViewContractorDetails
            addSubContractor={addSubContractor}
            loading={
              personalContractorListData?.isLoading ||
              deletePersonalContractorDatas.isLoading
            }
            rows={rows}
            personalContractorListData={
              personalContractorListData?.data?.result
            }
            deleteList={deleteList}
            compnayName={rows?.contractorName}
            constractorId={rows?.id}
            sendEmail={handleSendEmail}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default ContractorContainer;
