import React, { useState } from "react";
import { Grid } from "@mui/material";
import Loader from "../../library/common/Loader";
import DonoughtChart from "../../feature/support/component/ticket/charts";
import commonImages from "../../assets";
import NORow from "../../library/custom/noRow/noRow";
import GridTable from "../../library/custom/gridTable/GridTable";
import { TableLoader } from "../../library/common/Loader";
import OpenItemsFilter from "./OpenItemsFilter";
import "./openItemList.scss";
import {
  PAGE_SIZE,
  GET_ITEM_LOG,
  ROW_PER_PAGE_SIZE,
} from "../../constant/constant";
import SettingTable from "../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../admin/shared/ReportRowManipulate";
import downloadIcon from "../../../src/assets/downloadIcon.svg";
import EmailExportCard from "./emailExportCard";
import { useEffect } from "react";
import { checkPrivileges } from "../../utils/rolesHelper";
import {
  setColumnVisibilityModel1,
  columnVisibilityModel,
  getItemType,
} from "../../utils/helper";
import { width } from "@mui/system";
const OpenItemList = ({
  statusIds,
  setStatusIds,
  userId,
  activeTab,
  itemListData,
  isGetItemLogLoading,
  getItemLogCorrectiveData,
  handleViewOnClick,
  title,
  filterShow,
  getItemLogAllData,
  getExportItemListData,
  getExportItemListDatass,
  openItemCount,
  setActiveTab,
  fixedCount,
  setFixedCount,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [incSearchText, setIncSearchText] = useState("");
  const [assetTypeId, setAssetTypeId] = useState();
  const [itemTypesId, setItemTypesId] = useState();
  const [originId, setOriginId] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [isTableLoading, setisTableLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [allOpenitemFilterData, setAllitemFilterData] = useState({
    userId: userId,
    search: "",
    page: 1,
    pageSize: pageSize,
    projectId: 0,
    divisionId: 0,
    itemTypes: GET_ITEM_LOG.All,
    statusIds: parseInt(statusIds) === 1 ? "" : parseInt(statusIds),
    fromDate: "",
    toDate: "",
    status: parseInt(statusIds) === 1 ? "" : parseInt(statusIds),
    assetType: assetTypeId ? assetTypeId : "",
    origin: originId ? originId : "",
  });

  const handleTabClick = (id) => {
    setItemTypesId(itemTypesId ? id : "");
    setActiveTab(id);
    setPage(1);
    setPageSize(pageSize);
    const data = {
      userId: userId,
      search: "",
      page: 1,
      pageSize: pageSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: getItemType(id),
      statusIds: parseInt(statusIds) === 1 ? "" : parseInt(statusIds),
      fromDate: fromDate ? fromDate : "",
      toDate: toDate ? toDate : "",
      status: parseInt(statusIds) === 1 ? "" : parseInt(statusIds),
      assetType: assetTypeId ? assetTypeId : "",
      origin: originId ? originId : "",
    };

    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);

    if (id === 1) {
      setAllitemFilterData(data);
      getItemLogCorrectiveData(data);
    }
    if (id === 2) {
      setAllitemFilterData(data);
      getItemLogCorrectiveData(data);
    }
    if (id === 3) {
      setAllitemFilterData(data);
      getItemLogCorrectiveData(data);
    }
    if (id === 4) {
      setAllitemFilterData(data);
      getItemLogCorrectiveData(data);
    }
  };

  const resetData = () => {
    setIncSearchText("");
    setAssetTypeId("");
    setItemTypesId("");
    setOriginId("");
    setAnchorEl(null);
    setPageSize(PAGE_SIZE);
    setPage(1);
    setFromDate(null);
    setToDate(null);
    setStatusIds(2);
    setActiveTab(0);
    setFixedCount(2);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 300);

    const data = {
      userId: userId,
      search: "",
      page: 1,
      pageSize: PAGE_SIZE,
      projectId: 0,
      divisionId: 0,
      itemTypes: GET_ITEM_LOG.All,
      statusIds: 2,
      fromDate: "",
      toDate: "",
      status: "",
      assetType: "",
      origin: "",
      isSendMail: false,
    };
    setAllitemFilterData(data);
    getItemLogAllData(data);
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      const { row } = rows;
      handleViewOnClick(
        row?.id,
        row?.itemType,
        title,
        row?.origin,
        row?.status,
        row?.itemTypeId,
        row?.rootId,
        row?.subRootId,
        row?.assignedPartyId,
        row?.addedById,
        row?.assignedTo,
        row?.involvedPartyName,
        row?.involvedPartyId
      );
    }
  };
  const handleInputSearch = (event) => {
    setFixedCount(2);
    let search = "";
    if (event.target.value) {
      search = event.target.value;
    }
    setIncSearchText(search);
    const data = {
      ...allOpenitemFilterData,
      search: event.target.value,
      page: 1,
      isSendMail: false,
    };
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setAllitemFilterData(data);
    getItemLogAllData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      userId: userId,
      search: incSearchText ? incSearchText : "",
      page: newPage + 1,
      pageSize: pageSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: getItemType(activeTab),
      statusIds: parseInt(statusIds) === 1 ? "" : parseInt(statusIds),
      fromDate: fromDate ? fromDate : "",
      toDate: toDate ? toDate : "",
      status: parseInt(statusIds) === 1 ? "" : parseInt(statusIds),
      assetType: assetTypeId ? assetTypeId : "",
      origin: originId ? originId : "",
      isSendMail: false,
    };
    setAllitemFilterData(data);
    getItemLogAllData(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      userId: userId,
      search: incSearchText ? incSearchText : "",
      page: page,
      pageSize: newPazeSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: getItemType(activeTab),
      statusIds: parseInt(statusIds) === 1 ? "" : parseInt(statusIds),
      fromDate: fromDate ? fromDate : "",
      toDate: toDate ? toDate : "",
      status: parseInt(statusIds) === 1 ? "" : parseInt(statusIds),
      assetType: assetTypeId ? assetTypeId : "",
      origin: originId ? originId : "",
      isSendMail: false,
    };
    setAllitemFilterData(data);
    getItemLogAllData(data);
  };

  const [anchorEll, setAnchorEll] = useState(null);

  const opens = Boolean(anchorEll);
  const ids = opens ? "simple-popover" : undefined;
  const handleClosed = (event) => {
    event?.currentTarget.type === "text"
      ? setAnchorEll(event.currentTarget)
      : setAnchorEll(null);
  };

  const onDownloadClick = (event) => {
    setAnchorEll(event.currentTarget);
  };
  const OnClickCancelButton = (event) => {
    event?.currentTarget.type === "text"
      ? setAnchorEll(event.currentTarget)
      : setAnchorEll(null);
  };
  useEffect(() => {
    if (
      getExportItemListDatass?.data?.responseMessages?.responseMessage ==
      "Request was successful"
    ) {
      setAnchorEll(null);
    }
  }, [getExportItemListDatass]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.9,
      sortable: true,
      hideable: false,
      filterable: false,
    },
    {
      field: "origin",
      headerName: "Origin",
      flex: 1,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "itemType",
      headerName: "Item Type",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.assetType === 0
              ? "NA"
              : rows?.row?.assetType === 1
              ? "Project"
              : rows?.row?.assetType === 3
              ? "Vehicle"
              : rows?.row?.assetType === 2
              ? "Equipment"
              : "Custom asset"}
          </>
        );
      },
      type: "text",
      flex: 1.2,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "jobsite",
      headerName: "Asset Name",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "inspectionTitle",
      headerName: "Inspection Title",
      flex: 1.5,
      minWidth: 100,
      sortable: true,
      filterable: false,
    },

    {
      field: "category",
      headerName: "Category",
      flex: 1.5,
      minWidth: 110,
      sortable: true,
      filterable: false,
    },
    {
      field: "subCategory",
      headerName: "Sub-Category",
      flex: 1.5,
      minWidth: 110,
      sortable: true,
      filterable: false,
    },
    {
      field: "questions",
      headerName: "Questions",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "location",
      headerName: "Jobsite",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "involvedPartyName",
      headerName: "Involved Party",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },
    {
      field: "assignedToName",
      headerName: "Corrective Party",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },

    {
      field: "addedBy",
      headerName: "Added by",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },
    {
      field: "dateOfEntry",
      headerName: "Date of Entry",
      flex: 1.3,
      minWidth: 100,
      sortable: true,
      filterable: false,
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 70,
      sortable: true,
      filterable: false,
    },
    {
      field: "",
      width: 65,
      minWidth: 65,
      sortable: false,
      disableClickEventBubbling: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (rows) => {
        return filterShow ? (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={(row) =>
              handleViewOnClick(
                row?.id,
                row?.itemType,
                title,
                row?.origin,
                row?.status,
                row?.itemTypeId,
                row?.rootId,
                row?.subRootId,
                row?.assignedPartyId,
                row?.addedById,
                row?.assignedTo,
                row?.involvedPartyName,
                row?.involvedPartyId
              )
            }
            view={checkPrivileges([117, 118, 119, 12])}
            viewLabel={
              rows?.row?.itemType === "Kudos"
                ? "View Kudos"
                : rows?.row?.itemType === "Violation"
                ? "View Corrective"
                : rows?.row?.itemType === "Reprimand"
                ? "View Reprimand"
                : rows?.row?.itemType === "Action"
                ? "View Action"
                : ""
            }
          />
        ) : (
          ""
        );
      },
    },
  ];

  const handleSearchFilter = (event, state) => {
    setFixedCount(2);
    setAnchorEl(null);
    setAllitemFilterData(state);
    setStatusIds(state.status);
    setFromDate(state.startDate);
    setToDate(state.endDate);
    const data = {
      userId: userId,
      search: incSearchText,
      page: page,
      pageSize: pageSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: getItemType(activeTab),
      statusIds: parseInt(state.status) === 1 ? "" : state.status,
      status: parseInt(state.status) === 1 ? "" : state.status,
      fromDate: state.startDate,
      toDate: state.endDate,
      assetType: assetTypeId ? assetTypeId : "",
      origin: originId ? originId : "",
      isSendMail: false,
    };
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setAllitemFilterData(data);
    getItemLogAllData(data);
  };

  const handleClose = (event) => {
    event?.currentTarget.type === "button"
      ? setAnchorEl(event.currentTarget)
      : setAnchorEl(null);
  };

  var retrievedObjectdata = JSON.parse(
    localStorage.getItem("columnVisibility")
  );

  const typeOnAssetChanges = (e) => {
    setAssetTypeId(e?.target?.value);
  };

  const typeOnOriginChanges = (e) => {
    setOriginId(e?.target?.value);
  };
  const typeOnItemTypeChanges = (e) => {
    setActiveTab(e?.target?.value);
    setItemTypesId(e?.target?.value);
  };

  const sendEmail = () => {
    const data = {
      userId: userId,
      search: incSearchText ? incSearchText : "",
      page: page,
      pageSize: pageSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: getItemType(activeTab),
      statusIds: parseInt(statusIds) === 1 ? "" : parseInt(statusIds),
      fromDate: fromDate ? fromDate : "",
      toDate: toDate ? toDate : "",
      status: parseInt(statusIds) === 1 ? "" : parseInt(statusIds),
      assetType: assetTypeId ? assetTypeId : "",
      origin: originId ? originId : "",
      isSendMail: true,
    };
    setAllitemFilterData(data);
    getItemLogAllData(data);
  };

  return (
    <>
      {itemListData ? (
        <>
          <Grid className="myOpenItem">
            <Grid className="myOpenItemTitle">My Items</Grid>
          </Grid>

          <Grid container item className="overRideOpenItem">
            <Grid className="pieGraphCardBody" m={2}>
              <Grid
                className="assignedDiv1"
                onClick={() => handleTabClick(0, allOpenitemFilterData)}
              >
                <span className="heading">Total Open Items</span>
                <span className="addedNumber">
                  {openItemCount?.totalopenitems}
                </span>
              </Grid>
              <div className="pieChart">
                <DonoughtChart PieChart1 itemListData={openItemCount?.data} />
              </div>
            </Grid>
            {/* // graph end */}
            <Grid className="box">
              <Grid className="grid1">
                <Grid
                  className={
                    activeTab === 2 ? "openItemCard active" : "openItemCard"
                  }
                  onClick={() => handleTabClick(2, allOpenitemFilterData)}
                >
                  <Grid className="Itemgroup">
                    <img src={commonImages?.ViolationCount} alt="" />
                    <h1 className="headings">Corrective</h1>
                  </Grid>
                  <h1 className="value">{openItemCount?.correctivecount}</h1>
                </Grid>

                <Grid
                  className={
                    activeTab === 1 ? "openItemCard active" : "openItemCard"
                  }
                  onClick={() => handleTabClick(1, allOpenitemFilterData)}
                >
                  <Grid className="Itemgroup">
                    <img src={commonImages?.KudosCount} alt="" />
                    <h1 className="headings">Kudos</h1>
                  </Grid>
                  <h1 className="value">{openItemCount?.kudoscount}</h1>
                </Grid>
              </Grid>

              <Grid className="grid2">
                <Grid
                  onClick={() => handleTabClick(3, allOpenitemFilterData)}
                  className={
                    activeTab === 3 ? "openItemCard active" : "openItemCard"
                  }
                >
                  <Grid className="Itemgroup">
                    <img src={commonImages?.ReprimandCount} alt="" />
                    <h1 className="headings">Reprimands</h1>
                  </Grid>

                  <h1 className="value">{openItemCount?.reprimandscount}</h1>
                </Grid>

                <Grid
                  className={
                    activeTab === 4 ? "openItemCard active" : "openItemCard"
                  }
                  onClick={() => handleTabClick(4, allOpenitemFilterData)}
                >
                  <Grid className="Itemgroup">
                    <img src={commonImages?.ActionCount} alt="" />
                    <h1 className="headings">Actions</h1>
                  </Grid>
                  <h1 className="value">{openItemCount?.actionscount}</h1>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <section className="grid_main_sec">
            <div className="grid_main_header two_grid">
              {activeTab === 0 && (
                <>
                  <div className="grid_heading_style">All Items</div>
                  <div>
                    <EmailExportCard
                      id={ids}
                      open={opens}
                      anchorEl={anchorEll}
                      onClose={handleClosed}
                      OnClickCancelButton={OnClickCancelButton}
                      getExportItemListData={getExportItemListData}
                    />
                  </div>
                  {checkPrivileges([12, 117, 118]) && (
                    <div
                      onClick={onDownloadClick}
                      style={{ cursor: "pointer" }}
                      type="text"
                    >
                      <div className="DownloadText">
                        <div className="downLoadTemplate">
                          <span>
                            <img
                              src={downloadIcon}
                              className="DownLoadLogo"
                              alt="DownLoad Logo"
                            />
                          </span>
                          Download Data
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {activeTab === 2 && (
                <>
                  <div className="grid_heading_style">Corrective</div>
                  <div>
                    <EmailExportCard
                      id={ids}
                      open={opens}
                      anchorEl={anchorEll}
                      onClose={handleClosed}
                      OnClickCancelButton={OnClickCancelButton}
                      getExportItemListData={getExportItemListData}
                    />
                  </div>
                  {checkPrivileges([12, 117, 118]) && (
                    <div
                      onClick={onDownloadClick}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="DownloadText">
                        <div className="downLoadTemplate">
                          <span>
                            <img
                              src={downloadIcon}
                              className="DownLoadLogo"
                              alt="DownLoad Logo"
                            />
                          </span>
                          Download Data
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {activeTab === 1 && (
                <>
                  <div className="grid_heading_style">Kudos</div>
                  <div>
                    <EmailExportCard
                      id={ids}
                      open={opens}
                      anchorEl={anchorEll}
                      onClose={handleClosed}
                      OnClickCancelButton={OnClickCancelButton}
                      getExportItemListData={getExportItemListData}
                    />
                  </div>
                  {checkPrivileges([12, 117, 118]) && (
                    <div
                      onClick={onDownloadClick}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="DownloadText">
                        <div className="downLoadTemplate">
                          <span>
                            <img
                              src={downloadIcon}
                              className="DownLoadLogo"
                              alt="DownLoad Logo"
                            />
                          </span>
                          Download Data
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {activeTab === 3 && (
                <>
                  <div className="grid_heading_style">Reprimands</div>
                  <div>
                    <EmailExportCard
                      id={ids}
                      open={opens}
                      anchorEl={anchorEll}
                      onClose={handleClosed}
                      OnClickCancelButton={OnClickCancelButton}
                      getExportItemListData={getExportItemListData}
                    />
                  </div>
                  {checkPrivileges([117, 118, 12]) && (
                    <div
                      onClick={onDownloadClick}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="DownloadText">
                        <div className="downLoadTemplate">
                          <span>
                            <img
                              src={downloadIcon}
                              className="DownLoadLogo"
                              alt="DownLoad Logo"
                            />
                          </span>
                          Download Data
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {activeTab === 4 && (
                <>
                  <div className="grid_heading_style">Actions</div>
                  <div>
                    <EmailExportCard
                      id={ids}
                      open={opens}
                      anchorEl={anchorEll}
                      onClose={handleClosed}
                      OnClickCancelButton={OnClickCancelButton}
                      getExportItemListData={getExportItemListData}
                    />
                  </div>
                  {!checkPrivileges([12, 117, 118]) && (
                    <div
                      onClick={onDownloadClick}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="DownloadText">
                        <div className="downLoadTemplate">
                          <span>
                            <img
                              src={downloadIcon}
                              className="DownLoadLogo"
                              alt="DownLoad Logo"
                            />
                          </span>
                          Download Data
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="grid_main_body">
              <div className="desc_box row_uniq">
                <>
                  <div className="grid_main_body">
                    <div className="div-filter-container">
                      <OpenItemsFilter
                        statusIds={statusIds}
                        handleSearchFilter={handleSearchFilter}
                        handleClose={handleClose}
                        anchorEl={anchorEl}
                        incReport={incSearchText}
                        handleInputSearch={handleInputSearch}
                        resetFilterAPI={resetData}
                        placeholder="Search Open Item"
                        assetType={true}
                        assetTypeId={assetTypeId}
                        typeOnAssetChanges={typeOnAssetChanges}
                        itemTypes={true}
                        itemTypesId={itemTypesId}
                        typeOnItemTypeChanges={typeOnItemTypeChanges}
                        origin={true}
                        originId={originId}
                        typeOnOriginChanges={typeOnOriginChanges}
                        openItemFilter
                        fromDate={fromDate}
                        toDate={toDate}
                      />
                      {activeTab === 4 && (
                        <div
                          className="div-download-email-right"
                          onClick={sendEmail}
                        >
                          <span>
                            <img
                              src={downloadIcon}
                              className="DownLoadLogo"
                              alt="DownLoad Logo"
                            />
                          </span>
                          Download Data
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="table_style_ui">
                    {isTableLoading ? (
                      ""
                    ) : (
                      <GridTable
                        getRowId={(r) =>
                          r?.id +
                          "|" +
                          r?.itemType +
                          "|" +
                          Date.now() +
                          Math.floor(Math.random() * 100)
                        }
                        rows={itemListData?.result?.logsData ?? []}
                        rowCount={itemListData?.result?.noOfRecords ?? 0}
                        onClick={(rows) => onRowClick(rows)}
                        columns={columns}
                        loading={isGetItemLogLoading}
                        page={page}
                        pagination
                        pageSize={pageSize}
                        rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePagignation}
                        backIconButtonProps={{ disabled: false }}
                        disableSelectionOnClick
                        components={{
                          LoadingOverlay: TableLoader,
                          NoRowsOverlay: NORow,
                        }}
                        disableColumnMenu={false}
                        disableColumnSelector={false}
                        disableColumnFilter={false}
                        initialState={{
                          columns: {
                            columnVisibilityModel: retrievedObjectdata
                              ? retrievedObjectdata
                              : columnVisibilityModel,
                          },
                        }}
                        onColumnVisibilityModelChange={(newModel) =>
                          setColumnVisibilityModel1(
                            newModel,
                            "columnVisibility"
                          )
                        }
                      />
                    )}
                  </div>
                </>
              </div>
            </div>
          </section>
        </>
      ) : (
        <Loader className="loader" />
      )}
    </>
  );
};

export default OpenItemList;