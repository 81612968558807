import React from "react";
import { Grid } from "@mui/material";
import "./reportHeader.scss";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import { convertToLocalTime } from "../../../../../utils/helper";

const ReportHeader = ({ headerData, handlePrint }) => {
  return (
    <>
      <div className="overReportHeader">
        <Grid container className="reportHeader">
          <Grid md={6} xs={12} className="reportHeaderLeft">
            <div className="reportTitle">
              Report ID: {headerData?.report_Id}
            </div>
          </Grid>
          <Grid md={6} xs={12} className="reportHeaderRight">
            <div className="reportDesc">
              Reported on:
              <span>{convertToLocalTime(headerData.conducted_On)}</span>
            </div>
            <Button
              onClick={handlePrint}
              variant="contained"
              startIcon={<DownloadIcon className="downloadIcon" />}
              className="downloadpdf"
            >
              View PDF
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ReportHeader;
