import { lmsType } from "../constants/lmsType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "lessons",
  isError: false,
  isLoading: false,
};

export const getLessonsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.GET_LESSONS_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.GET_LESSONS_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.GET_LESSONS_LIST_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        isError: false,
        isLoading: false,
      };
    case lmsType.GET_LESSONS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createLessonReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.POST_LESSON_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.POST_LESSON_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.POST_LESSON_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const updateLessonReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.UPDATE_LESSON_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.UPDATE_LESSON_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.UPDATE_LESSON_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case lmsType.REVOKE_DATA_UNMOUNT:
      return {
        ...state,
        isSuccess: false,
        isError: false,
        isLoading: false,
        editCourseData: {},
        msg: "",
        error: "",
        successData: {},
      };
    case lmsType.GET_LESSON_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case lmsType.GET_LESSON_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        editLessonData: action.payload,
      };
    case lmsType.GET_LESSON_DETAILS_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const deleteLessonReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.DELETE_LESSON_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.DELETE_LESSON_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.DELETE_LESSON_FAILED:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const publishLessonReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.POST_PUBLISH_LESSON_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.POST_PUBLISH_LESSON_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.POST_PUBLISH_LESSON_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
export const assignLessonReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.POST_ASSIGN_LESSON_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.POST_ASSIGN_LESSON_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.POST_ASSIGN_LESSON_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
export const getAssignLessonUserListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case lmsType.GET_LESSON_ASSIGN_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.GET_LESSON_ASSIGN_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.GET_LESSON_ASSIGN_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};

export const completeLessonReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.POST_COMPLETE_LESSON_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.POST_COMPLETE_LESSON_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.POST_COMPLETE_LESSON_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const getAssignAlertUserListReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.GET_LESSON_ASSIGN_ALERT_LIST_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.GET_LESSON_ASSIGN_ALERT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.GET_LESSON_ASSIGN_ALERT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };

    default:
      return state;
  }
};
// reassign lesson reducer check lesson status

export const getCompletedLessonStatuscheckReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case lmsType.GET_LESSONS_COMPLETED_STATUS_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.GET_LESSONS_COMPLETED_STATUS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.GET_LESSONS_COMPLETED_STATUS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    case lmsType.CLEAR_ASSIGN_LESSON_STATUS:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: "",
      };
    default:
      return state;
  }
};

// Unassign lesson reducer
export const unAssignLessonReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.POST_LESSON_UNASSIGN_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.POST_LESSON_UNASSIGN_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.POST_LESSON_UNASSIGN_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
// Unenroll lesson reducer
export const unEnrollLessonReducer = (state = initialState, action) => {
  switch (action.type) {
    case lmsType.POST_LESSON_UNENROLL_PENDING:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case lmsType.POST_LESSON_UNENROLL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case lmsType.POST_LESSON_UNENROLL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
