import React, { useEffect, useState } from "react";
import ConductJhaList from "../component/conductJhaList";
import { API_STATUS, PAGE_SIZE } from "../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { ACCESS_DENIED, CONDUCT_JHA } from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { toast, ToastContainer } from "react-toastify";

function ConductJhaListContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sendEmail, setSendEmail] = useState(false);
  const conductJhaListData = useSelector(
    (state) => state.getConductJhaListData
  );

  const NavigateAddConductjha = () => {
    navigate(CONDUCT_JHA);
  };
  const defaultData = {
    page:1,
    pageSize: PAGE_SIZE,
    search: "",
    endDate: "",
    startDate: "",
    userId: 0,
    assetType: 0,
    isSendMail: false,
  };
  const getConductJhaList = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(jhaAction.getConductJhaList(data));
  };

  useEffect(() => {
    getConductJhaList(defaultData);
  }, []);

  useEffect(() => {
    if (conductJhaListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Conduct jha data has been sent to registered email ID");
    }
  }, [conductJhaListData]);

  return (
    <>
      {checkPrivileges([12, 123, 124, 125]) ? (
        <>
          <ToastContainer />
          <ConductJhaList
            getConductJhaList={getConductJhaList}
            conductJhaListData={conductJhaListData}
            NavigateAddConductjha={NavigateAddConductjha}
            isLoading={conductJhaListData.isLoading}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default ConductJhaListContainer;
