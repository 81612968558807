import React, { useRef } from "react";
import "./groupForm.scss";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Buttons from "../../../../library/custom/button/Button";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import DeletableChip from "../../../../library/custom/usersChip/UsersChip";
import { useState } from "react";
import { ADMIN_GROUP } from "../../../../constant/routeContant";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { useEffect } from "react";
import { API_STATUS, ORG_ID, USER_TYPE } from "../../../../constant/constant";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import { showRighSideMenu } from "../../../../utils/rolesHelper";
const GroupForm = ({
  onSearch,
  allUsers,
  addUserGroupData,
  isLoading,
  getUserGroupDetsilsData,
  groupId,
  editMode,
  DIVISION_ID,
  groupDivisionData,
}) => {
  const [userList, setUsersList] = useState([]);
  const [userListIds, setUsersListIds] = useState([]);
  const [users, setUsers] = useState(null);
  const [groupSupervisorList, setGroupSupervisorList] = useState([]);
  const [groupSupervisorIds, setGroupSupervisorIds] = useState([]);
  const [Groupsupervisor, setGroupsupervisor] = useState(null);

  const [groupName, setGroupName] = useState("");
  const [division, setDivision] = useState(DIVISION_ID());

  const [isDivisionErrMsg, setisDivisionErrMsg] = useState("");
  const [isDivision, setisDivision] = useState(false);

  const [isDivisionErrMsgForGroup, setisDivisionErrMsgForGroup] = useState("");
  const [isDivisionForGroup, setisDivisionForGroup] = useState(false);
  const [groupSuperwisorTypecheck, setGroupSuperwisorTypecheck] = useState("1");

  const autoComp = useRef(null);
  const autoGroupSupervisorComp = useRef(null);
  const deleteUsers = (row) => {
    const updateList = userList.filter((list) => list.key !== row);
    setUsersList(updateList);
  };
  const deleteSuperVisor = (row) => {
    const updateList = groupSupervisorList.filter((list) => list.key !== row);
    setGroupSupervisorList(updateList);
  };

  const [groupDivisions, setGroupDivisions] = useState();

  const [serviceType, setServiceType] = useState([]);
  const [serviceTypeError, setServiceTypeError] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const serviceTypes = userInfo?.product;

  useEffect(() => {
    if (serviceTypes?.length === 1) {
      setServiceType(serviceTypes[0].key);
    }
  }, [serviceTypes]);
  const selectedServiceTypeData = localStorage?.getItem("serviceType");

  useEffect(() => {
    if (groupDivisionData?.status === API_STATUS.SUCCESS) {
      const divisions = groupDivisionData?.data?.divisons && [
        ...groupDivisionData?.data?.divisons,
      ];
      divisions?.unshift({ key: "AllDivision", value: "All Divisions" });
      setGroupDivisions(divisions);
    }
  }, [groupDivisionData]);

  useEffect(() => {
    if (getUserGroupDetsilsData?.data) {
      setGroupName(getUserGroupDetsilsData?.data?.result?.value);
      setDivision(
        getUserGroupDetsilsData?.data?.result?.divisionId === 0
          ? "AllDivision"
          : getUserGroupDetsilsData?.data?.result?.divisionId
      );
      setServiceType(
        getUserGroupDetsilsData?.data?.result?.productIds?.map(
          (item) => item?.value
        )
      );
      const edtiUserList = getUserGroupDetsilsData?.data?.result?.userIds?.map(
        (users) => {
          return { key: users.key, value: users.value };
        }
      );
      setUsersList(edtiUserList);

      const editSupervisorList =
        getUserGroupDetsilsData?.data?.result?.groupSupervisor?.map((users) => {
          return { key: users.key, value: users.value };
        });

      setGroupSupervisorList(editSupervisorList);

      setGroupSuperwisorTypecheck(
        getUserGroupDetsilsData?.data?.result?.supervisorTypeId?.toString()
      );
    }
  }, [getUserGroupDetsilsData]);

  const addUserData = () => {
    setIsUser(false);
    if (!users?.key) return;
    const index = userList?.findIndex((obect) => obect.key === users?.key);
    if (index === -1) {
      setUsers("");
      setUsersListIds([...userListIds, users?.key]);
      setUsersList([...userList, users]);

      const ele = autoComp.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };
  const addGroupSupervisorData = () => {
    setIsGroupSupervisor(false);
    if (!Groupsupervisor?.key) return;
    const index = groupSupervisorList?.findIndex(
      (obect) => obect.key === Groupsupervisor.key
    );
    if (index === -1) {
      setGroupsupervisor("");
      setGroupSupervisorIds([...groupSupervisorIds, Groupsupervisor.key]);
      setGroupSupervisorList([...groupSupervisorList, Groupsupervisor]);

      const ele = autoGroupSupervisorComp.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };
  const dataBack = {
    title: "Back to User Groups",
    route: ADMIN_GROUP,
  };

  const onChangeUsers = (value) => {
    setUsers(value);
  };

  const onChangeSupervisor = (value) => {
    setGroupsupervisor(value);
  };
  const typeOnchange = (
    event,
    type,
    divisionId,
    assetType,
    assetId,
    priviledgeId,
    productId,
    orgId,
    withSpace,
    notPrivilegeId
  ) => {
    onSearch(
      event.target.value,
      divisionId > 0 ? type : 2,
      divisionId === "AllDivision" ? 0 : divisionId ?? 0,
      0,
      0,
      priviledgeId,
      productId,
      orgId,
      withSpace,
      notPrivilegeId
    );
  };
  const onChangeDivision = (e) => {
    setisDivision(false);
    setisDivisionForGroup(false);
    setUsersList([]);
    setUsersListIds([]);
    setDivision(e.target.value);
    setGroupSupervisorList([]);
    setGroupSupervisorIds([]);
  };
  const [gnameError, setGnameError] = useState(false);
  const [isUsers, setIsUser] = useState(false);
  const [isGroupSupervisor, setIsGroupSupervisor] = useState(false);

  const findValueMultipleSelectServiveType = (
    arrrayofObject,
    selectedArray
  ) => {
    if (serviceTypes?.length > 1) {
      let valueArray = [];
      arrrayofObject.forEach((data) => {
        if (selectedArray?.indexOf(data.value) >= 0) {
          valueArray.push(data.key);
        }
      });
      return valueArray;
    }
  };
  const onSubmitData = () => {
    const selectedServiceType = findValueMultipleSelectServiveType(
      serviceTypes,
      serviceType
    );
    let isFieldEmpty = false;
    if (groupName.trim() === "") {
      setGroupName("");
      setGnameError(true);
      isFieldEmpty = true;
    }
    if (userList?.length <= 0) {
      setIsUser(true);
      isFieldEmpty = true;
    }
    if (groupSupervisorList?.length <= 0 && PRODUCT_ID() == 2) {
      setIsGroupSupervisor(true);
      isFieldEmpty = true;
    }

    if (!isFieldEmpty) {
      const userIdLocal = localStorage.getItem("userId");
      const userId = userList.map((data) => parseInt(data.key));
      const groupSupervisorIds = groupSupervisorList.map((data) =>
        parseInt(data.key)
      );
      const sendData = {
        value: groupName,
        userIds: userId,
        divisionId: division === "AllDivision" ? 0 : division ?? 0,
        organizationId: ORG_ID(),
        createdBy: userIdLocal,
        createdByApp: 1,
        groupId: editMode ? groupId : 0,
        productIds: selectedServiceType ? selectedServiceType : [serviceType],
        groupSupervisor: groupSupervisorIds,
        supervisorTypeId: groupSuperwisorTypecheck
          ? parseInt(groupSuperwisorTypecheck)
          : null, // for selected radio button
      };

      addUserGroupData(sendData);
    }
  };
  const onChangeServiceType = (event) => {
    setServiceTypeError(false);
    const {
      target: { value },
    } = event;
    setServiceType(typeof value === "string" ? value.split(",") : value);
  };
  useEffect(() => {
    if (selectedServiceTypeData) {
      const newListData = serviceTypes?.find(
        (x) => x.key == selectedServiceTypeData
      );

      setServiceType(
        typeof newListData?.value === "string"
          ? newListData?.value.split(",")
          : newListData?.value
      );
    }
  }, [selectedServiceTypeData]);
  const handlecheckbtn = (e, value) => {
    setUsersListIds([]);
    setUsersList([]);
    setGroupSupervisorIds([]);
    setGroupSupervisorList([]);

    setGroupSuperwisorTypecheck(e.target.value);
  };
  return (
    <div>
      <h1 className="heading_style">
        {editMode ? "Edit User Group" : " Add User Group"}
      </h1>
      <Grid item container>
        <Grid item lg={12} xs={12} sm={12} md={12} textAlign="left">
          <Grid container item md={9} xs={9} className="addShadowBox">
            <Grid container item md={12}>
              <Grid lg={6} md={6} sm={12} xs={12}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"Group Name"}
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    placeholder="Enter Group Name"
                  />
                </FormControl>
                {gnameError && !groupName && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid item lg={6} xs={12} sm={12} md={6} mt={3}>
                <FormControl fullWidth>
                  <FormLabels label="Division" isRequired={true} />
                  <SelectMenu
                    placeholder={"Please Select"}
                    disabledDefault={false}
                    listData={groupDivisions}
                    value={division === "undefined" ? null : division}
                    onchangehandler={(e) => onChangeDivision(e)}
                    disabled={DIVISION_ID() > 0}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {parseInt(PRODUCT_ID()) === 2 && (
              <Grid item container mt={1} spacing={2} className="swproject">
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label={"Group supervisor Type"} />
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      className="radioGroup"
                    >
                      <RadioButton
                        checked={
                          groupSuperwisorTypecheck === "1" ? true : false
                        }
                        value="1"
                        disabled={editMode ? true : false}
                        label="Area Manager"
                        onChange={(e, value) => handlecheckbtn(e, value)}
                      ></RadioButton>

                      <RadioButton
                        checked={
                          groupSuperwisorTypecheck === "2" ? true : false
                        }
                        value="2"
                        label="Regional"
                        disabled={editMode ? true : false}
                        onChange={(e, value) => handlecheckbtn(e, value)}
                      ></RadioButton>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {parseInt(PRODUCT_ID()) === 2 && (
              <>
                <Grid container item mt={3}>
                  <Grid lg={6} md={6} sm={12} xs={12}>
                    {isDivisionForGroup && (
                      <div className="errorMsg">{isDivisionErrMsgForGroup}</div>
                    )}
                    <FormLabels label="Group Supervisor" isRequired={true} />
                    <SearchAutoComplete
                      name="groupSupervisor"
                      optionsData={isLoading ? [] : allUsers ?? []}
                      typeOnchange={(event, value) => {
                        if (division === "" || division === "undefined") {
                          setisDivisionForGroup(true);
                          setisDivisionErrMsgForGroup(
                            "Please select division first"
                          );
                          return;
                        } else {
                          typeOnchange(
                            event,
                            USER_TYPE.DIVISION_EMPLOYEE,
                            division,
                            0,
                            0,
                            showRighSideMenu().isSafety
                              ? 0
                              : parseInt(groupSuperwisorTypecheck) === 1
                              ? 136
                              : 137,
                            PRODUCT_ID(),
                            ORG_ID(),
                            false,
                            0
                          );
                        }
                      }}
                      isKeyValuePair={true}
                      value={Groupsupervisor}
                      onChange={(e, value) => onChangeSupervisor(value)}
                      isLoading={isLoading}
                      autoCompClear={autoGroupSupervisorComp}
                    />
                    {isGroupSupervisor && groupSupervisorList?.length <= 0 && (
                      <div className="errorMsg">
                        Please enter this required field
                      </div>
                    )}
                  </Grid>
                  <Grid
                    className="smallAddBtn"
                    lg={1.5}
                    md={9}
                    sm={12}
                    xs={12}
                    mt={4}
                    ml={2}
                  >
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={"Add"}
                      onClick={() => addGroupSupervisorData()}
                      id="btnAddEquipment"
                      varientAddIconBlue={true}
                    ></Buttons>
                  </Grid>
                </Grid>
                <div className="userListBody">
                  {
                    <DeletableChip
                      userList={groupSupervisorList}
                      handleDelete={deleteSuperVisor}
                    ></DeletableChip>
                  }
                </div>
              </>
            )}

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isDivision && (
                  <div className="errorMsg">{isDivisionErrMsg}</div>
                )}
                <FormLabels label="Add Users in this Group" isRequired={true} />
                <SearchAutoComplete
                  name="groupUsers"
                  optionsData={isLoading ? [] : allUsers ?? []}
                  typeOnchange={(event, value) => {
                    if (division === "" || division === "undefined") {
                      setisDivision(true);
                      setisDivisionErrMsg("Please select division first");
                      return;
                    } else {
                      typeOnchange(
                        event,
                        USER_TYPE.NONPRIVILEGED,
                        division,
                        0,
                        0,
                        showRighSideMenu().isSafety
                          ? 0
                          : parseInt(groupSuperwisorTypecheck) === 1 &&
                            parseInt(PRODUCT_ID()) === 2
                          ? [17, 138]
                          : parseInt(groupSuperwisorTypecheck) === 1 &&
                            parseInt(PRODUCT_ID()) === 1
                          ? 0
                          : 136,
                        PRODUCT_ID(),
                        ORG_ID(),
                        false,
                        parseInt(groupSuperwisorTypecheck) === 1 &&
                          parseInt(PRODUCT_ID()) === 2
                          ? [136, 137]
                          : 0
                      );
                    }
                  }}
                  isKeyValuePair={true}
                  value={users}
                  onChange={(e, value) => onChangeUsers(value)}
                  isLoading={isLoading}
                  autoCompClear={autoComp}
                />
                {isUsers && userList?.length <= 0 && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
              </Grid>

              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addUserData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
            </Grid>
            <div className="userListBody">
              {
                <DeletableChip
                  userList={userList}
                  handleDelete={deleteUsers}
                ></DeletableChip>
              }
            </div>
            <Grid item container mt={3} spacing={2}>
              {serviceTypes.length > 1 && (
                <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                  <FormControl fullWidth>
                    <FormLabels label="Service Type" isRequired={true} />

                    <MultiSelectBox
                      placeholder={"Please Select"}
                      listData={serviceTypes}
                      onChange={(e) => onChangeServiceType(e)}
                      value={serviceType || []}
                      isError={serviceTypeError}
                      errorMessage="Selection is required for this field"
                      disableSelect={true}
                    />
                  </FormControl>
                </Grid>
              )}
              {serviceTypes.length === 1 && (
                <Grid item lg={4} xs={12} sm={12} md={6} pl={2}>
                  <FormControl fullWidth>
                    <FormLabels label="Service Type" isRequired={true} />
                    {serviceTypes?.map((value, index) => {
                      return (
                        <div className="serviveTypeStyle" key={value?.key}>
                          {" "}
                          {value?.value}
                        </div>
                      );
                    })}
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Grid container mb={10} mt={1} spacing={3}>
              <Grid item lg={6} xs={12} sm={6} md={4}>
                <div className="save_btn_with_back">
                  <div className="">
                    <BackBtn dataBack={dataBack} />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
                sm={6}
                md={4}
                className="assignrightbtn"
                mt={2}
              >
                <div className="rightSideBtn">
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={"Save"}
                    onClick={() => onSubmitData()}
                    varientContained={true}
                  ></Buttons>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default GroupForm;
