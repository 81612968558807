import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import Loader from "../../../library/common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessScreen from "../../../library/custom/success/successScreen";
import {
  ACCESS_DENIED,
  ADMIN_GROUP,
} from "../../../../src/constant/routeContant";
import { DIVISION_ID, HBNEXT_ORG_ID, ORG_ID } from "../../../constant/constant";
import GroupForm from "../component/groups/groupForm";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { checkPrivileges, showRighSideMenu } from "../../../utils/rolesHelper";

const GroupsContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const groupId = location?.state?.groupId;
  const editMode = location?.state?.editMode;
  const searchOption = useSelector((state) => state.users.data);
  const addUserGroupResponse = useSelector((state) => state.addUserGroupData);
  const getUserGroupDetsilsData = useSelector(
    (state) => state.getUserGroupDetsilsData
  );
  const updateUserGroupDatas = useSelector(
    (state) => state.updateUserGroupData
  );
  const dispatch = useDispatch();
  const [successScreens, setSuccessScreens] = useState(false);

  const division = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const groupDivisionData = useSelector(
    (state) => state.getDropdownDataKeyValue
  );
  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: "6",
    productId: PRODUCT_ID(),
  };

  const showLoader = useSelector(
    (state) =>
      state.addUserGroupData?.isLoading ||
      state.getUserGroupDetsilsData?.isLoading ||
      state.updateUserGroupData?.isLoading ||
      state.getDropdownDataKeyValue?.isLoading
  );
  const allUsers = useSelector((state) => state.allUsers);

  const isLoading = allUsers.isLoading;
  const searchUsers = (
    searchText,
    type,
    divisionId,
    assetType,
    assetId,
    priviledgeId,
    productId,
    orgId,
    withSpace,
    notPrivilegeId
  ) => {
    dispatch(
      userAction.searchAllUsers(
        searchText,
        type,
        divisionId,
        assetType,
        assetId,
        priviledgeId,
        productId,
        orgId,
        withSpace,
        notPrivilegeId
      )
    );
  };

  const addUserGroupData = (payload) => {
    setSuccessScreens(true);
    if (editMode) {
      dispatch(userAction.updateUserGroup(payload));
    } else {
      dispatch(userAction.addUserGroup(payload));
    }
  };

  const getUserGroupDetail = () => {
    dispatch(userAction.getUserGroupDetails(groupId));
  };
  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);
  const SuccessScree = (observationid, message1) => {
    return (
      <SuccessScreen
        isUpdate={
          editMode ? "Group Updated Successfully" : "Group Created Successfully"
        }
        label1="Group ID"
        label4="Group in "
        route={ADMIN_GROUP}
        id={observationid}
        label2={message1}
      ></SuccessScreen>
    );
  };

  useEffect(() => {
    dispatch(userAction.clearList());
    setSuccessScreens(false);
    if (groupId) {
      getUserGroupDetail();
    }
  }, []);
  const showMenu = showRighSideMenu();
  const groupAccessSW =
    showMenu?.isSW &&
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) &&
    checkPrivileges([12, 55, 56]);
  const groupAccessSafety = showMenu?.isSafety && checkPrivileges([12, 55, 56]);

  return (
    <>
      {groupAccessSW || groupAccessSafety ? (
        <>
          {addUserGroupResponse?.status === "SUCCESS" &&
          addUserGroupResponse?.data &&
          successScreens ? (
            SuccessScree(addUserGroupResponse?.data?.id)
          ) : updateUserGroupDatas?.status === "SUCCESS" &&
            updateUserGroupDatas?.data &&
            successScreens ? (
            SuccessScree(updateUserGroupDatas?.data?.id, "has been Updated")
          ) : (
            <>
              {showLoader && <Loader />}
              <GroupForm
                divisions={division}
                searchOption={searchOption?.users || []}
                addUserGroupData={addUserGroupData}
                allUsers={allUsers?.data?.users}
                onSearch={searchUsers}
                isLoading={isLoading}
                groupId={groupId}
                editMode={editMode}
                DIVISION_ID={DIVISION_ID}
                getUserGroupDetsilsData={
                  editMode ? getUserGroupDetsilsData : ""
                }
                groupDivisionData={groupDivisionData}
              />
            </>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};
export default GroupsContainer;
