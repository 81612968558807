import React, { useEffect, useState } from "react";
import PersonalContractorForm from "../component/contractor/personalContractorForm";
import * as contractorAction from "../../../redux/actions/contractorAction";
import { useLocation } from "react-router-dom";
import SuccessScreen from "../../../library/custom/success/successScreen";
import {
  ADMIN_CONTRACTOR_DETAILS,
  ADMIN_CONTRACTOR_LIST,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import Loader from "../../../library/common/Loader";

import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../constant/constant";
const PersonalContractorFormContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [successScreens, setSuccessScreens] = useState(false);

  const createPersonalContractors = (data) => {
    setSuccessScreens(true);
    if (editMode) {
      dispatch(contractorAction.updatePersonalContractor(data));
    } else {
      dispatch(contractorAction.createPersonalContractor(data));
    }
  };

  const createPersonalContractorDatas = useSelector(
    (state) => state.createPersonalContractorData
  );
  const viewPersonalContractorDatas = useSelector(
    (state) => state.viewPersonalContractorData
  );
  const updatePersonalContractorDatas = useSelector(
    (state) => state.updatePersonalContractorData
  );

  const showLoader = useSelector(
    (state) =>
      state.state.isLoading ||
      state.createPersonalContractorData.isLoading ||
      state.viewPersonalContractorData.isLoading ||
      state.updatePersonalContractorData.isLoading
  );

  const contractorId = location?.state?.contractorId;
  const companyName = location?.state?.companyName;
  const contractorPersonnelId = location?.state?.contractorPersonnelId;

  const editMode = location?.state?.editMode;

  const userData = useSelector((state) => state.getUserListDetsilsData);
  const getContactDetail = () => {
    dispatch(
      contractorAction.getPersonalContractorDetails(contractorPersonnelId)
    );
  };

  useEffect(() => {
    // if (!contractorId) {
    //   navigate(ADMIN_CONTRACTOR_LIST);
    // }
    dispatch(contractorAction.clearContractor());
    setSuccessScreens(false);
    if (contractorPersonnelId && editMode) {
      getContactDetail();
    }
  }, []);

  const SuccessScree = (id) => {
    return (
      <SuccessScreen
        isUpdate={
          editMode
            ? "Personnel Updated Successfully"
            : "Personnel Created Successfully"
        }
        route={ADMIN_CONTRACTOR_DETAILS}
        id={id}
        contractorData={location?.state?.contractorData}
        label1={"Personnel ID"}
        label2={editMode ? "has been updated" : "has been generated"}
      ></SuccessScreen>
    );
  };

  return (
    <>
      {createPersonalContractorDatas.status === API_STATUS.SUCCESS &&
      createPersonalContractorDatas.data &&
      successScreens ? (
        SuccessScree(createPersonalContractorDatas?.data?.id)
      ) : updatePersonalContractorDatas.status === API_STATUS.SUCCESS &&
        updatePersonalContractorDatas &&
        successScreens ? (
        SuccessScree(updatePersonalContractorDatas?.data?.id)
      ) : (
        <>
          {showLoader && <Loader />}
          <PersonalContractorForm
            addPersonalContractor={createPersonalContractors}
            userData={userData?.data}
            editMode={editMode}
            contractorId={contractorId}
            companyName={companyName}
            contractorPersonnelId={contractorPersonnelId}
            viewContractors={editMode ? viewPersonalContractorDatas : ""}
          />
        </>
      )}
    </>
  );
};

export default PersonalContractorFormContainer;
