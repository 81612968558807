import { Grid } from "@mui/material";
import React from "react";
import "./viewProfile.scss";
import commonImages from "../../../../assets";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
const viewProfile = ({
  getUserListDetsilsData,
  editProfile,
  getClientDetsilsData,
  viewContractors,
}) => {
  const data = getUserListDetsilsData;
  const clientData = getClientDetsilsData?.data?.result;
  const ContractorData = viewContractors?.data?.result;

  const imageUrl = data?.signatureUrl?.split("/");
  const imageUrlName = imageUrl?.slice(-1).pop();

  const ContractorimageUrl = ContractorData?.signatureUrl?.split("/");
  const contractorimageUrlName = ContractorimageUrl?.slice(-1).pop();

  const ClientimageUrl = clientData?.signatureUrl?.split("/");
  const clinetimageUrlName = ClientimageUrl?.slice(-1).pop();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const jobTitle = userInfo?.jobTitle;
  const division = userInfo?.divisions;
  const isClient = userInfo?.isClient;
  const isContractor = userInfo?.isContractor;
  const role = userInfo?.roles;
  const contractorCompany = userInfo?.organization;
  const contractorDivision = userInfo?.divisions;
  const contractorUserId = userInfo?.userId;

  const UserProfileShow = isClient === false && isContractor === false;
  const contractorProfileShow = isContractor === true && isClient === false;
  const clientProfileShow = isClient === true && isContractor === false;

  const space = clientData?.pointOfContact.indexOf(" ");
  const firstName = clientData?.pointOfContact.substring(0, space);
  const lastName = clientData?.pointOfContact.substring(space + 1);

  function filterByServiceType(data, serviceType) {
    return data.filter((item) => item.serviceType === serviceType);
  }
  const filteredDataWithType1 = filterByServiceType(division, 1);
  const filteredDataWithType2 = filterByServiceType(division, 2);

  return (
    <>
      {UserProfileShow && (
        <>
          <Grid className="overRideViewProfile" mt={10} item container>
            <Grid className="header">
              <Grid className="profileImg">
                <img
                  className="borderProfileImg"
                  src={
                    data?.profileImage
                      ? data?.profileImage
                      : commonImages?.DefaultProfilePic
                  }
                  alt=""
                />
              </Grid>
              <Grid item md={6} className="name">
                <label className="userName">
                  {data?.firstname} {data?.lastname}
                </label>
                <h3 className="userJob">{jobTitle}</h3>
              </Grid>

              <Grid className="btnProfile" onClick={() => editProfile(2)}>
                <span className="editImage">
                  <img src={commonImages?.whiteEditIcon} alt="" />
                </span>
                <span className="editProfileBtn">Edit Profile</span>
              </Grid>
            </Grid>
            <Grid className="profileDetails">
              <Grid className="leftSection">
                <Grid className="PageHeading">Personal Details</Grid>
                <Grid className="leftSection1">
                  <Grid container className="columnLeftSide" item mt={4}>
                    <Grid className="heading">User ID</Grid>
                    <span className="response">{data?.userid}</span>
                    <Grid className="heading" mt={3}>
                      First Name
                    </Grid>
                    <span className="response">{data?.firstname}</span>
                    <Grid className="heading" mt={3}>
                      Email
                    </Grid>
                    <span className="email">{data?.email}</span>
                    <Grid className="heading" mt={3}>
                      Company
                    </Grid>
                    <span className="response">{data?.companyname}</span>

                    <Grid className="heading" mt={3}>
                      Job Title
                    </Grid>

                    <span className="response">{jobTitle}</span>

                    <Grid className="heading" mt={3}>
                      Divisions
                    </Grid>
                    {PRODUCT_ID() == 1 && (
                      <Grid mt={-2.8}>
                        {filteredDataWithType1?.map((item, index) => {
                          return (
                            <div className="userRoleResponse" key={index}>
                              {item?.value}
                            </div>
                          );
                        })}
                      </Grid>
                    )}
                    {PRODUCT_ID() == 2 && (
                      <Grid mt={-2.8}>
                        {filteredDataWithType2?.map((item, index) => {
                          return (
                            <div className="userRoleResponse" key={index}>
                              {item?.value}
                            </div>
                          );
                        })}
                      </Grid>
                    )}
                    <Grid className="heading" mt={3}>
                      User Role
                    </Grid>
                    {PRODUCT_ID() == 1 && (
                      <Grid mt={-2.8}>
                        {data?.safetyRoles?.map((item, index) => {
                          return (
                            <div className="userRoleResponse" key={index}>
                              {item?.value}
                            </div>
                          );
                        })}
                      </Grid>
                    )}
                    {PRODUCT_ID() == 2 && (
                      <Grid mt={-2.8}>
                        {data?.stormRoles?.map((item, index) => {
                          return (
                            <div className="userRoleResponse" key={index}>
                              {item?.value}
                            </div>
                          );
                        })}
                      </Grid>
                    )}
                  </Grid>
                  <Grid container className="columnRightSide" item mt={4}>
                    <Grid className="heading" mt={10}>
                      Last name
                    </Grid>
                    <span className="response">{data?.lastname}</span>
                    <Grid className="heading" mt={11.5}>
                      Phone
                    </Grid>
                    <span className="response">{data?.phonenumber}</span>

                    <Grid className="heading" mt={3}>
                      Hiring Date
                    </Grid>

                    <span className="response">{data?.startDate}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="rightSection">
                <Grid className="PageHeading"> Login Details</Grid>

                <Grid className="heading" mt={4}>
                  Phone
                </Grid>
                <span className="response">{data?.phonenumber}</span>
                <Grid className="signature" mt={3}>
                  <Grid className="signatureHeading">Signature</Grid>

                  <img
                    className="borderProfileImg"
                    src={data?.signatureUrl}
                    alt=""
                  />
                  {/* <span className="response">{imageUrlName}</span> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {clientProfileShow && (
        <>
          {" "}
          <Grid className="overRideViewProfile" mt={10} item container>
            <Grid className="header">
              <Grid className="profileImg">
                <img
                  className="borderProfileImg"
                  src={
                    clientData?.profileImage
                      ? clientData?.profileImage
                      : commonImages?.DefaultProfilePic
                  }
                  alt=""
                />
              </Grid>
              <Grid item md={6} className="name">
                <label className="userName">
                  {firstName} {lastName}
                </label>
              </Grid>

              <Grid className="btnProfile" onClick={() => editProfile(2)}>
                <span className="editImage">
                  <img src={commonImages?.whiteEditIcon} alt="" />
                </span>
                <span className="editProfileBtn">Edit Profile</span>
              </Grid>
            </Grid>
            <Grid className="profileDetails">
              <Grid className="leftSection">
                <Grid className="PageHeading">Personal Details</Grid>
                <Grid className="leftSection1">
                  <Grid container className="columnLeftSide" item mt={4}>
                    <Grid className="heading">User ID</Grid>
                    <span className="response">{clientData?.userId}</span>
                    <Grid className="heading" mt={3}>
                      First Name
                    </Grid>
                    <span className="response">{firstName}</span>

                    <Grid className="heading" mt={3}>
                      Email
                    </Grid>
                    <span className="email">{clientData?.email}</span>
                    <Grid className="heading" mt={3}>
                      Company
                    </Grid>
                    <span className="response">{clientData?.fullName}</span>
                    <Grid className="heading" mt={3}>
                      User Role
                    </Grid>
                    <Grid mt={-2.8}>
                      {role.map((item, index) => {
                        return (
                          <div key={index} className="userRoleResponse">
                            {item?.name}
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid container className="columnRightSide" item mt={4}>
                    <Grid className="heading" mt={10}>
                      Last name
                    </Grid>
                    <span className="response">{lastName}</span>
                    <Grid className="heading" mt={11.5}>
                      Phone
                    </Grid>
                    <span className="response">{clientData?.phoneNumber}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="rightSection">
                <Grid className="PageHeading"> Login Details</Grid>

                <Grid className="heading" mt={4}>
                  Phone
                </Grid>
                <span className="response">{clientData?.phoneNumber}</span>
                <Grid className="signature" mt={3}>
                  <Grid className="signatureHeading">Signature</Grid>

                  <img
                    className="borderProfileImg"
                    src={clientData?.signatureUrl}
                    alt=""
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>{" "}
        </>
      )}
      {contractorProfileShow && (
        <>
          <Grid className="overRideViewProfile" mt={10} item container>
            <Grid className="header">
              <Grid className="profileImg">
                <img
                  className="borderProfileImg"
                  src={
                    ContractorData?.profileImage
                      ? ContractorData?.profileImage
                      : commonImages?.DefaultProfilePic
                  }
                  alt=""
                />
              </Grid>
              <Grid item md={6} className="name">
                <label className="userName">
                  {ContractorData?.firstname} {ContractorData?.lastname}
                </label>
                <h3 className="userJob">{jobTitle}</h3>
              </Grid>

              <Grid className="btnProfile" onClick={() => editProfile(2)}>
                <span className="editImage">
                  <img src={commonImages?.whiteEditIcon} alt="" />
                </span>
                <span className="editProfileBtn">Edit Profile</span>
              </Grid>
            </Grid>
            <Grid className="profileDetails">
              <Grid className="leftSection">
                <Grid className="PageHeading">Personal Details</Grid>
                <Grid className="leftSection1">
                  <Grid container className="columnLeftSide" item mt={4}>
                    <Grid className="heading">User ID</Grid>
                    <span className="response">{contractorUserId}</span>
                    <Grid className="heading" mt={3}>
                      First Name
                    </Grid>
                    <span className="response">
                      {ContractorData?.pocFirstName}
                    </span>
                    <Grid className="heading" mt={3}>
                      Email
                    </Grid>
                    <span className="email">{ContractorData?.pocEmail}</span>
                    <Grid className="heading" mt={3}>
                      Company
                    </Grid>
                    <span className="response">
                      {ContractorData?.contractorName}
                    </span>

                    <Grid className="heading" mt={3}>
                      Divisions
                    </Grid>

                    {contractorDivision?.map((item, index) => {
                      return (
                        <span key={index} className="userRoleResponse">
                          {item?.value}
                        </span>
                      );
                    })}

                    <Grid className="heading" mt={3}>
                      User Role
                    </Grid>
                    <Grid mt={-2.8}>
                      {ContractorData?.roles?.map((item, index) => {
                        return (
                          <div key={index} className="userRoleResponse">
                            {item?.value}
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid container className="columnRightSide" item mt={4}>
                    <Grid className="heading" mt={10}>
                      Last name
                    </Grid>
                    <span className="response">
                      {ContractorData?.pocLastName}
                    </span>
                    <Grid className="heading" mt={11.5}>
                      Phone
                    </Grid>
                    <span className="response">{ContractorData?.pocPhone}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="rightSection">
                <Grid className="PageHeading"> Login Details</Grid>

                <Grid className="heading" mt={4}>
                  Phone
                </Grid>
                <span className="response">{ContractorData?.pocPhone}</span>
                <Grid className="signature" mt={3}>
                  <Grid className="signatureHeading">Signature</Grid>

                  <img
                    className="borderProfileImg"
                    src={ContractorData?.signatureUrl}
                    alt=""
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default viewProfile;
