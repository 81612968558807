import * as toolBoxAction from "../actions/toolBoxAction";
import { toolBoxTalkType } from "../constants/toolBoxType";
import {
  createToolBox,
  getToolBoxTalkList,
  getToolBoxTalkDetails,
  updateToolBoxTalkList,
  deleleToolBoxTalk,
  GetSaftyMeetingSearchList,
  getAdminToolBoxTalkList,
  getProjectSearchList,
  getAllUsersProject,
  gettoolboxAssignedUserStatusList,
  sendSafetyMeetingAlertMail,
  getMySafetyMeetingList,
  getScheduleInstructorDetails,
  getScheduleSaftyMeetingDetails,
  getScheduleAttendeesDetails,
  updateAdminAssignInstructors,
} from "../../services/toolBoxTalkService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* createToolBoxTalkSaga(param) {
  const payload = param.data;

  try {
    const { data, status, error } = yield call(createToolBox, payload);
    if (!data && status !== 200) throw apiError(error);
    const casePage = toolBoxTalkType.CREATE_PAGE;
    yield put(
      toolBoxAction.createToolBoxTalkSuccess({
        casePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Tool Box Talk Failed" };
    yield put(toolBoxAction.createToolBoxTalkFailure(data));
  }
}
export function* getToolBoxTalkSaga(params) {
  try {
    const { data, status, error } = yield call(getToolBoxTalkList, params);

    if (!data && status !== 200) throw apiError(error);
    const toolboxpagePage = toolBoxTalkType.TOOLBOXTALK_PAGE;
    yield put(
      toolBoxAction.getToolBoxTalkListSuccess({
        toolboxpagePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Tool Box List  Failed" };
    yield put(toolBoxAction.getToolBoxTalkListFailure(data));
  }
}
export function* getAdminToolBoxTalkSaga(params) {
  try {
    const { data, status, error } = yield call(getAdminToolBoxTalkList, params);

    if (!data && status !== 200) throw apiError(error);
    const toolboxpagePage = toolBoxTalkType.ADMINTOOLBOXTALK_PAGE;
    yield put(
      toolBoxAction.getAdminToolBoxTalkListSuccess({
        toolboxpagePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Admin Tool Box List  Failed" };
    yield put(toolBoxAction.getAdminToolBoxTalkListFailure(data));
  }
}
export function* getToolBoxTalkDetailsSaga(payload) {
  try {
    const { data, status, error } = yield call(getToolBoxTalkDetails, payload);
    if (!data && status !== 200) throw apiError(error);
    yield put({
      type: toolBoxTalkType.GET_TOOL_BOX_TALK_DETAILS_SUCCESS,
      data: data,
    });
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Tool Box Detail List Failed" };
    yield put({
      type: toolBoxTalkType.GET_TOOL_BOX_TALK_DETAILS_FAILED,
      error: data,
    });
  }
}

export function* updateToolBoxTalkListSaga(param) {
  const payload = param.payload;
  try {
    const { data, status, error } = yield call(updateToolBoxTalkList, payload);

    if (!data && status !== 200) throw apiError(error);
    const updatePage = toolBoxTalkType.UPDATE_PAGE;
    yield put(
      toolBoxAction.updateToolBoxTalkSuccess({
        updatePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "update Tool Box Talk Failed" };
    yield put(toolBoxAction.updateToolBoxTalkFailure(data));
  }
}

function* deleteToolBoxTalkSaga(payload) {
  try {
    const { data, status } = yield call(deleleToolBoxTalk, payload);
    const deletePage = toolBoxTalkType.TOOLBOXTALK_DELETE_PAGE;

    yield put(
      toolBoxAction.deleteToolBoxTalkSuccess({
        // working on it ===========================================================
        deletePage,
        data,
        status,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Delete Tool Box Failed" };
    yield put(toolBoxAction.deleteToolBoxTalkFailure(data));
  }
}
// safety metting search saaaga search auto complete
export function* GetSaftyMeetingSearchSaga(params) {
  try {
    const { data, status, error } = yield call(
      GetSaftyMeetingSearchList,
      params
    );

    if (!data && status !== 200) throw apiError(error);
    const toolboxpagePage = toolBoxTalkType.TOOLBOXSAFETYSEARCH_PAGE;
    yield put(
      toolBoxAction.getToolBoxSafetyMeetingSearchListSuccess({
        toolboxpagePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : {
            message: error || "Get Tool Box Safety Meeting Search List  Failed",
          };
    yield put(toolBoxAction.getToolBoxSafetyMeetingSearchListFailure(data));
  }
}

export function* getProjectSearchSaga(params) {
  try {
    const { data, status, error } = yield call(getProjectSearchList, params);

    if (!data && status !== 200) throw apiError(error);
    const toolboxpagePage = toolBoxTalkType.PROJECT_SEARCH_PAGE;
    yield put(
      toolBoxAction.getProjectSearchListSuccess({
        toolboxpagePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Project Search List Failed" };
    yield put({
      type: toolBoxTalkType.GET_PROJECT_SEARCH_LIST_FAILURE,
      error: data,
    });
  }
}

export function* allUsersProjectSaga({
  searchText,
  searchType,
  searchProjectId,
  divisionId,
}) {
  try {
    const { data, status, error } = yield call(
      getAllUsersProject,
      searchText,
      searchType,
      searchProjectId,
      divisionId
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = toolBoxTalkType.USER_PROJECT_SEARCH_PAGE;
    yield put(
      toolBoxAction.searchAllUsersProjectsSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Search all User Project wise Failed" };
    yield put(toolBoxAction.searchAllUsersProjectsFailure(data));
  }
}

export function* getAssignUserListToolBoxStatusSaga(params) {
  try {
    const { data, status, error } = yield call(
      gettoolboxAssignedUserStatusList,
      params
    );

    //const dataresult = data?.result;
    if (!data && status !== 200) throw apiError(error);
    const assignPage = toolBoxTalkType.SCHEDULE_USER_COUNTPAGE;

    yield put(
      toolBoxAction.scheduleUserCountStatusSuccess({
        assignPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Assign  safety user List Failed" };
    yield put(toolBoxAction.scheduleUserCountStatusFailure(data));
  }
}
export function* postSafetyMeetingAlertSaga(params) {
  try {
    const { data, status, error } = yield call(
      sendSafetyMeetingAlertMail,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const alertPage = toolBoxTalkType.ASSIGN_SAFETY_MEETING_ALERT_PAGE;
    yield put(
      toolBoxAction.getAlertmailSafetyMeetingSuccess({
        alertPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Post alert mail for safety meeting Failed" };
    yield put(toolBoxAction.getAlertmailSafetyMeetingFailure(data));
  }
}

export function* getMySafetyMeetingSaga(params) {
  try {
    const { data, status, error } = yield call(getMySafetyMeetingList, params);

    if (!data && status !== 200) throw apiError(error);
    const toolboxpagePage = toolBoxTalkType.MYSAFETYMEETING_PAGE;
    yield put(
      toolBoxAction.getMySafetyMeetingListSuccess({
        toolboxpagePage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get My safety meeting List  Failed" };
    yield put(toolBoxAction.getMySafetyMeetingListFailure(data));
  }
}

export function* getScheduleSaftyMeetingDetailsSaga(params) {
  try {
    const { data, status, error } = yield call(
      getScheduleSaftyMeetingDetails,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = toolBoxTalkType.USER_PROJECT_SEARCH_PAGE;
    yield put(
      toolBoxAction.getScheduleSaftyMeetingDetailsSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getScheduleSaftyMeetingDetails Failed" };
    yield put(toolBoxAction.getScheduleSaftyMeetingDetailsFailure(data));
  }
}

export function* getScheduleAttendeesDetailsSaga(params) {
  try {
    const { data, status, error } = yield call(
      getScheduleAttendeesDetails,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = toolBoxTalkType.USER_PROJECT_SEARCH_PAGE;
    yield put(
      toolBoxAction.getScheduleAttendeesDetailsSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getScheduleAttendeesDetails Failed" };
    yield put(toolBoxAction.getScheduleAttendeesDetailsFailure(data));
  }
}
export function* getScheduleInstructorDetailsSaga(params) {
  try {
    const { data, status, error } = yield call(
      getScheduleInstructorDetails,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const userPage = toolBoxTalkType.USER_PROJECT_SEARCH_PAGE;
    yield put(
      toolBoxAction.getScheduleInstructorDetailsSuccess({
        userPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "getScheduleInstructorDetails Failed" };
    yield put(toolBoxAction.getScheduleInstructorDetailsFailure(data));
  }
}
export function* updateAdminAssignInstructorsSaga(params) {
  try {
    const { data, status, error } = yield call(
      updateAdminAssignInstructors,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const alertPage = toolBoxTalkType.ASSIGN_SAFETY_MEETING_ALERT_PAGE;
    yield put(
      toolBoxAction.updateAdminAssignInstructorsSuccess({
        alertPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "updateAdminAssignInstructorsSaga Failed" };
    yield put(toolBoxAction.updateAdminAssignInstructorsFailure(data));
  }
}
const myToolBoxTalkSaga = [
  takeLatest(toolBoxTalkType.POST_TOOL_BOX_TALK_PENDING, createToolBoxTalkSaga),
  takeLatest(
    toolBoxTalkType.GET_TOOL_BOX_TALK_LIST_PENDING,
    getToolBoxTalkSaga
  ),
  takeLatest(
    toolBoxTalkType.GET_TOOL_BOX_TALK_DETAILS_PENDING,
    getToolBoxTalkDetailsSaga
  ),
  takeLatest(
    toolBoxTalkType.UPDATE_TOOL_BOX_TALK_PENDING,
    updateToolBoxTalkListSaga
  ),
  takeLatest(
    toolBoxTalkType.DELETE_TOOL_BOX_TALK_PENDING,
    deleteToolBoxTalkSaga
  ),
  takeLatest(
    toolBoxTalkType.GET_TOOL_BOX_SAFETY_MEETING_SEARCH_PENDING,
    GetSaftyMeetingSearchSaga
  ),
  takeLatest(
    toolBoxTalkType.GET_ADMINTOOL_BOX_TALK_LIST_PENDING,
    getAdminToolBoxTalkSaga
  ),
  takeLatest(
    toolBoxTalkType.GET_PROJECT_SEARCH_LIST_PENDING,
    getProjectSearchSaga
  ),
  takeLatest(toolBoxTalkType.GET_ALL_USER_SEARCH, allUsersProjectSaga),
  takeLatest(
    toolBoxTalkType.SCHEDULE_USERCOUNT_STATUS_LIST_PENDING,
    getAssignUserListToolBoxStatusSaga
  ),
  takeLatest(
    toolBoxTalkType.POST_SAFETY_MEETING_ALERT_PENDING,
    postSafetyMeetingAlertSaga
  ),
  takeLatest(
    toolBoxTalkType.GET_MY_SAFETY_MEETING_PENDING,
    getMySafetyMeetingSaga
  ),
  takeLatest(
    toolBoxTalkType.GET_SCHEDULE_ATTENDEES_DETAILS,
    getScheduleAttendeesDetailsSaga
  ),
  takeLatest(
    toolBoxTalkType.GET_SCHEDULE_INSTRUCTOR_DETAILS,
    getScheduleInstructorDetailsSaga
  ),
  takeLatest(
    toolBoxTalkType.GET_SCHEDULE_SAFETY_MEETING_DETAILS,
    getScheduleSaftyMeetingDetailsSaga
  ),
  takeLatest(
    toolBoxTalkType.POST_UPDATE_ADMIN_ASSIGN_INSTRUCTOR,
    updateAdminAssignInstructorsSaga
  ),
];

export default myToolBoxTalkSaga;