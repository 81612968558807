import { getApiUrl } from "../../config";
import { ORG_ID } from "../../constant/constant";
import { get, post, deletes } from "../axiosRequest";

export const questionSetList = async ({ data }) => {
  const { userId, page, pageSize, search, status } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }QuestionSet/GetQuestionSetList?userId=${userId}&pageSize=${pageSize}&page=${page}&search=${search}&status=${status}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addQuestionSet = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}QuestionSet/AddQuestionSet`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateQuestionSet = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}QuestionSet/UpdateQuestionSet`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getQestSetDetail = async (data) => {
  const { questionSetId, orgId } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }QuestionSet/GetQuestionSetDetails?questionSetId=${questionSetId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const updateQuestionSetStatus = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}QuestionSet/UpdateQuestionSetStatus`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const cloneSwQuestionSet = async (data) => {
  const { createdBy, newQuestionSet, cloneQuestionSetId, stateId } = data.data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }QuestionSet/CloneQuestionSet?createdBy=${createdBy}&newQuestionSet=${newQuestionSet}&cloneQuestionSetId=${cloneQuestionSetId}&stateId=${stateId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getQuestionSetOnProject = async ({ data }) => {
  const { projectId, orgId } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }QuestionSet/GetQuestionSetOnProject?projectId=${projectId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addQuestionSetProjectMap = async ({ data }) => {
  const url = `${
    getApiUrl().adminSWApiUrl
  }QuestionSet/AddQuestionSetProjectMap`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: data?.orgId ? data?.orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateQuestionSetProjectMap = async ({ data }) => {
  const url = `${
    getApiUrl().adminSWApiUrl
  }QuestionSet/UpdateQuestionSetProjectMap`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: data?.orgId ? data?.orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const UpdateStatusQuestionSetProjectMap = async ({ data }) => {
  const url = `${
    getApiUrl().adminSWApiUrl
  }QuestionSet/UpdateStatusQuestionSetProjectMap`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: data.orgId ? data.orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getQuestionSetProjectMapDetails = async (data) => {
  const { mapId, userId } = data.data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }QuestionSet/GetQuestionSetProjectMapDetails?mapId=${mapId}&userId=${userId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: data.orgId ?? ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getQuestionSetProjectMapList = async ({ data }) => {
  const {
    search,
    userId,
    customerId,
    projectId,
    page,
    pageSize,
    status,
    orgId,
  } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }QuestionSet/GetQuestionSetProjectMapList?search=${search}&userId=${userId}&customerId=${customerId}&status=${status}&projectId=${projectId}&pageSize=${pageSize}&page=${page}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: orgId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const addCategory = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Utility/AddCategory`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateCategory = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Utility/UpdateCategory`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getCategoryList = async ({ data }) => {
  const { search, page, pageSize } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Utility/GetCategoryList?search=${search}&pageSize=${pageSize}&page=${page}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addSubCategory = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Utility/AddSubCategory`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateSubCategory = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Utility/UpdateSubCategory`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getSubCategoryList = async ({ data }) => {
  const { search, page, pageSize } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Utility/GetSubCategoryList?search=${search}&pageSize=${pageSize}&page=${page}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addQuestion = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Utility/AddQuestionWithOption`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateQuestion = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Utility/UpdateQuestionWithOption`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getQuestionList = async ({ data }) => {
  const { subcatId } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Utility/GetQuestionList?subcatId=${subcatId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addInspectionType = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Inspection/AddInspectionType`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateInspectionType = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Inspection/UpdateInspectionType`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getInspectionTypeList = async ({ data }) => {
  const { search, stateId, userId, isActive, pageSize, page } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Inspection/GetInspectionTypeList?PageSize=${pageSize}&IsActive=${isActive}&Search=${search}&StateId=${stateId}&UserId=${userId}&Page=${page}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getInspectionTypeFieldsList = async () => {
  const url = `${getApiUrl().adminSWApiUrl}Inspection/GetInspectionTypeFields`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getBindInspectionTypeFromStateList = async ({ data }) => {
  const { stateId } = data;

  const url = `${
    getApiUrl().adminSWApiUrl
  }Inspection/BindInspectionTypeFromState?stateId=${stateId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getInTyFeByInTyList = async ({ data }) => {
  const { inspectionTypeId, stateId, projectId, orgId } = data;

  const url = `${
    getApiUrl().adminSWApiUrl
  }Inspection/GetInspecionTypeFieldsByInspectionType?InspectionTypeId=${inspectionTypeId}&stateId=${stateId}&projectId=${projectId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateInspectionTypeFieldsMap = async ({ data }) => {
  const url = `${
    getApiUrl().adminSWApiUrl
  }Inspection/UpdateInspectionTypeFieldsMap`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// user

export const getSwInspectionList = async ({ data }) => {
  const {
    customerId,
    inspectionTypeId,
    projectId,
    statusId,
    startDate,
    endDate,
    page,
    pageSize,
    search,
    userId,
    isDailyReport = false,
    frequencyId = "",
  } = data;
  const url = `${
    getApiUrl().swIncpectionApiUrl
  }GetInspectionList?projectId=${projectId}&userId=${userId}&pageSize=${pageSize}&page=${page}&search=${search}&inspectionTypeId=${inspectionTypeId}&frequencyId=${frequencyId}&customerId=${customerId}&statusId=${statusId}&startDate=${startDate}&endDate=${endDate}&isDailyReport=${isDailyReport}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: customerId ? customerId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addSwInspection = async ({ data }) => {
  const url = `${getApiUrl().swIncpectionApiUrl}AddInspection`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: data?.orgId ? data?.orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateSwInspection = async ({ data }) => {
  const url = `${getApiUrl().swIncpectionApiUrl}UpdateInspection`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: data?.orgId ? data?.orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getSwInspectionDetail = async (data) => {
  const { InspectionId, setupId, projectId, questionSetId, orgId } = data;
  const url = `${
    getApiUrl().swIncpectionApiUrl
  }GetInspectionReportView?InspectionId=${
    InspectionId ? InspectionId : ""
  }&SetupId=${setupId ? setupId : ""}&projectId=${
    projectId ? projectId : ""
  }&questionSetId=${questionSetId ? questionSetId : ""}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const deleteSwInspection = async ({ payload }) => {
  const url = `${getApiUrl().swIncpectionApiUrl}DeleteInspection/${
    payload?.selectedRow
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: payload?.cusid ? payload?.cusid : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// primary and secondry inspector

export const getSwInspectorList = async ({ data }) => {
  const { page, pageSize, search, UserId, InspectionDayId, FrequencyId } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Inspection/GetInspectionAuthorityList?Search=${search}&InspectionDayId=${InspectionDayId}&FrequencyId=${FrequencyId}&UserId=${UserId}&Page=${page}&PageSize=${pageSize}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const assignSwInspector = async ({ data }) => {
  const url = `${getApiUrl().adminSWApiUrl}Inspection/AssignInspector`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getSwSecondaryInspectorList = async ({ data }) => {
  const { setupId } = data;
  const url = `${
    getApiUrl().adminSWApiUrl
  }Inspection/GetManageSecondaryInspectorList?setupId=${setupId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteSwInspector = async ({ payload }) => {
  const data = {
    id: payload.id,
    userId: payload.userId,
  };
  const url = `${getApiUrl().adminSWApiUrl}Inspection/DeleteAssignInspector`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
// sw dashboard swDashboardCardDetails
export const swDashboardCardDetails = async ({ data }) => {
  const { cartType, userId, page, pageSize, organizationId, isTeamTask } = data;
  const url = `${
    getApiUrl().swIncpectionApiUrl
  }GetDashboardCartDetails?cartType=${cartType}&userId=${
    userId ? userId : ""
  }&page=${page}&pageSize=${pageSize}&organisationId=${organizationId}&isTeamTask=${isTeamTask}`;
  //&organisationId=${ORG_ID()}
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const swGetOpenItemDetails = async ({ data }) => {
  const { orgId } = data;
  const url = `${getApiUrl().swIncpectionApiUrl}GetOpenItemDetails`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const swInspectionCloseItem = async ({ data }) => {
  const url = `${getApiUrl().swIncpectionApiUrl}InspectionCloseItem`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const swAddInspectionItemComment = async ({ data }) => {
  const url = `${getApiUrl().swIncpectionApiUrl}AddInspectionItemComment`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
// sw dashboard differ inspection
export const diferredSwInspection = async ({ data }) => {
  const url = `${getApiUrl().swIncpectionApiUrl}DeferredInspection`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getOpenItemDashboardDetails = async ({ data }) => {
  const {
    organisationId,
    cartType,
    search,
    userId,
    fromDate,
    toDate,
    page,
    pageSize,
    projectId,
  } = data;
  const url = `${
    getApiUrl().swIncpectionApiUrl
  }GetOpenItemDashboardDetails?Search=${search}&organisationId=${organisationId}&cartType=${cartType}&userId=${userId}&Page=${page}&PageSize=${pageSize}&toDate=${toDate}&fromDate=${fromDate}&projectId=${projectId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: organisationId ? organisationId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getExternalInspection = async ({ data }) => {
  const { inspectionId, orgId } = data;
  const url = `${
    getApiUrl().swIncpectionApiUrl
  }GetExternalInspection?inspectionId=${inspectionId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const conductExternalInspection = async ({ data }) => {
  const url = `${getApiUrl().swIncpectionApiUrl}ConductExternalInspection`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getInspectionHeaderDetails = async ({ data }) => {
  const { projectId, setupId, orgId, inspectionType } = data;
  const url = `${
    getApiUrl().swIncpectionApiUrl
  }GetInspectionHeaderDetails?setupId=${setupId}&projectId=${projectId}&inspectionType=${inspectionType}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getInspectionDailyReportDetails = async ({ data }) => {
  const { inspectionId, orgId } = data;
  const url = `${
    getApiUrl().swIncpectionApiUrl
  }GetInspectionDailyReportDetails?InspectionId=${inspectionId}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        OrgId: orgId ? orgId : ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateInspectionDailyReport = async ({ data }) => {
  const url = `${getApiUrl().swIncpectionApiUrl}updateInspectionDailyReport`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getBulkInspectionDetails = async ({ data }) => {
  const url = `${getApiUrl().swIncpectionApiUrl}GetBulkInspectionDetails`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getBulkInspectionFiles = async ({ data }) => {
  const { page, pageSize, userId } = data;
  const url = `${
    getApiUrl().swIncpectionApiUrl
  }GetBulkInspectionFiles?userId=${userId}&page=${page}&pageSize=${pageSize}`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const getProjectContactsEmailDetails = async ({ data }) => {
  const url = `${getApiUrl().swIncpectionApiUrl}GetProjectContactsEmailDetails`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: data?.orgId,
      },
    });
  } catch (err) {
    return err;
  }
};
