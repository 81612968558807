import { Grid, Button, Popover, Typography, Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Buttons from "../../../../library/custom/button/Button";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import {
  ISwitch,
  USER_ID,
  SAMPLINGREQUIREDDATA,
  PROJECT_SW_TEMPLATE_URL,
  ORG_ID,
  ORG_NAME,
  HBNEXT_ORG_ID,
} from "../../../../constant/constant";
import FormControl from "@mui/material/FormControl";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { NumberField } from "../../../../library/custom/numberField/numberField";
import DatePickers from "../../../../library/custom/datePicker/DatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useNavigate } from "react-router-dom";
import TextArea from "../../../../library/custom/textArea/TextArea";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import { getFormatedDate, checkAlphnumeric, parseDate } from "../../../../utils/helper";
import { PROJECT_SW_GRID_COLOUMNS } from "../../../../constant/storm-water/constant";
import {
  ADMIN_PROJECTS_LIST,
  INSPECTION_AUTHORITY,
  SETUP_INSPECTION,
  SETUP_INSPECTION_LIST,
  SW_ADMIN_SAMPLINGLOG,
  SW_CONTACT_PROJECT,
  SW_INSPECTION_LIST,
  SW_OPEN_ITEMS,
  SW_PERMIT_LIST,
  SW_RAIN_FALL_DATA,
  ADMIN_RESOURCE,
} from "../../../../constant/routeContant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { dateValiadtor } from "../../../../utils/helper";
import LocationField from "../../../../library/common/LocationField";
import "./swaddproject.scss";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import ToolBar from "../../../../library/storm-water/toolBar";
import * as adminAction from "../../../../redux/actions/adminCommonAction";
import {
  PRODUCT_ID,
  TIMEZONE_OPTION,
} from "../../../../constant/storm-water/constant";
import { useDispatch } from "react-redux";
import QrCodeDownload from "./QrCodeDownload";

function AddProject({
  editMode,
  state,
  city,
  getCity,
  addProject,
  getSwProjectDetailData,
  viewMode,
  projectId,
  onSearchSite,
  searchSitedata,
  isLoading,
  searchClient,
  searchAllClientsReducerData,
  autoSearchDataLoading,
  customerId,
  customer,
  stateId,
  getSiteDetsil,
  getSiteDetsilsData,
  divisions,
  setcustomer,
  getSwReference,
  getSwReferenceData,
  projectDataQr,
  generateQr,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerName = customer ?? ORG_NAME();
  const [formType, setFormType] = useState(1);
  const projectid = projectId;
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [division, setDivision] = useState("");
  const [divisionError, setDivisionError] = useState(false);
  const autoCompSupervisor = useRef(null);
  const [siteName, setSiteName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [totalArea, setTotalArea] = useState("");
  const [responsibleAuthority, setResponsibleAuthority] = useState("");
  const [projectType, setProjectType] = useState("");
  const [location, setLocations] = useState("");
  const [samplingrequired, setSamplingrequired] = useState("");
  const [states, setStates] = useState(0);
  const [cities, setCity] = useState(0);
  const [addNotes, setAddNotes] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [ownerName, setOwnerName] = useState("");
  const [checked, setChecked] = useState(true);
  const [projectTypecheck, setProjectTypecheck] = useState("1");
  const [adress, setAdress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [siteNames, setSiteNames] = useState("");
  const [rainfall, setRainfall] = useState("");
  const history = useNavigate();
  const [projectNameError, setProjectNameError] = useState(false);
  const [siteNameError, setSiteNameError] = useState(false);
  const [stateNameError, setStateNameError] = useState(false);
  const [addressNameError, setAddressNameError] = useState(false);
  const [sampleReqNameError, setSampleReqNameError] = useState(false);
  const [dateErrMsg, setDateErrMsg] = useState("");
  const [rainfallError, setRainfallError] = useState(false);
  const [locationError, setLocationsError] = useState(false);
  const [clients, setClients] = useState();
  const [timezone, setTimezone] = useState(null);
  const [isFluid, setIsFluid] = useState(null);
  const [isfluidError, setisfluidError] = useState(false);
  useEffect(() => {
    if ((getSwProjectDetailData?.data && editMode) || viewMode) {
      const dateStr = getSwProjectDetailData?.data?.result?.startDate;
      const parsedDate = parseDate(dateStr, 'MM-dd-yyyy');
      setFormType(2);
      getCity(getSwProjectDetailData?.data?.result?.stateId);
      setProjectTypecheck(
        getSwProjectDetailData?.data?.result?.isStandalone ? "1" : "0"
      );

      setProjectName(getSwProjectDetailData?.data?.result?.name);
      setJobNumber(getSwProjectDetailData?.data?.result?.projectNumber);
      setTotalArea(getSwProjectDetailData?.data?.result?.coveredArea);
      setResponsibleAuthority(
        getSwProjectDetailData?.data?.result?.responsibleAuthority
      );
      setLocations(getSwProjectDetailData?.data?.result?.location);
      setAddNotes(getSwProjectDetailData?.data?.result?.notes);
      setSamplingrequired(
        getSwProjectDetailData?.data?.result?.isSamplingRequired ? "0" : "1"
      );
      setAdress(getSwProjectDetailData?.data?.result?.address);
      const zipCode = getSwProjectDetailData?.data?.result?.zip;
      setZipCode(
        zipCode && zipCode > 10
          ? zipCode.substring(zipCode.length - 5, zipCode.length)
          : zipCode
      );
      setProjectType(getSwProjectDetailData?.data?.result?.projectTypeId);
      setStates(getSwProjectDetailData?.data?.result?.stateId);
      setCity(getSwProjectDetailData?.data?.result?.cityId);
      setStartDate(parsedDate);
      setChecked(getSwProjectDetailData?.data?.result?.isActive ? 1 : 0);
      setSiteName(getSwProjectDetailData?.data?.result?.siteId);
      setSiteNames(getSwProjectDetailData?.data?.result?.site);
      setRainfall(getSwProjectDetailData?.data?.result?.rainfallThreshold);
      setsitenamchange({
        key: getSwProjectDetailData?.data?.result?.siteId,
        value: getSwProjectDetailData?.data?.result?.site,
      });
      if (parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID)) {
        setClients(customerId ? customerId : "");
      }
      setDivision(getSwProjectDetailData?.data?.result?.divisionId);
      setchangesearchauto({ key: customerId ?? "", value: customer ?? "" });
      setTimezone(getSwProjectDetailData?.data?.result?.timezone);
      setIsFluid(
        getSwProjectDetailData?.data?.result?.isFluid === true ? "1" : "2"
      );
    }
  }, [getSwProjectDetailData?.data]);
  const handleFileChanges = (data) => {
    const projects = data.map((x, index) => {
      x.client = x.client ? parseInt(x.client) : 0;

      x.isStandalone = x.isStandalone?.toUpperCase() == "FALSE" ? false : true;
      x.siteId = x.siteId ? parseInt(x.siteId) : 0;
      x.divisionId = x.divisionId ? parseInt(x.divisionId) : 0;
      x.name = x.name ? x.name : "";
      x.projectNumber = x.projectNumber ? x.projectNumber : "";
      x.coveredArea = x.DisturbedAcreage
        ? parseFloat(x.DisturbedAcreage)
        : null;
      x.responsibleAuthority = x.PermitEnforecement
        ? x.PermitEnforecement
        : null;
      x.projectTypeId = x.projectTypeId ? parseInt(x.projectTypeId) : null;
      x.location = x.location ? x.location : "";
      x.stateId = parseInt(x.stateId);
      x.cityId = x.cityId ? parseInt(x.cityId) : 0;
      x.address = x.address ? x.address : "";
      x.zip = x.zip ? x.zip : "";
      x.startDate = x.startDate ? x.startDate : null;
      x.primaryOwner = "";
      x.rainfallThreshold = x.rainfallThreshold
        ? parseFloat(x.rainfallThreshold)
        : "";

      x.exceedance = true;
      x.forecast = false;
      x.isSamplingRequired =
        x.isSamplingRequired?.toUpperCase() == "FALSE" ? false : true;
      x.notes = x.notes ? x.notes : "";
      x.isActive = x.isActive?.toUpperCase() == "FALSE" ? false : true;
      x.createdBy = parseInt(USER_ID());

      return x;
    });
    const projectData = {
      projectDetails: projects,
    };

    addProject(projectData);
  };

  const [sitenamchange, setsitenamchange] = useState({
    key: "",
    value: "",
  });
  const onSelectSearchSite = (e, value, s) => {
    setsitenamchange({ key: value?.key ?? "", value: value?.value ?? "" });
    setSiteNames(value?.value);
    setSiteName(value?.key || null);
    getSiteDetsil(value?.key, clients);
    setStateNameError(false);
  };
  const onChangeAdress = (event) => {
    setAdress(event.target.value);
    setAddressNameError(false);
  };

  const onChangeProjectName = (event) => {
    if (event.target.value) {
      const checkDataval = checkAlphnumeric(event.target.value);
      if (checkDataval) {
        setProjectName(event.target.value);
      }
    } else {
      setProjectName("");
    }
  };

  const onChangeResponsibleAuthority = (event) => {
    if (event.target.value) {
      const checkDataval = checkAlphnumeric(event.target.value);
      if (checkDataval) {
        setResponsibleAuthority(event.target.value);
      }
    } else {
      setResponsibleAuthority("");
    }
  };

  const onChangeState = (event) => {
    setStateNameError(false);
    const stateId = event.target.value;
    getCity(stateId);
    setStates(event.target.value);
  };

  const onChangeCity = (event) => {
    setCity(event.target.value);
  };
  const handleAddNotesChange = (event) => {
    setAddNotes(event.target.value);
  };

  const onchangeStartDate = (e) => {
    setStartDate(e);
    setDateErrMsg(false);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleIsFluidChange = (event) => {
    setIsFluid(event.target.value);
    setisfluidError(false);
  };
  const onChangeSamplingRequired = (event) => {
    setSamplingrequired(event.target.value);
    setSampleReqNameError(false);
  };

  const handlecheckbtn = (e, value) => {
    setProjectTypecheck(e?.target?.value);
    if (!editMode) {
      setProjectNameError(false);
      setSiteNameError(false);
      setStateNameError(false);
      setAddressNameError(false);
      setSampleReqNameError(false);
      setRainfallError(false);
      setLocationsError(false);
      setSiteName("");
      setProjectName("");
      setJobNumber("");
      setTotalArea("");
      setResponsibleAuthority("");
      setProjectType("");
      setLocations("");
      setStates("");
      setCity("");
      setSamplingrequired("");
      setZipCode("");
      setAdress("");
      setAddNotes("");
      setRainfall("");
      setStartDate(null);
      setDateErrMsg(false);
      setOwnerName("");
      setDivision("");
      setsitenamchange({
        key: "",
        value: "",
      });

      setTimezone("");
      setDivisionError(false);
    }
  };

  const dataBack = {
    title: "Back to Projects",
    route: ADMIN_PROJECTS_LIST,
  };

  const addProjects = () => {
    let isFieldEmpty = false;

    if (projectName.trim() === "") {
      setProjectName("");
      setProjectNameError(true);
      isFieldEmpty = true;
    }

    if (!siteName && projectTypecheck == "0") {
      setSiteNameError(true);
      isFieldEmpty = true;
    }
    if (!states || states == 0) {
      setStateNameError(true);
      isFieldEmpty = true;
    }

    if (startDate) {
      const validDate = dateValiadtor(getFormatedDate(startDate));
      if (validDate === false) {
        setDateErrMsg(true);
        isFieldEmpty = true;
      } else if (validDate === "Valid date") {
        setDateErrMsg(false);
      }
    }
    if (location.trim() === "") {
      setLocations("");
      setLocationsError(true);
      isFieldEmpty = true;
    }

    if (samplingrequired === "") {
      setSampleReqNameError(true);
      isFieldEmpty = true;
    }
    if (rainfall === "") {
      setRainfallError(true);
    }

    if (!division) {
      setDivisionError(true);
      isFieldEmpty = true;
    }

    if (!isFluid) {
      setisfluidError(true);
      isFieldEmpty = true;
    }
    if (!isFieldEmpty) {
      const sendData = {
        projectDetails: [
          {
            siteId:
              siteName && projectTypecheck !== "1" ? parseInt(siteName) : 0,
            isStandalone: projectTypecheck == "1" ? true : false,
            name: projectName ? projectName.trim() : "",
            projectNumber: jobNumber ? jobNumber : "",
            coveredArea: totalArea ? parseFloat(totalArea) : null,
            responsibleAuthority: responsibleAuthority
              ? responsibleAuthority.trim()
              : null,
            projectTypeId: projectType ? parseInt(projectType) : null,
            location: location,
            isSamplingRequired: samplingrequired === "0" ? true : false,
            startDate: startDate == null ? null : getFormatedDate(startDate),
            stateId: states ? states : 0,
            cityId: cities ? cities : 0,
            primaryOwner: ownerName ? ownerName : "",
            isActive: checked,
            notes: addNotes,
            address: adress,
            zip: zipCode,
            createdBy: USER_ID(),
            rainfallThreshold: rainfall ? parseFloat(rainfall) : "",
            exceedance: true,
            forecast: false,
            client: clients ? clients : ORG_ID(),
            divisionId: division,
            clientName: customerName,
            timezone: timezone,
            isFluid: parseInt(isFluid) === 1 ? true : false,
          },
        ],
      };

      const sendEdit = {
        siteId: siteName && projectTypecheck !== "1" ? parseInt(siteName) : 0,
        isStandalone: projectTypecheck == "1" ? true : false,
        name: projectName ? projectName : "",
        projectNumber: jobNumber ? jobNumber : "",
        coveredArea: totalArea ? parseFloat(totalArea) : null,
        responsibleAuthority: responsibleAuthority
          ? responsibleAuthority
          : null,
        projectTypeId: projectType ? parseInt(projectType) : null,
        location: location,
        isSamplingRequired: samplingrequired === "0" ? true : false,
        startDate: startDate == null ? null : getFormatedDate(startDate),
        stateId: states ? states : 0,
        cityId: cities ? cities : 0,
        primaryOwner: ownerName ? ownerName : "",
        isActive: checked == 1 ? true : false,
        notes: addNotes,
        address: adress,
        zip: zipCode ? zipCode : "",
        projectId: projectId,
        modifiedBy: parseInt(USER_ID()),
        createdBy: USER_ID(),
        rainfallThreshold: rainfall ? parseFloat(rainfall) : " ",
        exceedance: true,
        forecast: false,
        client: clients ? clients : ORG_ID(),
        divisionId: division,
        timezone: timezone,
        isFluid: parseInt(isFluid) === 1 ? true : false,
      };

      addProject(editMode ? sendEdit : sendData);
    }
  };
  const handleAllSearchSearch = (event) => {
    const defaultdata = {
      search: event?.target?.value.trim(),
      customerId: clients ? clients : null,
    };

    onSearchSite(defaultdata);
  };
  const handleMinsonChange = (event) => {
    setRainfallError(false);
    const re = /^[0-9.\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      var count = (event.target.value.match(/\./g) || []).length;
      if (count > 1) {
        var minval = event.target.value.replace(/\.+$/, "");
      }

      setRainfall(event.target.value);
      if (minval) {
        setRainfall("");
      }
    }
  };
  const handleDisturbedChange = (event) => {
    const re = /^[0-9.\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      var count = (event.target.value.match(/\./g) || []).length;
      if (count > 1) {
        var minval = event.target.value.replace(/\.+$/, "");
      }
      setTotalArea(event.target.value);
      if (minval) {
        setTotalArea("");
      }
    }
  };
  const handleProjectNumberChange = (event) => {
    const re2 = /^[a-zA-Z0-9- ]+$/;
    if (event.target.value === "" || re2.test(event.target.value)) {
      setJobNumber(event.target.value);
    }
  };
  const onChangeLocation = (event) => {
    setLocations(event.target.value);
  };

  const typeOnchange = (event) => {
    searchClient(event.target.value.trim());
  };
  const [changesearchauto, setchangesearchauto] = useState({
    key: "",
    value: "",
  });
  const onSelectSearchCustomer = (e, value, s) => {
    setchangesearchauto({ key: value?.key ?? "", value: value?.value ?? "" });
    setClients(value?.key);
    setcustomer(value?.value);
    const flagData = {
      flags: [10],
      orgId: value?.key,
    };
    getSwReferenceData(flagData);
  };

  const toolBarDataEditMode = {
    title: "Project Tools",
    list: [
      {
        id: 11,
        title: "Edit Project",
        link: "",
        activeClass: "active",
        isshow: checkPrivileges([12, 111, 112, 113]),
      },
      {
        id: 1,
        title: "Project Contact",
        link: "",
        isshow: checkPrivileges([12, 132, 133, 134]),
      },
      {
        id: 2,
        title: "Inspection Service List",
        link: "",
        isshow: checkPrivileges([12, 99, 100, 101]),
      },
      {
        id: 9,
        title: "Setup Inspections",
        link: "",
        isshow: checkPrivileges([12, 99, 100]),
      },
      {
        id: 3,
        title: "Permits",
        link: "",
        isshow: checkPrivileges([12, 126, 127, 128]),
      },
      {
        id: 4,
        title: "Sampling Log",
        link: "",
        isshow: checkPrivileges([12, 129, 130, 131]),
      },
      {
        id: 5,
        title: "Open Items",
        link: "",
        isshow: checkPrivileges([12, 105, 106, 107]),
      },
      {
        id: 6,
        title: "Rainfall Data",
        link: "",
        isshow: true,
      },
      {
        id: 7,
        title: "Inspection Records",
        link: "",
        isshow: checkPrivileges([12, 102, 103, 104]),
      },

      {
        id: 8,
        title: "Inspection Authority",
        link: "",
        isshow: checkPrivileges([12, 96, 97, 98]),
      },
      {
        id: 12,
        title: "Project Resources",
        link: "",
        isshow: checkPrivileges([12, 73, 74, 75]),
      },
    ],
  };

  const site = searchSitedata?.find((i) => i.key === siteName);
  useEffect(() => {
    if (
      !getSiteDetsilsData?.isLoading &&
      getSiteDetsilsData?.status === "SUCCESS"
    ) {
      getCity(getSiteDetsilsData?.data?.result?.stateId);
      setAdress(getSiteDetsilsData?.data?.result?.address);
      setCity(getSiteDetsilsData?.data?.result?.cityId);
      setLocations(getSiteDetsilsData?.data?.result?.location);
      setStates(getSiteDetsilsData?.data?.result?.stateId);
      setZipCode(getSiteDetsilsData?.data?.result?.zip);
      setTotalArea(getSiteDetsilsData?.data?.result?.disturbedArea);
      setTimezone(getSiteDetsilsData?.data?.result?.timezone);
    }
  }, [getSiteDetsilsData]);

  const onChangeDivision = (e) => {
    setDivision(e.target.value);
  };
  useEffect(() => {
    const getDropdownDataKeyValueData = {
      orgId: clients ? clients : parseInt(ORG_ID()),
      flags: "6",
      productId: PRODUCT_ID(),
      Clientid: clients ? clients : null,
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, [clients]);
  const onChangeTimeZone = (e) => {
    setTimezone(e.target.value);
  };

  const mapBulkError = (data) => {
    let swprojectsWithError = [];
    data.forEach((x) => {
      if (
        x.name?.trim() === undefined ||
        null ||
        x.stateId?.trim() === undefined ||
        null ||
        "" ||
        x.divisionId?.trim() === undefined ||
        null ||
        "" ||
        x.location?.trim() === undefined ||
        null ||
        "" ||
        x.isSamplingRequired?.trim() === undefined ||
        null ||
        "" ||
        x.rainfallThreshold?.trim() === undefined ||
        null ||
        "" ||
        x.isStandalone?.trim() === undefined ||
        null ||
        "" ||
        x.siteId?.trim() === undefined ||
        null ||
        "" ||
        (x.isStandalone?.trim().toUpperCase() === "TRUE" &&
          x.siteId?.trim() !== "0") ||
        (x.isStandalone?.trim().toUpperCase() === "FALSE" &&
          x.siteId?.trim() === "0") ||
        ""
      ) {
        swprojectsWithError.push(x);
        return;
      }
    });
    return swprojectsWithError;
  };

  const [anchorElqr, setAnchorElqr] = useState(null);
  const openQr = Boolean(anchorElqr);
  const idQr = openQr ? "simple-popover" : undefined;

  const qrCodeRef = useRef(null);
  const onClickQrCodeDownload = (event) => {
    setAnchorElqr(event.currentTarget);
  };

  const handleCloseQr = () => {
    setAnchorElqr(null);
  };

  const handleDownloadAsPDF = () => {
    qrCodeRef.current?.(projectDataQr, "pdf");
    handleCloseQr();
  };

  const handleDownloadAsPNG = () => {
    qrCodeRef.current?.(projectDataQr, "png");
    handleCloseQr();
  };

  return (
    <div className="overidePersonal">
      <Grid container>
        <Grid lg={8} md={12} xs={12} pr={2}>
          <Grid container item lg={12} xs={12} sm={12} md={9}>
            <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
              <div className="heading_style">
                {editMode
                  ? "Edit Project"
                  : viewMode
                  ? "View Project"
                  : "Add Project"}
              </div>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={4} textAlign="right">
              {!editMode && checkPrivileges([12, 111]) ? (
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={
                    isBulkUpload
                      ? "Add Single Project"
                      : "Add Multiple Projects"
                  }
                  varientContained={true}
                  onClick={() => setIsBulkUpload(!isBulkUpload)}
                ></Buttons>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <div>
              {isBulkUpload ? (
                <Grid container>
                  <Grid item lg={12} xs={9} sm={9} md={9} mt={4}>
                    <BulkUpload
                      handleFileChanges={handleFileChanges}
                      mapBulkError={mapBulkError}
                      paraText1={"Drop the files here ..."}
                      paraText2={"Drop the files here, "}
                      paraText3={"or Browse"}
                      templateUrl={PROJECT_SW_TEMPLATE_URL}
                      templateText="Download Template"
                      gridColoumns={PROJECT_SW_GRID_COLOUMNS}
                      isCustomColoumns={true}
                    />
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container item>
                    <Grid container spacing={1} mt={1}>
                      {/* Left side: Fields */}
                      <Grid item lg={8} md={6} xs={12}>
                        <Grid
                          container
                          direction="column"
                          lg={8}
                          md={6}
                          xs={12}
                          spacing={2}
                        >
                          {parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) &&
                            checkPrivileges([12]) && (
                              <>
                                <Grid item>
                                  <FormControl fullWidth>
                                    <FormLabels
                                      label="Client"
                                      isRequired={false}
                                    />
                                    <SearchAutoComplete
                                      name="Customer"
                                      optionsData={
                                        searchAllClientsReducerData?.data ?? []
                                      }
                                      typeOnchange={(event, value) =>
                                        typeOnchange(event)
                                      }
                                      isKeyValuePair={true}
                                      value={
                                        changesearchauto ? changesearchauto : ""
                                      }
                                      onChange={(event, value, key) =>
                                        onSelectSearchCustomer(
                                          event,
                                          value,
                                          key
                                        )
                                      }
                                      isLoading={autoSearchDataLoading}
                                      noOptionsTextMsg="No Client"
                                    />
                                  </FormControl>
                                </Grid>
                              </>
                            )}
                          <Grid item>
                            <FormControl fullWidth>
                              <FormLabels label={"Project Type"} />
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                className="radioGroup"
                              >
                                <RadioButton
                                  checked={projectTypecheck === "1"}
                                  value="1"
                                  label="Standalone"
                                  onChange={(e, value) =>
                                    handlecheckbtn(e, value)
                                  }
                                />
                                <RadioButton
                                  checked={projectTypecheck === "0"}
                                  value="0"
                                  label="Under a Site"
                                  onChange={(e, value) =>
                                    handlecheckbtn(e, value)
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          <Grid item>
                            <FormControl fullWidth>
                              <FormLabels
                                label={"Fluid Inspection"}
                                isRequired={true}
                              />
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                className="radioGroup"
                              >
                                <RadioButton
                                  checked={parseInt(isFluid) === 1}
                                  value="1"
                                  label="Yes"
                                  onChange={(e, value) =>
                                    handleIsFluidChange(e, value)
                                  }
                                  disabled={editMode}
                                />
                                <RadioButton
                                  checked={parseInt(isFluid) === 2}
                                  value="2"
                                  label="No"
                                  onChange={(e, value) =>
                                    handleIsFluidChange(e, value)
                                  }
                                  disabled={editMode}
                                />
                              </RadioGroup>
                              {isfluidError && (
                                <div className="errorMsg">
                                  Please select required field
                                </div>
                              )}
                            </FormControl>
                          </Grid>

                          {projectTypecheck === "0" && (
                            <Grid item>
                              <FormControl fullWidth>
                                <FormLabels
                                  label="Site Name"
                                  isRequired={true}
                                />
                                <SearchAutoComplete
                                  name="Site"
                                  isKeyValuePair={true}
                                  typeOnchange={(event, value) =>
                                    handleAllSearchSearch(event)
                                  }
                                  optionsData={searchSitedata}
                                  isLoading={isLoading}
                                  onChange={(event, value, key) =>
                                    onSelectSearchSite(event, value, key)
                                  }
                                  value={sitenamchange ? sitenamchange : ""}
                                  autoCompClear={autoCompSupervisor}
                                  noOptionsTextMsg="No Site"
                                />
                                {siteNameError &&
                                  !siteName &&
                                  projectTypecheck === "0" && (
                                    <div className="errorMsg">
                                      Please Select this required field
                                    </div>
                                  )}
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      {/* Right side: QR Code and Button */}
                      {editMode && (
                        <Grid item lg={4} md={6} xs={12}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item>
                              <QrCodeDownload
                                ref={qrCodeRef}
                                projectData={projectDataQr}
                                renderScanner={
                                  generateQr ||
                                  projectDataQr?.[0]?.projectNumber
                                    ? true
                                    : false
                                }
                              />
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                style={{
                                  height: "44px",
                                  backgroundColor: "#34A853",
                                  color: "white",
                                  borderRadius: "100px",
                                  textTransform: "none",
                                  boxShadow: "none",
                                  transition: "none",
                                  fontFamily: "Lato",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  padding: "0px 15px",
                                }}
                                onClick={onClickQrCodeDownload}
                              >
                                Download QR Code
                              </Button>
                              <Popover
                                id={idQr}
                                open={openQr}
                                anchorEl={anchorElqr}
                                onClose={handleCloseQr}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <Box
                                  sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                    boxShadow: "none",
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{ cursor: "pointer", color: "#333333" }}
                                    onClick={handleDownloadAsPDF}
                                  >
                                    Download PDF
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ cursor: "pointer", color: "#333333" }}
                                    onClick={handleDownloadAsPNG}
                                  >
                                    Download PNG
                                  </Typography>
                                </Box>
                              </Popover>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item container mt={1} spacing={2}>
                      <Grid item lg={6} xs={12} sm={12} md={4}>
                        <FormControl fullWidth>
                          <FormLabels label="Division" isRequired={true} />
                          <SelectMenu
                            placeholder="Please Select"
                            listData={divisions}
                            value={division}
                            onchangehandler={(e) => onChangeDivision(e)}
                          />
                        </FormControl>
                        {divisionError && !division && (
                          <div className="errorMsg">
                            Please enter this required field
                          </div>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item container spacing={2} mt={1}>
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <InputField
                            isRequired={true}
                            type="text"
                            label="Project Name"
                            placeholder={"Add Project Name Here..."}
                            value={projectName}
                            onChange={(e) => onChangeProjectName(e)}
                          />
                          {projectNameError && !projectName && (
                            <div className="errorMsg">
                              Please enter this required field
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <InputField
                            disabled={false}
                            isRequired={false}
                            type="text"
                            label="Project/Job Number"
                            value={jobNumber}
                            placeholder={"Add Project/Job Number Here..."}
                            onChange={(event, value) =>
                              handleProjectNumberChange(event)
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <FormLabels
                            label={"Disturbed Acreage"}
                            isRequired={false}
                          />

                          <InputField
                            fullWidth={true}
                            disabled={false}
                            type="text"
                            placeholder={"Add disturbed acreage here..."}
                            value={totalArea}
                            onChange={(event, value) =>
                              handleDisturbedChange(event)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <InputField
                            isRequired={false}
                            type="text"
                            label="Permit Enforecement"
                            placeholder={"Add Permit Enforecement Here..."}
                            value={responsibleAuthority}
                            onChange={(e) => onChangeResponsibleAuthority(e)}
                            disabled={viewMode ? true : false}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item container mt={1} spacing={2}>
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <LocationField
                            icon={true}
                            isRequired={true}
                            disabled={
                              editMode && projectTypecheck === "0"
                                ? true
                                : false
                            }
                            type="text"
                            label="Location"
                            placeholder={"Add Location Here..."}
                            value={location}
                            onChangeLocations={(e) => setLocations(e)}
                            onChange={(e) => onChangeLocation(e)}
                          />
                          {locationError && !location && (
                            <div className="errorMsg">
                              Selection is required for this field
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <FormLabels label="Timezone" isRequired={false} />
                          <SelectMenu
                            listData={TIMEZONE_OPTION}
                            value={timezone}
                            onchangehandler={(e) => onChangeTimeZone(e)}
                            placeholder="Please Select"
                            disabled={
                              editMode && projectTypecheck === "0"
                                ? true
                                : false
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      mt={1}
                      spacing={2}
                      className="swproject"
                    ></Grid>

                    <>
                      <Grid item container mt={1} spacing={2}>
                        <Grid item lg={6} xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <FormLabels label="State" isRequired={true} />
                            <SelectMenu
                              listData={state}
                              value={states}
                              onchangehandler={(e) => onChangeState(e)}
                              placeholder="Please Select"
                              disabled={
                                (viewMode || projectTypecheck === "0"
                                  ? true
                                  : false) ||
                                (editMode && true)
                              }
                            />
                            {stateNameError && (
                              <div className="errorMsg">
                                Selection is required for this field
                              </div>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <FormLabels label="City" isRequired={false} />
                            <SelectMenu
                              listData={city}
                              value={cities}
                              onchangehandler={(e) => onChangeCity(e)}
                              placeholder="Please Select"
                              disabled={
                                viewMode || projectTypecheck === "0"
                                  ? true
                                  : false
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>

                    <>
                      <Grid item container mt={1} spacing={2}>
                        <Grid item lg={6} xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <InputField
                              isRequired={false}
                              type="text"
                              label="Address/Street"
                              placeholder={"Add Address/Street Here..."}
                              value={adress}
                              onChange={(e) => onChangeAdress(e)}
                              disabled={
                                viewMode || projectTypecheck === "0"
                                  ? true
                                  : false
                              }
                            />
                            {addressNameError && (
                              <div className="errorMsg">
                                Please enter this required field
                              </div>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <NumberField
                              isRequired={false}
                              type="text"
                              label={"Zip Code"}
                              maxLength="5"
                              value={zipCode}
                              placeholder={"Add Zip Code Here..."}
                              onChange={(value) =>
                                value.length <= 5 ? setZipCode(value) : zipCode
                              }
                              disabled={
                                viewMode || projectTypecheck === "0"
                                  ? true
                                  : false
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                    <Grid item container mt={1} spacing={2}>
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        {" "}
                        <FormControl fullWidth>
                          <FormLabels
                            label={"Rainfall Threshold (in inches)"}
                            isRequired={true}
                          />

                          <InputField
                            fullWidth={true}
                            disabled={false}
                            type="text"
                            placeholder={"Add Rainfall Threshold Here..."}
                            value={rainfall}
                            onChange={(event, value) =>
                              handleMinsonChange(event)
                            }
                          />
                          {rainfallError && (
                            <div className="errorMsg">
                              Please enter this required field
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <FormLabels label={"Start Date"} />
                          <DatePickers
                            maxDateEnable={true}
                            value={startDate}
                            onChangeHandler={(e) => onchangeStartDate(e)}
                            disabled={true}
                          />
                        </FormControl>

                        {dateErrMsg && startDate != null && (
                          <div className="errorMsg">Invalid Date</div>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item container mt={1} spacing={2}>
                      <Grid item lg={6} xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <FormLabels
                            label="Sampling Required"
                            isRequired={true}
                          />
                          <SelectMenu
                            listData={SAMPLINGREQUIREDDATA}
                            value={samplingrequired}
                            onchangehandler={(e) => onChangeSamplingRequired(e)}
                            placeholder="Please Select"
                            disabled={viewMode ? true : false}
                          />
                          {sampleReqNameError && (
                            <div className="errorMsg">
                              Selection is required for this field
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid mt={3} spacing={2} lg={12}>
                      <FormLabels label={"Add Notes"} isRequired={false} />

                      <TextArea
                        name={"issuefound"}
                        placeholder="Add notes here..."
                        value={addNotes ? addNotes : ""}
                        onChange={(event, value) => handleAddNotesChange(event)}
                        disabled={viewMode ? true : false}
                      />
                    </Grid>
                    {checkPrivileges([12, 111]) && (
                      <>
                        {" "}
                        <Grid container mt={3}>
                          <Grid lg={5} xs={12} sm={6} md={4}>
                            <FormControl
                              component="fieldset"
                              variant="standard"
                              className="clientswitch"
                            >
                              <FormLabels label="Status" />
                              <Grid>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <ISwitch
                                        checked={checked}
                                        onChange={handleChange}
                                        name="status"
                                      />
                                    }
                                    label={checked ? "Active" : "InActive"}
                                  />
                                </FormGroup>
                              </Grid>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {!viewMode && (
                      <>
                        <Grid container mt={3} spacing={1}>
                          <Grid
                            item
                            lg={6}
                            xs={12}
                            sm={12}
                            md={4}
                            textAlign="left"
                          >
                            <div className="swprojectback">
                              <BackBtn dataBack={dataBack} />
                            </div>
                          </Grid>
                          {checkPrivileges([12, 111, 112]) && (
                            <>
                              <Grid item lg={3} xs={12} sm={6} md={4}>
                                <div className="swcancel">
                                  <Buttons
                                    varientText={true}
                                    label="Cancel"
                                    onClick={() => history(-1)}
                                  />
                                </div>
                              </Grid>

                              <Grid item lg={3} xs={12} sm={6} md={4}>
                                <div className="swsave">
                                  <Buttons
                                    varientContained={true}
                                    label={editMode ? "Update" : "Save"}
                                    onClick={() => addProjects()}
                                  />
                                </div>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </div>
          </Grid>
        </Grid>
        {editMode ? (
          <Grid lg={4} md={12} xs={12} pl={1}>
            <div className="toolBarBox">
              {formType === 2 && (
                <div className="otherDetail">
                  <h3>Project Details</h3>
                  <h2>{projectName}</h2>
                  <h2>{jobNumber}</h2>
                </div>
              )}

              {formType === 2 && (
                <div className="otherDetail">
                  <h3>Client Name</h3>
                  <h2>{customerName}</h2>
                </div>
              )}
              {projectTypecheck === "0" && formType === 1 && (
                <div className="otherDetail">
                  <h3>Site Name</h3>
                  <h2>{site?.value}</h2>
                </div>
              )}
              {projectTypecheck === "0" && formType === 2 && (
                <div className="otherDetail">
                  <h3>Site Name</h3>
                  <h2>{siteNames}</h2>
                </div>
              )}
              {/* {projectTypecheck === "0" && (
                <div className="otherDetail">
                  <h3>Client Name</h3>
                  <h2>{customerName}</h2>
                </div>
              )} */}
              <ToolBar
                toolBarData={formType === 2 && toolBarDataEditMode}
                handleClick={(e) => {
                  if (e.id === 1) {
                    navigate(SW_CONTACT_PROJECT, {
                      state: {
                        projectName: projectName,
                        projectNumber: jobNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: clients ? clients : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 2) {
                    navigate(SETUP_INSPECTION_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: jobNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: clients ? clients : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }

                  if (e.id === 3) {
                    navigate(SW_PERMIT_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: jobNumber,
                        projectid: projectid,
                        customerName: customerName,
                        customerId: clients ? clients : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 4) {
                    navigate(SW_ADMIN_SAMPLINGLOG, {
                      state: {
                        projectName: projectName,
                        projectNumber: jobNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: clients ? clients : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 5) {
                    navigate(SW_OPEN_ITEMS, {
                      state: {
                        projectName: projectName,
                        projectNumber: jobNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: clients ? clients : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 6) {
                    navigate(SW_RAIN_FALL_DATA, {
                      state: {
                        projectName: projectName,
                        projectNumber: jobNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: clients ? clients : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 7) {
                    navigate(SW_INSPECTION_LIST, {
                      state: {
                        projectName: projectName,
                        projectNumber: jobNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: clients ? clients : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  }
                  if (e.id === 8) {
                    navigate(INSPECTION_AUTHORITY);
                  }
                  if (e.id === 9) {
                    navigate(SETUP_INSPECTION, {
                      state: {
                        projectName: projectName,
                        projectNumber: jobNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: clients ? clients : parseInt(ORG_ID()),
                        stateId: stateId,
                        rightSideMode: true,
                      },
                    });
                  }
                  if (e.id === 12) {
                    navigate(ADMIN_RESOURCE, {
                      state: {
                        projectName: projectName,
                        projectNumber: jobNumber,
                        projectId: projectId,
                        customerName: customerName,
                        customerId: clients ? clients : parseInt(ORG_ID()),
                        stateId: stateId,
                      },
                    });
                  } else return;
                }}
              />
            </div>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
}

export default AddProject;