import React, { useState } from "react";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../shared/ReportRowManipulate";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterList from "./FilterIncidentList";
import {
  PAGE_SIZE,
  ORG_ID,
  GET_ITEM_LOG,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import {
  setColumnVisibilityModel1,
  columnVisibilityModel,
} from "../../../../utils/helper";
import downloadIcon from "../../../../assets/downloadIcon.svg";

const KudosList = ({
  userId,
  isGetItemLogLoading,
  filterShow,
  getItemLogKudosData,
  itemListData,
  handleViewOnClickKudos,
  title,
  searchShow,
  filterButtonShow = false,
  getItemLogAllData,
  statusIds,
  setStatusIds,
  usersId,
  isSafetyLog = false,
  showDownloadButton = false,
}) => {
  //
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [incSearchText, setIncSearchText] = useState("");
  const [assetTypeId, setAssetTypeId] = useState();
  const [originId, setOriginId] = useState();
  
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const intialstate = {
    userId: userId,
    search: "",
    page: 1,
    pageSize: pageSize,
    projectId: 0,
    divisionId: 0,
    itemTypes: GET_ITEM_LOG.Kudos,
    statusIds: statusIds ? statusIds : "",
    fromDate: "",
    toDate: "",
    status: "",
    origin: "",
    assetType: "",
  };
  const [allOpenitemFilterData, setAllOpenItemFilterData] =
    useState(intialstate);

  const handleautoSearch = (event) => {
    let search = "";
    if (event.target.value) {
      search = event.target.value;
    }
    setIncSearchText(search);
    getItemLogKudosData({
      userId: isSafetyLog ? usersId : userId,
      page: page,
      pageSize: pageSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: GET_ITEM_LOG.Kudos,
      statusIds: isSafetyLog ? "" : statusIds,
      fromDate: startDate ?? "",
      toDate: endDate ?? "",
      status: statusIds ?? "",
      origin: originId ? originId : "",
      assetType: assetTypeId ? assetTypeId : "",
      organizationId: ORG_ID(),
      search: event.target.value,
    });
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.9,
      sortable: true,
      hideable: false,
      filterable: false,
    },
    {
      field: "origin",
      headerName: "Origin",
      flex: 1,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "itemType",
      headerName: "Item Type",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      renderCell: (rows) => {
        return (
          <>
            {rows?.row?.assetType === 0
              ? "NA"
              : rows?.row?.assetType === 1
              ? "Project"
              : rows?.row?.assetType === 3
              ? "Vehicle"
              : rows?.row?.assetType === 2
              ? "Equipment"
              : "Custom asset"}
          </>
        );
      },
      type: "text",
      flex: 1.2,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "jobsite",
      headerName: "Jobsite",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "inspectionTitle",
      headerName: "Inspection Title",
      flex: 1.5,
      minWidth: 100,
      sortable: true,
      filterable: false,
    },

    {
      field: "category",
      headerName: "Category",
      flex: 1.5,
      minWidth: 110,
      sortable: true,
      filterable: false,
    },
    {
      field: "subCategory",
      headerName: "Sub-Category",
      flex: 1.5,
      minWidth: 110,
      sortable: true,
      filterable: false,
    },
    {
      field: "questions",
      headerName: "Questions",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "location",
      headerName: "Jobsite",
      flex: 1.5,
      minWidth: 90,
      sortable: true,
      filterable: false,
    },
    {
      field: "involvedPartyName",
      headerName: "Involved Party",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },
    {
      field: "assignedToName",
      headerName: "Corrective Party",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },
    {
      field: "addedBy",
      headerName: "Added by",
      flex: 1.5,
      minWidth: 120,
      sortable: true,
      filterable: false,
    },
    {
      field: "dateOfEntry",
      headerName: "Date of Entry",
      flex: 1,
      minWidth: 100,
      sortable: true,
      filterable: false,
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 70,
      sortable: true,
      filterable: false,
    },
    {
      field: "icon",
      renderHeader: () =>
        filterShow ? (
          <SettingTable
            noOfRecords={itemListData?.result?.totalnoOfRecords ?? 0}
            numberOfItemsPerPage={PAGE_SIZE}
            incidentLists={itemListData?.result?.logsData ?? []}
            fileNameXL={"kudos-list"}
          />
        ) : (
          ""
        ),
      //  flex: 1,
      width: 70,
      minWidth: 70,
      maxWidth: 70,
      disableClickEventBubbling: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (rows) => {
        return filterShow ? (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={(row) =>
              handleViewOnClickKudos(
                row?.id,
                row?.itemType,
                title,
                row?.origin,
                row?.status,
                row?.addedById,
                row?.involvedPartyName
              )
            }
            view={checkPrivileges([117, 118, 119, 12])}
            viewLabel="View Kudos"
          />
        ) : (
          ""
        );
      },
    },
  ];

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getItemLogKudosData({
      userId: isSafetyLog ? usersId : userId,
      search: "",
      page: newPage + 1,
      pageSize: pageSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: GET_ITEM_LOG.Kudos,
      statusIds: isSafetyLog ? "" : statusIds,
      fromDate: startDate ?? "",
      toDate: endDate ?? "",
      status: statusIds ?? "",
      origin: originId ? originId : "",
      assetType: assetTypeId ? assetTypeId : "",
      organizationId: ORG_ID(),
    });
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    getItemLogKudosData({
      userId: isSafetyLog ? usersId : userId,
      search: "",
      page: page,
      pageSize: newPazeSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: GET_ITEM_LOG.Kudos,
      statusIds: isSafetyLog ? "" : statusIds,
      fromDate: startDate ?? "",
      toDate: endDate ?? "",
      status: statusIds ?? "",
      origin: originId ? originId : "",
      assetType: assetTypeId ? assetTypeId : "",
      organizationId: ORG_ID(),
    });
  };

  const handleClose = (event) => {
    event?.currentTarget.type === "button"
      ? setAnchorEl(event.currentTarget)
      : setAnchorEl(null);
  };
  const resetData = () => {
    setAssetTypeId();
    setOriginId();
    setAnchorEl(null);
    setStatusIds(2);
    getItemLogAllData({
      ...intialstate,
      page: 1,
      statusIds: 2,
      status: 2,
    });
  };

  const onRowClick = (rows) => {
    if (rows.value && checkPrivileges([117, 118, 119, 12])) {
      const { row } = rows;
      handleViewOnClickKudos(
        row?.id,
        row?.itemType,
        title,
        row?.origin,
        row?.status,
        row?.addedById,
        row?.involvedPartyName
      );
    }
  };

  const handleSearchFilter = (event, state) => {
    setAnchorEl(null);
    setAllOpenItemFilterData(state);
    setStatusIds(state.status);
    setEndDate(state.endDate);
    setStartDate(state.startDate);
    const data = {
      userId: userId,
      search: incSearchText,
      page: page,
      pageSize: pageSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: GET_ITEM_LOG.Kudos,
      statusIds: state.status,
      status: state.status,
      fromDate: state.startDate,
      toDate: state.endDate,
      origin: originId ? originId : "",
      assetType: assetTypeId ? assetTypeId : "",
    };
    getItemLogKudosData(data);
  };

  var retrievedObjectdata = JSON.parse(
    localStorage.getItem("columnVisibilityKudus")
  );
  const typeOnAssetChanges = (e) => {
    setAssetTypeId(e?.target?.value);
  };

  const typeOnOriginChanges = (e) => {
    setOriginId(e?.target?.value);
  };
  const handlesendEmail = () => {
    getItemLogKudosData({
      userId: isSafetyLog ? usersId : userId,
      search: "",
      page: page,
      pageSize: pageSize,
      projectId: 0,
      divisionId: 0,
      itemTypes: GET_ITEM_LOG.Kudos,
      statusIds: isSafetyLog ? "" : statusIds,
      fromDate: startDate ?? "",
      toDate: endDate ?? "",
      status: statusIds ?? "",
      origin: originId ? originId : "",
      assetType: assetTypeId ? assetTypeId : "",
      organizationId: ORG_ID(),
      isSendMail: true,
    });
  };
  return (
    <div className="grid_main_body">
      <div className="desc_box row_uniq">
        {searchShow ? (
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={incSearchText}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
            {/* <div className="filter_btn">
            <button
              aria-describedby={id}
              variant="contained"
              type="button"
              name="btn"
              onClick={""}
            >
              <FilterListIcon id="icn" /> Filters
            </button>
          </div> */}
            {filterButtonShow && (
              <div className="filter_btn">
                <button
                  variant="contained"
                  type="button"
                  name="btn"
                  onClick={(e) => handleClose(e)}
                >
                  <FilterListIcon id="icn" /> Filters
                </button>
                <FilterList
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  resetFilterAPI={resetData}
                  handleSearch={(event, state) =>
                    handleSearchFilter(event, state)
                  }
                  filterType={[]}
                  itemTypes={GET_ITEM_LOG.Kudos}
                  assetTypes={true}
                  assetTypeId={assetTypeId}
                  typeOnAssetChanges={typeOnAssetChanges}
                  origin={true}
                  originId={originId}
                  typeOnOriginChanges={typeOnOriginChanges}
                  bothRecords
                />
              </div>
            )}
          </div>
        ) : (
          ""
        )}

        {showDownloadButton ? (
          <div className="div-safety-log-filter-container">
            <div className="div-download-button-container">
              <div
                className="div-download-email-right"
                onClick={handlesendEmail}
              >
                <span>
                  <img
                    src={downloadIcon}
                    className="DownLoadLogo"
                    alt="DownLoad Logo"
                  />
                </span>
                Download Data
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="table_style_ui">
          <GridTable
            getRowId={(r) =>
              r?.id +
              "|" +
              r?.itemType +
              "|" +
              Date.now() +
              Math.floor(Math.random() * 100)
            }
            rows={itemListData?.result?.logsData ?? []}
            rowCount={itemListData?.result?.totalnoOfRecords ?? 0}
            columns={columns}
            onClick={(rows) => onRowClick(rows)}
            loading={isGetItemLogLoading}
            page={page}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={ROW_PER_PAGE_SIZE}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePagignation}
            backIconButtonProps={{ disabled: false }}
            disableSelectionOnClick
            components={{
              LoadingOverlay: TableLoader,
              NoRowsOverlay: NORow,
            }}
            disableColumnMenu={false}
            disableColumnSelector={false}
            disableColumnFilter={false}
            // initialState={{
            //   columns: {
            //     columnVisibilityModel: columnVisibilityModel,
            //   },
            // }}
            initialState={{
              columns: {
                columnVisibilityModel: retrievedObjectdata
                  ? retrievedObjectdata
                  : columnVisibilityModel,
              },
            }}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel1(newModel, "columnVisibilityKudus")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default KudosList;
