import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import * as clientAction from "../../../redux/actions/clientAction";
import {
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  SEFETY_HBN_CLIENT_PARTNER_PRI,
  SW_HBN_CLIENT_PARTNER_PRI,
} from "../../../constant/constant";
import UserBouncedEmailList from "../component/users/userBouncedEmailList";
import { checkPrivileges, hasAllPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";

function UserBouncedEmailContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bouncedEmailList = useSelector((state) => state.bouncedEmailList);
  const searchAllClientsData = useSelector(
    (state) => state.searchAllClientsReducerData
  );
  const autoSearchClientLoading = searchAllClientsData.isLoading;

  const getBouncedList = (data) => {
    dispatch(userAction.getBouncedList(data));
  };
  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      orgId: LOGIN_ORG_ID() !== ORG_ID() ? ORG_ID() : LOGIN_ORG_ID(),
      bounceType: "",
      fromDate: "",
      toDate: "",
    };
    getBouncedList(data);
  }, []);
  const bouncedEmail =
    checkPrivileges([12]) ||
    hasAllPrivileges(SEFETY_HBN_CLIENT_PARTNER_PRI) ||
    hasAllPrivileges(SW_HBN_CLIENT_PARTNER_PRI);

  return (
    <>
      {bouncedEmail ? (
        <UserBouncedEmailList
          loading={bouncedEmailList.isLoading}
          bouncedEmailListData={bouncedEmailList?.data}
          getBouncedList={getBouncedList}
          searchClient={searchClient}
          autoSearchDataLoading={autoSearchClientLoading}
          searchAllClientsData={searchAllClientsData}
        />
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default UserBouncedEmailContainer;
