import { Grid } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import NORow from "../../../../library/custom/noRow/noRow";
import Loader, { TableLoader } from "../../../../library/common/Loader";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import FilterCommonStatusSelect from "../../../admin/shared/FilterCommonStatusSelect";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import {
  INSSTATUS,
  SW_REPORTS_COUNT,
} from "../../../../constant/storm-water/constant";
import { getFormatedDate } from "../../../../utils/helper";
import FormControl from "@mui/material/FormControl";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import DialogBox from "../../../../library/common/DialogBox";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import * as assetsAction from "../../../../redux/actions/assetsAction";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../../../../library/custom/button/Button";
import commonImages from "../../../../assets";

const ReportList = ({
  swInspectionList,
  isLoading,
  getSwInspectionListData,
  count,
  handleEditOnClick,
  viewOnClick,
  inspectionTypeValue,
  isSearchClientLoading,
  allClients,
  searchClient,
  handleDeleteOnClick,
  onRowClick,
  statusHander,
  statusId,
  stateData,
  stodayDate,
  scalculatedDate,
  setProjects,
  projects,
  setClients,
  clients,
  selectedReports,
  setSelectedReports,
  getBulkInspectionDetailsData,
  getBulkInspectionFiles,
  getBulkInspectionFilesData,
  getProjectContactsEmailDetailsData,
  isProfile,
  handleAddOnClick,
  frequencyId,
  setFrequencyId,
  getOpenItemDetailData,
  swOpenItemDetail,
  isFromDashboard,
  isUseridPass,
}) => {
  const dispatch = useDispatch();

  const InspectionOpenItemCount = swOpenItemDetail?.data?.openItemList;

  let contentMessage;

  if (InspectionOpenItemCount === "" || InspectionOpenItemCount?.length === 0) {
    contentMessage = "Are you sure you want to delete this report?";
  } else if (InspectionOpenItemCount?.length !== 0) {
    contentMessage = `This report has ${InspectionOpenItemCount?.length} open item(s). Are you sure you want to delete this report?`;
  }

  const HBView =
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && checkPrivileges([12, 17]);
  const adminView = checkPrivileges([139]);
  const clientView = LOGIN_ORG_ID() !== ORG_ID();
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [pageSizeDownloads, setPageSizeDownloads] = useState(PAGE_SIZE);
  const [selectedREportsError, setselectedREportsError] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [downloadsPage, setDownloadsPage] = useState(1);
  const [statusIds, setStatusIds] = useState();

  const [cusid, setcusid] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  const deleteReport = (report) => {
    const customerId = report?.customerId;
    const reportId = report?.reportId;
    setcusid(customerId);
    setOpenModal(true);
    setSelectedRow(reportId);
    const data = {
      questionList: null,
      setupId: null,
      projectId: null,
      questionSetId: null,
      itemStatus: 2,
      orgId: null,
      inspectionId: [report?.reportId],
    };
    getOpenItemDetailData(data);
  };
  const deletehandle = () => {
    setOpenModal(false);
    const swinsreportdata = {
      selectedRow,
      cusid,
    };
    handleDeleteOnClick(swinsreportdata);
  };

  const allProject = useSelector((state) => state.getAssetDynomoList);
  const isProjectLoading = allProject?.isLoading;

  const [filter, setFilter] = useState({
    searchType: [],
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const autoComp = useRef(null);
  const autoCompPro = useRef(null);

  const [inspectionType, setInspectionType] = useState();
  const [asssetName, setAsssetName] = useState(false);
  const [clientName, setClientName] = useState(false);

  const inspectionTypeOnChanges = (e) => {
    setFrequencyId("");
    setInspectionType(e.target.value);
  };
  const frequencyOnChanges = (e) => {
    setFrequencyId(e.target.value);
  };
  const onchangeToDatePicker = (e) => {
    setToDate(e);
  };
  const onchangeFromDatePicker = (e) => {
    setFromDate(e);
  };
  const onChangeProjects = (value) => {
    setProjects(value);
    setAsssetName(true);
    const data = {
      page: page,
      search: search ? search : "",
      projectId: value?.key ? value?.key : 0,
      customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      statusId: statusId,
      inspectionTypeId: inspectionType ? inspectionType : 0,
      frequencyId: frequencyId ? frequencyId : "",
      startDate: fromDate === null ? "" : getFormatedDate(fromDate),
      endDate:
        statusId === 9 ? "" : toDate === null ? "" : getFormatedDate(toDate),
      pageSize: pageSize,
      userId: isProfile || isUseridPass ? parseInt(USER_ID()) : "",
    };
    getSwInspectionListData(data);
  };

  const onChangeClients = (value) => {
    setClients(value);
    setClientName(true);

    const data = {
      page: page,
      search: search ? search : "",
      projectId: 0,
      customerId: value?.key ? value?.key : parseInt(ORG_ID()),
      statusId: statusId,
      inspectionTypeId: inspectionType ? inspectionType : 0,
      frequencyId: frequencyId ? frequencyId : "",
      startDate: fromDate === null ? "" : getFormatedDate(fromDate),
      endDate:
        statusId === 9 ? "" : toDate === null ? "" : getFormatedDate(toDate),
      pageSize: pageSize,
      userId: isProfile || isUseridPass ? parseInt(USER_ID()) : "",
    };
    getSwInspectionListData(data);
    setProjects();

    const ele = autoCompPro.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) {
      ele.click();
    }
  };

  function handleAllProjectSearch(event, value) {
    dispatch(
      assetsAction.getAssetDynomoList({
        orgId: clients?.key ? clients?.key : parseInt(ORG_ID()),
        searchText: event.target.value,
        type: 5,
      })
    );
  }

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      search: search ? search : "",
      projectId: projects?.key ? projects?.key : 0,
      customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      statusId: statusId,
      inspectionTypeId: inspectionType ? inspectionType : 0,
      frequencyId: frequencyId ? frequencyId : "",
      startDate: fromDate === null ? "" : getFormatedDate(fromDate),
      endDate:
        statusId === 9 ? "" : toDate === null ? "" : getFormatedDate(toDate),
      pageSize: pageSize,
      userId: isProfile || isUseridPass ? parseInt(USER_ID()) : "",
    };
    getSwInspectionListData(data);
  };
  const handleDownloadsPagignation = (newPage) => {
    setDownloadsPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: pageSizeDownloads,
      userId: isProfile || isUseridPass ? parseInt(USER_ID()) : "",
    };
    getBulkInspectionFilesData(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      search: event.target.value,
      inspectionTypeId: inspectionType ? inspectionType : 0,
      frequencyId: frequencyId ? frequencyId : "",
      projectId: projects?.key ? projects?.key : 0,
      customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      statusId: statusId,
      startDate: fromDate === null ? "" : getFormatedDate(fromDate),
      endDate:
        statusId === 9 ? "" : toDate === null ? "" : getFormatedDate(toDate),
      page: page,
      pageSize: pageSize,
      userId: isProfile || isUseridPass ? parseInt(USER_ID()) : "",
    };
    getSwInspectionListData(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      search: search ? search : "",
      inspectionTypeId: inspectionType ? inspectionType : 0,
      frequencyId: frequencyId ? frequencyId : "",
      projectId: projects?.key ? projects?.key : 0,
      customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      statusId: statusId,
      startDate: fromDate === null ? "" : getFormatedDate(fromDate),
      endDate:
        statusId === 9 ? "" : toDate === null ? "" : getFormatedDate(toDate),
      page: page,
      pageSize: newPazeSize,
      userId: isProfile || isUseridPass ? parseInt(USER_ID()) : "",
    };
    getSwInspectionListData(data);
  };
  const handlePageSizeDownloadsChange = (newPazeSize) => {
    setPageSizeDownloads(newPazeSize);
    const data = {
      page: downloadsPage,
      pageSize: newPazeSize,
      userId: isProfile || isUseridPass ? parseInt(USER_ID()) : "",
    };
    getBulkInspectionFilesData(data);
  };

  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
        setStatusIds(item?.key);
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };

  const handleApply = () => {
    const data = {
      search: search ? search : "",
      projectId: projects?.key ? projects?.key : 0,
      customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      statusId: statusId,
      inspectionTypeId: inspectionType ? inspectionType : 0,
      frequencyId: frequencyId ? frequencyId : "",
      startDate: fromDate === null ? "" : getFormatedDate(fromDate),
      endDate:
        statusId === 9 ? "" : toDate === null ? "" : getFormatedDate(toDate),
      page: page,
      pageSize: pageSize,
      userId: isProfile || isUseridPass ? parseInt(USER_ID()) : "",
    };
    getSwInspectionListData(data);
    handleClose();
  };

  const handleClear = () => {
    setProjects();
    setClients();
    setInspectionType("");
    setStatusIds("");
    setFromDate(null);
    setToDate(null);
    setSearch("");
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      prevStates.searchType = [];
      return prevStates;
    });
    const dataClear = {
      search: "",
      inspectionTypeId: 0,
      projectId: stateData ? stateData?.projectId : 0,
      customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      statusId: statusId,
      startDate: statusId === 9 ? getFormatedDate(stodayDate) : "",
      endDate: statusId === 9 ? getFormatedDate(scalculatedDate) : "",
      page: 1,
      pageSize: pageSize,
      userId: isProfile || isUseridPass ? parseInt(USER_ID()) : "",
    };
    getSwInspectionListData(dataClear);
    handleClose();
  };

  const sendEmailOnClick = (row) => {
    const data = {
      orgId: row?.customerId,
      inspectionId: row?.reportId,
      fromDate: "",
      toDate: "",
      projectIds: [0],
    };
    getProjectContactsEmailDetailsData(data);
  };

  const columns = [
    {
      field: "reportId",
      headerName: "Report ID",
      flex: 1,
    },
    {
      field: "projectNumber",
      headerName: "Project Number",
      flex: 1,
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: 1,
    },
    {
      field: "customer",
      headerName: "Client",
      flex: 1,
    },
    {
      field: "dateScheduled",
      headerName: statusId === 5 ? "Date Submitted" : "Date Scheduled",
      flex: 1,
      hide: false,
    },

    {
      field: "openItemCount",
      headerName: "Open Items",
      flex: 1,
      hide: false,
    },
    {
      field: "inspectionBy",
      headerName: "Inspector",
      flex: 1,
      hide: false,
    },
    {
      field: "inspectionType",
      headerName: "Inspection Type",
      flex: 1,
    },

    {
      field: "frequency",
      headerName: "Frequency",
      flex: 1.5,
      renderCell: (rows) => {
        return (
          <>
            <div>
              <>
                {rows?.row?.frequency}{" "}
                {rows?.row?.frequencyId === 5 &&
                rows?.row?.isFluid &&
                rows?.row?.insTerm
                  ? `(${rows?.row?.insTerm})`
                  : ""}
                {rows?.row?.frequencyId === 7 ? (
                  <span
                    className="frequencyInDays"
                    title={rows?.row?.frequencyInDays}
                  >
                    {"- " + rows?.row?.frequencyInDays}
                  </span>
                ) : (
                  ""
                )}
              </>
            </div>
          </>
        );
      },
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={count ?? 0}
          numberOfItemsPerPage={10}
          incidentLists={swInspectionList ?? []}
          fileNameXL={"inspections"}
        />
      ),
      //  flex: 0.7,
      width: 75,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        const currentDate = new Date(); // Get the current date
        const scheduledDate = new Date(rows?.row?.dateScheduled); // Convert scheduled date to Date object
        const isTodayOrUpcoming = scheduledDate <= currentDate; // Check if scheduled date is today or in the future
        return statusId === 5 ? (
          <ReportRowManipulte
            rows={rows}
            edit={checkPrivileges([12, 102, 103])}
            view={checkPrivileges([12, 102, 103, 104])}
            editOnClick={(row) => handleEditOnClick(row)}
            viewOnClick={(row) => viewOnClick(row)}
            editLabel="Edit Report"
            viewLabel={
              rows?.row?.isExternal ? "View Submission Proof" : "View Report"
            }
            deleted={checkPrivileges([12, 102])}
            deleteOnClick={(row) => deleteReport(row)}
            deleteLabel="Delete Report"
            sendEmail={checkPrivileges([12, 102, 103, 104])}
            sendEmailOnClick={(row) => sendEmailOnClick(row)}
            sendEmailLable="Send Mail to Contacts"
          />
        ) : statusId === 9 && rows?.row?.isFluid && isTodayOrUpcoming ? (
          <ReportRowManipulte
            rows={rows}
            addBtn={checkPrivileges([12, 102, 103, 104])}
            addBtnLabel={"Add Report"}
            addForm={() => handleAddOnClick(rows?.row)}
          />
        ) : null;
      },
    },
  ];
  const columnsDownloads = [
    {
      field: "requestId",
      headerName: "Request ID",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
    },
    {
      field: "clientName",
      headerName: "Client",
      flex: 1,
    },
    {
      field: "requestedByName",
      headerName: "Requested By ",
      flex: 1,
    },
    {
      field: "fileUrl",
      headerName: "Link",
      flex: 1.2,
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            {/* {rows?.row?.fileUrl?.slice(0, 24)}...{" "} */}
            Download file{" "}
            {rows?.row?.status === "Active" && (
              <a
                rel="noopener noreferrer"
                href={rows?.row?.fileUrl}
                target="_blank"
              >
                <img src={commonImages.download} alt="" />
              </a>
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      field: "",
      headerName: "",
      flex: 0.01,
      renderCell: (rows) => (
        <Grid container className="catListBox">
          {/* <Grid container justifyContent={"left"} className="closeIcon">
            
          </Grid> */}
        </Grid>
      ),
    },
  ];

  if (statusId === 10) {
    columns.find((column) => column.field === "openItemCount").hide = true;
  }
  if (statusId === 9) {
    columns.find((column) => column.field === "openItemCount").hide = true;
  }
  if (statusId === 5) {
    columns.find((column) => column.field === "openItemCount").hide = false;
    // columns.find((column) => column.field === "inspectionBy").hide = true; #US 2539
  }

  if (statusId === 5 && isProfile) {
    columns.find((column) => column.field === "openItemCount").hide = true;
    columns.find((column) => column.field === "inspectionBy").hide = false;
  }

  useEffect(() => {
    if (statusId) {
      setFromDate(null);
      setToDate(null);
    }
    if (stateData && !isFromDashboard) {
      setProjects({
        key: stateData?.projectId ?? "",
        value: stateData?.projectName ?? "",
      });

      setClients({
        key: stateData?.customerId ?? "",
        value: stateData?.customerName ?? "",
      });
      const data = {
        page: page,
        search: search ? search : "",
        projectId: stateData?.projectId ? stateData?.projectId : 0,
        customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
        statusId: statusId,
        inspectionTypeId: inspectionType ? inspectionType : 0,
        frequencyId: frequencyId ? frequencyId : "",
        startDate: fromDate === null ? "" : getFormatedDate(fromDate),
        endDate:
          statusId === 9 ? "" : toDate === null ? "" : getFormatedDate(toDate),
        pageSize: pageSize,
        userId: isProfile || isUseridPass ? parseInt(USER_ID()) : "",
      };
      getSwInspectionListData(data);
    }
  }, [statusId, stateData]);

  useEffect(() => {
    if (!stateData) {
      setAsssetName(true);
      setClientName(true);
    }
  }, [stateData]);
  useEffect(() => {
    if (statusId === 9 && stodayDate) {
      setFromDate(stodayDate);
      setToDate(scalculatedDate);
    }
  }, [statusId, stodayDate]);
  console.log("clients?.key", clients, clients?.key);
  return (
    <>
      {swOpenItemDetail.isLoading && <Loader />}
      <section className="grid_main_sec">
        {openModal && (
          <DialogBox
            open={openModal}
            rows={[]}
            header={"Confirm Deletion"}
            deletehandle={deletehandle}
            handleCloseModal={() => setOpenModal(false)}
            content={contentMessage}
            view
          />
        )}
        {!isProfile && (
          <div className="grid_main_header two_grid">
            <Grid container item md={12} xs={12} className="">
              <Grid container className="confBox">
                <Grid item md={8} xs={12}>
                  <div className="confTitile">
                    {HBView || adminView
                      ? "Inspection Record"
                      : "My Inspection Record"}
                  </div>
                </Grid>

                <Grid item md={8} xs={12}>
                  <div className="btnStatusWrapper">
                    <div
                      className={
                        statusId === 10 ? "btnStatusIns active" : "btnStatusIns"
                      }
                      onClick={() => {
                        setPageSize(PAGE_SIZE);
                        setDownloadsPage(PAGE_SIZE);
                        statusHander(10);
                      }}
                    >
                      Missed
                    </div>
                    <div
                      className={
                        statusId === 5 ? "btnStatusIns active" : "btnStatusIns"
                      }
                      onClick={() => {
                        setPageSize(PAGE_SIZE);
                        setDownloadsPage(PAGE_SIZE);
                        statusHander(5);
                      }}
                    >
                      Completed
                    </div>
                    <div
                      className={
                        statusId === 9 ? "btnStatusIns active" : "btnStatusIns"
                      }
                      onClick={() => {
                        setPageSize(PAGE_SIZE);
                        setDownloadsPage(PAGE_SIZE);
                        statusHander(9);
                      }}
                    >
                      Scheduled
                    </div>
                    <div
                      className={
                        statusId === 8 ? "btnStatusIns active" : "btnStatusIns"
                      }
                      onClick={() => {
                        setPageSize(PAGE_SIZE);
                        statusHander(8);
                      }}
                    >
                      Downloads
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}

        {statusId !== 8 && (
          <Grid container mb={2}>
            <Grid lg={8} md={12} xs={12} pr={2} item>
              <Grid container spacing={2}>
                {HBView && !isProfile && (
                  <Grid lg={6} xs={12} sm={12} md={6} item>
                    <FormControl fullWidth>
                      <FormLabels label="Client" isRequired={false} />
                      <SearchAutoComplete
                        name="Add Individual Users"
                        optionsData={allClients ?? []}
                        typeOnchange={(event, value) => {
                          searchClient(event, value);
                        }}
                        isKeyValuePair={true}
                        value={clients}
                        onChange={(e, value) => onChangeClients(value)}
                        isLoading={isSearchClientLoading}
                        autoCompClear={autoComp}
                        noOptionsTextMsg={"No Client"}
                      />
                    </FormControl>
                  </Grid>
                )}

                {(HBView || adminView) && !isProfile && (
                  <Grid lg={6} xs={12} sm={12} md={6} item>
                    <FormControl fullWidth>
                      <FormLabels label="Project" isRequired={false} />
                      <SearchAutoComplete
                        name="Add Individual Users"
                        optionsData={allProject?.data ?? []}
                        typeOnchange={(event, value) =>
                          handleAllProjectSearch(event, value)
                        }
                        isKeyValuePair={true}
                        value={projects}
                        onChange={(e, value) => onChangeProjects(value)}
                        isLoading={isProjectLoading}
                        autoCompClear={autoCompPro}
                        noOptionsTextMsg={"No Project"}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        <div className="grid_main_body">
          {statusId !== 8 && (
            <Grid container>
              <Grid item lg={9}>
                <div className="filter_of_caselist">
                  <div className="searchcaseid">
                    <label htmlFor="search">
                      <span>
                        <SearchIcon />
                      </span>
                      <div>
                        <input
                          id="search"
                          placeholder="Search"
                          type="text"
                          value={search}
                          className=""
                          onChange={handleautoSearch}
                          autoComplete="off"
                        />
                      </div>
                    </label>
                  </div>
                  <div className="filter_btn">
                    <button
                      aria-describedby={id}
                      variant="contained"
                      type="button"
                      name="btn"
                      onClick={(e) => handleClick(e)}
                    >
                      <FilterListIcon id="icn" /> Filters
                    </button>

                    <FilterCommonStatusSelect
                      open={open}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      statusType={INSSTATUS}
                      handleApply={handleApply}
                      handleClear={handleClear}
                      filter={filter}
                      onChangeType={onChangeType}
                      statusIds={statusIds}
                      selectLable={"Inspection Type"}
                      categoryType={inspectionTypeValue?.insTypeMstList}
                      category={inspectionType}
                      categoryOnChanges={inspectionTypeOnChanges}
                      filterSelect={true}
                      filterStatus={false}
                      resetBtn={true}
                      filterDate={true}
                      toDate={toDate}
                      fromDate={fromDate}
                      onchangeToDatePicker={onchangeToDatePicker}
                      onchangeFromDatePicker={onchangeFromDatePicker}
                      scalculatedDate={scalculatedDate}
                      statusId={statusId}
                      frequencySelect={true}
                      frequencyLable={"Frequency"}
                      frequencyType={inspectionTypeValue?.insfrequencyList}
                      frequencyId={frequencyId}
                      frequencyOnChanges={frequencyOnChanges}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item lg={3}>
                {selectedReports.length > 0 && (
                  <div>
                    <Buttons
                      onClick={() => {
                        if (selectedReports?.length > SW_REPORTS_COUNT)
                          setselectedREportsError(true);
                        else
                          getBulkInspectionDetailsData(
                            selectedReports,
                            clients?.key
                          );
                      }}
                      varientContained={true}
                      label={"Send Report"}
                    />
                  </div>
                )}
                <Grid item container className="buttonsGrid">
                  {selectedREportsError && (
                    <div className="errorMsg">
                      Please select up to {SW_REPORTS_COUNT} records
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              {statusId === 8 ? (
                <GridTable
                  getRowId={(r) => r.requestId}
                  rows={
                    getBulkInspectionFiles?.isLoading
                      ? []
                      : getBulkInspectionFiles?.data?.result ?? []
                  }
                  rowCount={getBulkInspectionFiles?.data?.itemsCount ?? 0}
                  columns={columnsDownloads}
                  checkboxSelection={false}
                  loading={getBulkInspectionFiles?.isLoading}
                  page={downloadsPage}
                  pagination
                  pageSize={pageSizeDownloads}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeDownloadsChange}
                  onPageChange={handleDownloadsPagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick={true}
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              ) : (
                <GridTable
                  getRowId={(r) => r.reportId}
                  rows={swInspectionList ?? []}
                  rowCount={count ?? 0}
                  columns={columns}
                  checkboxSelection={
                    (statusId === 5 && clients?.key && !isProfile) ||
                    (clientView && statusId === 5 && !isProfile) ||
                    (!HBView && statusId === 5 && !isProfile)
                  }
                  loading={isLoading}
                  page={1}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick={true}
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                  onSelectionModelChange={(e) => {
                    setselectedREportsError(false);
                    setSelectedReports(e);
                  }}
                  isRowSelectable={(rows) =>
                    selectedReports.length < SW_REPORTS_COUNT ||
                    selectedReports.indexOf(rows.row.reportId) >= 0
                  }
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReportList;
