import React, { useState } from "react";
import "../../../incidents/cases/addCaseList.scss";
import { TableLoader } from "../../../../library/common/Loader";
import { PAGE_SIZE } from "../../../../constant/constant";
import {
  TOOL_BOX_ATTENDEES,
  TOOL_BOX_DETAIL,
  TOOL_BOX_LIST,
} from "../../../../constant/routeContant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import NORow from "../../../../library/custom/noRow/noRow";
import Button from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import NormalGrid from "../../../../library/custom/gridTable/NormalGrid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import TextEditor from "../../../../library/custom/textEditor/TextEditor";
import TableRowAction from "../../../../library/custom/gridTable/TableRowAction";
import { useNavigate } from "react-router-dom";
export default function ToolBoxAssignUserStatus({
  fieldid,
  onSubmit,
  getScheduleAssignUserStatusData,
  isLoading,
  flag,
  titleSafetyMeeting,
  Tabvalue,
  scheduleId,
  coursesUserAssignListData,
  filterBy,
  toolboxTalkId,
  toolboxTalkVersionId,
}) {
  let titleUserStatus;
  if (flag === "0") {
    titleUserStatus = "ASSIGN USERS" + " " + titleSafetyMeeting;
  } else if (flag === "1") {
    titleUserStatus = "COMPLETED USERS" + " " + titleSafetyMeeting;
  } else {
    titleUserStatus = "NOT COMPLETED USERS" + " " + titleSafetyMeeting;
  }
  const navigate = useNavigate();

  const [selectedUserId, setselectedUserId] = useState([]);
  const [customMessage, setCustomMessage] = useState(
    "This is a reminder that you have a Safety Meeting which is incomplete."
  );
  const [open, setOpen] = React.useState(false);
  const [editInsName, setInsName] = useState(false);

  const dataBack = {
    title: "Back to Safety Meetings",
    route: TOOL_BOX_LIST,
    backTabId: Tabvalue,
  };

  const handleAlertMail = () => {
    setOpen(true);
    setInsName(true);
    setselectedUserId([]);

    const data = {
      userIds: selectedUserId,
      scheduleId: scheduleId,
      dynamicText: customMessage,
    };

    onSubmit(data);
    setOpen(false);
    setCustomMessage(
      "This is a reminder that you have a Safety Meeting which is incomplete."
    );
  };

  const handleclickSafetyAlertAll = (userId) => {
    setOpen(true);
    setInsName(true);
    setselectedUserId([]);
  };
  const handleAlertAll = (userId) => {
    const UsersIds = getScheduleAssignUserStatusData?.map((val) => {
      return val?.userId;
    });
    const data = {
      userIds: UsersIds,
      scheduleId: scheduleId,
      dynamicText: customMessage,
    };

    onSubmit(data);
    setOpen(false);
    setCustomMessage(
      "This is a reminder that you have a Safety Meeting which is incomplete."
    );
  };

  const handleclickSafetyAlert = (userId) => {
    setOpen(true);
    setInsName(true);
    const useridArr = [];
    useridArr.push(userId);
    setselectedUserId(useridArr);
  };

  const columns_completed = [
    { field: "userId", headerName: "User ID", width: 100, flex: 1 },

    { field: "userName", headerName: "User Name", flex: 1 },
    {
      field: "jobTitle",
      headerName: "Job Title",
      type: "text",
      flex: 1,
    },
    {
      field: "dateCompleted",
      headerName: "Date Completed",
      type: "text",
      flex: 1,
    },
    {
      field: "dateScheduled",
      headerName: "Date Scheduled",
      type: "text",
      flex: 1,
    },
    {
      field: "icon",
      renderHeader: () =>
        filterBy === 2 || filterBy === 4 ? (
          <SettingTable
            noOfRecords={getScheduleAssignUserStatusData ?? 0}
            numberOfItemsPerPage={10}
            incidentLists={getScheduleAssignUserStatusData ?? []}
            fileNameXL={"Complete USers"}
          />
        ) : (
          ""
        ),
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <Grid mt={filterBy === 2 || filterBy === 4 ? -4 : 0}>
            {filterBy === 2 || filterBy === 4 ? (
              <TableRowAction
                rows={rows}
                usingComp={"Meeting"}
                // VerifyAttendees
                view
                viewLabel={"View Attendees"}
                viewOnClick={(row) =>
                  navigate(TOOL_BOX_DETAIL, {
                    state: {
                      scheduleId: row.scheduleId,
                      instructorUserId: row.instructorUserId,
                      toolboxTalkId: toolboxTalkId,
                      toolboxTalkVersionId: toolboxTalkVersionId,
                      fromCompletedTab: true,
                      safetyDetail: {},
                      fromMyTraining: true,
                      showDownloadBtn:true,
                      userName:row.userName
                    },
                  })
                }
              />
            ) : filterBy === 3 ? (
              <div className="alertbtn">
                <Button
                  primaryalert={true}
                  onClick={(row) => handleclickSafetyAlert(rows?.id)}
                  label="Alert"
                />
              </div>
            ) : (
              ""
            )}
          </Grid>
        );
      },
    },
  ];
  const columns_assign = [
    { field: "userId", headerName: "User ID", width: 100, flex: 1 },

    { field: "userName", headerName: "User Name", flex: 1 },
    {
      field: "jobTitle",
      headerName: "Job Title",
      type: "text",
      flex: 1,
    },
    // {
    //   field: "dateCompleted",
    //   headerName: "Date Completed",
    //   type: "text",
    //   flex: 1,
    // },
    {
      field: "dateScheduled",
      headerName: "Scheduled Date",
      type: "text",
      flex: 1,
    },
    {
      field: "icon",
      renderHeader: () =>
        filterBy === 2 || filterBy === 4 ? (
          <SettingTable
            noOfRecords={getScheduleAssignUserStatusData ?? 0}
            numberOfItemsPerPage={10}
            incidentLists={getScheduleAssignUserStatusData ?? []}
            fileNameXL={"Complete USers"}
          />
        ) : (
          ""
        ),
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <Grid mt={filterBy === 2 ? -4 : 0}>
            {filterBy === 2 ? (
              <TableRowAction
                rows={rows}
                usingComp={"Meeting"}
                // VerifyAttendees
                view
                viewLabel={"View Attendees"}
                viewOnClick={(row) =>
                  navigate(TOOL_BOX_ATTENDEES, {
                    state: {
                      scheduleId: row.scheduleId,
                      instructorUserId: row.instructorUserId,
                    },
                  })
                }
              />
            ) : filterBy === 3 ? (
              <div className="alertbtn">
                <Button
                  primaryalert={true}
                  onClick={(row) => handleclickSafetyAlert(rows?.id)}
                  label="Alert"
                />
              </div>
            ) : (
              ""
            )}
          </Grid>
        );
      },
    },
  ];

  const handleClose = () => {
    setCustomMessage(
      "This is a reminder that you have training that is incomplete."
    );
    setOpen(false);
    setselectedUserId([]);
  };
  const onchangeCustomMessage = (e) => {
    setCustomMessage(e);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid className="modalContainer">
          {editInsName && (
            <Box className="modalBox">
              <div className="modalTitle">Send Custom Message</div>
              {/* <div className="modalInputBox"> */}
              <Grid container mt={2} lg={12} className="lesson-alert-model">
                <TextEditor
                  value={customMessage}
                  placeholderText={"Enter Here..."}
                  onChange={(e) => {
                    onchangeCustomMessage(e);
                  }}
                />
              </Grid>

              {/* </div> */}
              <Grid container className="modalFooterBox" mt={2}>
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Button
                    varientText={true}
                    label="Cancel"
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item lg={3} xs={12} sm={3} md={3}>
                  <Button
                    redButton={true}
                    label={"Send Alert"}
                    onClick={
                      selectedUserId.length > 0
                        ? handleAlertMail
                        : handleAlertAll
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Modal>
      <BackBtn dataBack={dataBack} />
      <div className="assignDivtitle">
        <div className="assigntitle">
          <div className="lessonnme">
            {filterBy === 1
              ? "Assigned Users"
              : filterBy === 2 || filterBy === 4
              ? "Completed Users"
              : "Not Completed Users"}
          </div>
          <div
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "29px",
              color: "#333333",
              marginBottom: "1rem",
            }}
          >
            {titleSafetyMeeting}
          </div>
        </div>
        {filterBy === 3 && (
          <div className="allertall">
            {getScheduleAssignUserStatusData?.length > 1 && (
              <Button
                primaryalertAll={true}
                onClick={(row) => handleclickSafetyAlertAll()}
                label="Alert All"
              />
            )}
          </div>
        )}
      </div>

      <div className="tableData">
        <NormalGrid
          loading={isLoading}
          getRowId={(r) => r?.userId}
          rowss={
            getScheduleAssignUserStatusData
              ? getScheduleAssignUserStatusData
              : []
          }
          columnss={
            filterBy === 2 || filterBy === 4
              ? columns_completed
              : columns_assign
          }
          rowCount={getScheduleAssignUserStatusData?.length}
          components={{
            LoadingOverlay: TableLoader,
            NoRowsOverlay: NORow,
          }}
          checkboxSelection={false}
          onSelectionModelChange={() => {}}
        ></NormalGrid>
      </div>
    </>
  );
}
