import React, { useState, useEffect, useRef } from "react";
import { Grid, RadioGroup } from "@mui/material";
import "./editResource.scss";
import Loader from "../../../../library/common/Loader";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import Buttons from "../../../../library/custom/button/Button";
import UsersChip from "../../../../library/custom/usersChip/UsersChip";
import { ORG_ID, USER_ID } from "../../../../constant/constant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { ADMIN_RESOURCE } from "../../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import SearchAutoComplete2 from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import FormDropZone from "../../../../library/dropZone/FormDropZone";
import {
  getCurrentDate,
  getFormatedDate,
  parseDate,
} from "../../../../utils/helper";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import Checkbox from "../../../../library/checkbox/checkbox";
import commonImages from "../../../../assets";

const EditResource = ({
  allUsers,
  isLoading,
  isResViewLoading,
  viewResourceData,
  handleAllUserSearch,
  onSubmit,
  allGroupsUser,
  handleAllGroupSearch,
  isUserGropLoading,
  searchAsset,
  assetOption,
  clients,
}) => {
  const dataBack = {
    title: "Back to Resource",
    route: ADMIN_RESOURCE,
  };
  const [isNewVersion, setIsNewVersion] = useState(false);
  const navigate = useNavigate();
  const [serviceType, setServiceType] = useState([]);

  const intialState = {
    resourcesId: 0,
    title: "",
    resourceType: 1,
    categoryId: "",
    fileUrls: "",
    linkUrls: "",
    individualUserIds: [],
    createdBy: USER_ID(),
    organisationId: ORG_ID(),
    userType: 1,
    isPublic: false,
    resourceAssigneeTypeId: 0, // 0 =  user, 1 = asset
    assetTypeId: PRODUCT_ID == 2 ? 5 : 1, // 1 = project 5 , swproject 2 = vehicle , 3 = sequipment 4 = custom
    assetsIds: [],
  };
  const [isSubmit, setIsSubmit] = useState(false);
  const [formValue, setFormValue] = useState(intialState);

  const dateOfExpireSet = new Date(viewResourceData?.expirationDate);

  const [individualUsersIds, setIndividualUsersIds] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [users, setUsers] = useState("");
  const [isindividualUsersIds, setIsindividualUsersIds] = useState(false);
  const [isGroupIds, setIsGroupIds] = useState(false);
  const [isassignAssets, setIsassignAssets] = useState(false);
  const [expirationDate, setExpirationDate] = useState();
  const [checkBox, setcheckBox] = useState(false);

  // code start
  const [individualAssetsIds, setndividualAssetsIds] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [assets, setAssets] = useState("");
  const autoCompAsset = useRef(null);

  const onchangeExpirationdatePicker = (e) => {
    setExpirationDate(e);
  };

  const addIndiVidualUserData = () => {
    const index = userList?.findIndex((obect) => obect?.key === users?.key);
    if (index === -1 && users) {
      setUsers("");
      setUsersList([...userList, users]);
      setIndividualUsersIds([...individualUsersIds, users.key]);
    }
  };

  const deleteUsers = (row) => {
    const updateList = userList.filter((list) => list.key !== row);
    setUsersList(updateList);
    setIndividualUsersIds(updateList?.map((ids) => ids.key));
  };

  const onChangeUsers = (value) => {
    setIsindividualUsersIds(false);
    setUsers(value);
  };

  // User Group List
  const [groupUsersIds, setGroupUsersIds] = useState([]);
  const [userGroupList, setUsersGroupList] = useState([]);
  const [usersGroup, setUsersGroup] = useState("");

  const addGroupUserData = () => {
    const index = userGroupList?.findIndex(
      (obect) => obect.key === usersGroup.key
    );
    if (index === -1 && usersGroup) {
      setUsersGroup("");
      setUsersGroupList([...userGroupList, usersGroup]);
      setGroupUsersIds([...groupUsersIds, usersGroup.key]);
    }
  };

  const deleteUsersGroup = (row) => {
    const updateList = userGroupList.filter((list) => list.key !== row);
    setUsersGroupList(updateList);
    setGroupUsersIds(updateList?.map((ids) => ids.key));
  };

  const onChangeUsersGroup = (value) => {
    setIsGroupIds(false);
    setUsersGroup(value);
  };

  const handleFileChange = (urls) => {
    setFormValue({
      ...formValue,
      fileUrls: urls,
    });
  };
  const handleDeleteImageURL = (url) => {
    setFormValue({
      ...formValue,
      fileUrls: "",
    });
  };

  const addVersion = () => {
    setIsNewVersion(true);
  };
  const [dropboxError, setDropboxError] = useState(false);

  function mappedData() {
    let errors = false;

    if (formValue?.fileUrls == "" && formValue.resourceType === 1) {
      setDropboxError(true);
      errors = true;
    }
    if (
      individualUsersIds?.length === 0 &&
      formValue?.resourceAssigneeTypeId === 0 &&
      formValue?.userType === 1
    ) {
      errors = true;
      setIsindividualUsersIds(true);
    }
    if (
      groupUsersIds?.length === 0 &&
      formValue?.resourceAssigneeTypeId === 0 &&
      formValue?.userType === 2
    ) {
      errors = true;
      setIsGroupIds(true);
    }
    if (
      individualAssetsIds?.length === 0 &&
      formValue?.resourceAssigneeTypeId === 1
    ) {
      errors = true;
      setIsassignAssets(true);
    }
    if (errors) {
      return false;
    }
    const data = {
      title: viewResourceData?.resourceTitle,
      resourceType: viewResourceData?.resourceTypeId,
      userType:
        formValue?.resourceAssigneeTypeId == 1 ? null : formValue?.userType,

      categoryId: viewResourceData?.categoryId,
      expirationDate: expirationDate && getFormatedDate(expirationDate),
      fileUrls: formValue?.fileUrls,
      linkUrls: formValue?.linkUrls,
      individualUserIds: individualUsersIds,
      userGroupIds: groupUsersIds,
      isPublic: formValue?.isPublic ? formValue?.isPublic : false,
      resourceId: viewResourceData?.resourceId,
      modifiedBy: parseInt(USER_ID),
      resourceAssigneeTypeId: formValue?.resourceAssigneeTypeId,
      assetTypeId:
        formValue?.resourceAssigneeTypeId > 0
          ? PRODUCT_ID() == 2
            ? 5
            : formValue?.assetTypeId
          : 0,
      assetsIds: individualAssetsIds,
      productIds: serviceType,
      client: clients ? clients : parseInt(ORG_ID()),
      sendNotification: checkBox ? true : false,
    };
    return data;
  }

  const submitData = () => {
    const data = mappedData();

    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  useEffect(() => {
    if (viewResourceData) {
      setFormValue({
        title: viewResourceData?.resourceTitle,
        categoryId: viewResourceData?.categoryId,
        userType: viewResourceData?.userType,
        resourceType: viewResourceData?.resourceTypeId,
        expirationDate: viewResourceData?.expirationDate,
        linkUrls: viewResourceData?.linkUrls[0],
        fileUrls: viewResourceData?.fileUrls
          ? viewResourceData?.fileUrls[0]
          : "",
        resourceAssigneeTypeId: viewResourceData?.resourceAssigneeType,
        assetTypeId: viewResourceData?.assetTypeId,
      });
      setIndividualUsersIds(
        viewResourceData?.individualUserIds?.map((data) => data.key)
      );
      setUsersList(viewResourceData?.individualUserIds);

      setGroupUsersIds(viewResourceData?.userGroupIds?.map((data) => data.key));
      setUsersGroupList(viewResourceData?.userGroupIds);

      setndividualAssetsIds(
        viewResourceData?.assetIds?.map((data) => data?.key)
      );
      setExpirationDate(
        parseDate(viewResourceData?.expirationDate, "MM-dd-yyyy")
      );
      setcheckBox(viewResourceData?.sendNotification);
      setAssetsList(viewResourceData?.assetIds);
      setServiceType(viewResourceData?.productIds?.map((item) => item.key));
    }
  }, [viewResourceData]);

  const onChangeAssets = (e, value) => {
    setIsassignAssets(false);
    setAssets(value);
  };

  const blankstate = () => {
    setndividualAssetsIds([]);
    setAssetsList([]);

    setIndividualUsersIds([]);
    setUsersList([]);

    setGroupUsersIds([]);
    setUsersGroupList([]);

    setAssets("");
  };
  useEffect(() => {
    const ele = autoCompAsset?.current?.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  }, [formValue?.assetTypeId]);
  const onChnageResourseassignee = (e, val) => {
    setFormValue({
      ...formValue,
      resourceAssigneeTypeId: parseInt(e?.target?.value),
      isPublic: false,
    });
    blankstate();
  };
  const onChangeAssetTypeId = (e, val) => {
    setFormValue({
      ...formValue,
      assetTypeId: parseInt(e?.target?.value),
      isPublic: false,
    });
    blankstate();
  };
  const onChangeUserType = (e, val) => {
    setFormValue({
      ...formValue,
      userType: parseInt(e.target.value),
      isPublic: false,
    });
    blankstate();
  };
  return (
    <>
      {isResViewLoading && <Loader />}
      <div className="viewOverideContact">
        <Grid container>
          <Grid item xs={12} md={12}>
            <div className="editResourceTitle">Edit Resource</div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="titleGuid">{viewResourceData?.resourceTitle}</div>
            <div className="viewBoxWrap">
              {viewResourceData?.resourceAssigneeType == 1 &&
                PRODUCT_ID() == 2 && (
                  <div className="viewBox">
                    <div className="viewBoxItem">Project Name : </div>

                    <span className="font-w4001">
                      {viewResourceData?.assetIds?.map((val, index) => {
                        return (
                          <p className="viewBoxItem1" key={index}>
                            {(index ? "," : " ") + " " + val?.value}
                          </p>
                        );
                      })}
                    </span>
                  </div>
                )}

              <div className="viewBox">
                <div className="viewBoxItem">Entered by :</div>
                <div className="viewBoxItem">{viewResourceData?.addedBy}</div>
              </div>

              <div className="viewBox">
                <div className="viewBoxItem">Resource Type : </div>
                <div className="viewBoxItem">Document</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="titlePre">View Previous Versions:</div>
            <div className="viewBoxWrap">
              {viewResourceData?.resourceVersions?.map((version, i) => {
                const dateOfVersion = new Date(version?.dateOfEntry);
                return (
                  <div className="viewBox" key={version?.addedById}>
                    <div className="viewBoxItem">
                      Version {viewResourceData?.resourceVersions?.length - i} :{" "}
                    </div>
                    <div className="viewBoxItem version">
                      Date of Entry :{" "}
                      {`${
                        dateOfVersion.getMonth() + 1
                      }/${dateOfVersion.getDate()}/${dateOfVersion.getFullYear()}`}
                    </div>
                    <div className="viewBoxItem downloadImg">
                      <a href={version?.fileUrls} target="_blank">
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.53428 13C1.11501 13 0.758794 12.854 0.46564 12.562C0.171815 12.2707 0.0249023 11.9167 0.0249023 11.5V10H1.53428V11.5H10.5905V10H12.0999V11.5C12.0999 11.9167 11.953 12.2707 11.6592 12.562C11.366 12.854 11.0098 13 10.5905 13H1.53428ZM6.0624 10L2.0374 6L3.10604 4.938L5.30771 7.125V0H6.81709V7.125L9.01876 4.938L10.0874 6L6.0624 10Z"
                            fill="#1B436E"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>

        <Grid container mt={2}>
          <Grid container mt={1}>
            <Grid container>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <FormLabels label="Assign Resource To" />
                <RadioGroup row name="radio-user-type" className="radioGroup">
                  <RadioButton
                    checked={formValue.resourceAssigneeTypeId === 0}
                    value={0}
                    label="Users"
                    onChange={(e, value) => onChnageResourseassignee(e, value)}
                    disabled={
                      formValue?.resourceAssigneeTypeId == 1 ? true : false
                    }
                  ></RadioButton>
                  <RadioButton
                    checked={formValue.resourceAssigneeTypeId === 1}
                    value={1}
                    label="Assets"
                    onChange={(e, value) => onChnageResourseassignee(e, value)}
                    disabled={
                      formValue?.resourceAssigneeTypeId == 0 ? true : false
                    }
                  ></RadioButton>
                </RadioGroup>
              </Grid>
            </Grid>

            {formValue?.resourceAssigneeTypeId == 1 && (
              <Grid container mt={2}>
                <Grid item lg={12} xs={12} sm={12} md={12}>
                  <FormLabels label="Asset Type" />
                  <RadioGroup row name="radio-user-type" className="radioGroup">
                    {PRODUCT_ID() == 2 && (
                      <RadioButton
                        checked={formValue.assetTypeId === 5}
                        value={5}
                        label="Projects"
                        onChange={(e, value) => onChangeAssetTypeId(e, value)}
                      ></RadioButton>
                    )}
                    {PRODUCT_ID() == 1 && (
                      <>
                        <RadioButton
                          checked={formValue.assetTypeId === 1}
                          value={1}
                          label="Projects"
                          onChange={(e, value) => onChangeAssetTypeId(e, value)}
                          disabled={true}
                        ></RadioButton>
                        <RadioButton
                          checked={formValue.assetTypeId === 3}
                          value={3}
                          label="Vehicles"
                          onChange={(e, value) => onChangeAssetTypeId(e, value)}
                          disabled={true}
                        ></RadioButton>
                        <RadioButton
                          checked={formValue.assetTypeId === 2}
                          value={2}
                          label="Equipment"
                          onChange={(e, value) => onChangeAssetTypeId(e, value)}
                          disabled={true}
                        ></RadioButton>
                        <RadioButton
                          checked={formValue.assetTypeId === 4}
                          value={4}
                          label="Custom"
                          onChange={(e, value) => onChangeAssetTypeId(e, value)}
                          disabled={true}
                        ></RadioButton>
                      </>
                    )}
                  </RadioGroup>
                </Grid>
              </Grid>
            )}
            {formValue?.resourceAssigneeTypeId == 0 && (
              <>
                <Grid item lg={6} xs={12} sm={12} md={6}>
                  <FormLabels label="Select User Type" />
                  <RadioGroup row name="radio-user-type" className="radioGroup">
                    <RadioButton
                      checked={formValue.userType === 1}
                      value={1}
                      label="Individual"
                      onChange={(e, value) => onChangeUserType(e, value)}
                    ></RadioButton>

                    <RadioButton
                      checked={formValue.userType === 2}
                      value={2}
                      label="User Group"
                      onChange={(e, value) => onChangeUserType(e, value)}
                    ></RadioButton>

                    <RadioButton
                      checked={formValue.userType === 3}
                      value={3}
                      label="All User"
                      onChange={(e, value) => onChangeUserType(e, value)}
                    ></RadioButton>
                  </RadioGroup>
                </Grid>
              </>
            )}
          </Grid>

          {formValue.userType === 1 &&
            formValue?.resourceAssigneeTypeId == 0 && (
              <Grid item container mt={0} spacing={2}>
                <Grid item lg={4} xs={12} sm={12} md={4}>
                  <FormLabels label="Assign to" isRequired={true} />
                  <SearchAutoComplete
                    name="Add Individual Users"
                    optionsData={allUsers?.users ?? []}
                    typeOnchange={(event, value) =>
                      handleAllUserSearch(event?.target?.value.trim())
                    }
                    isKeyValuePair={true}
                    value={users}
                    onChange={(e, value) => onChangeUsers(value)}
                    isLoading={isLoading}
                  />
                  {isindividualUsersIds && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  className="smallAddBtn"
                  lg={1.5}
                  xs={12}
                  sm={12}
                  mt={3}
                  md={4}
                >
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={"Add"}
                    onClick={() => addIndiVidualUserData()}
                    id="btnAddEquipment"
                    varientAddIconBlue={true}
                  ></Buttons>
                </Grid>
                <Grid pl={2} mt={3} container gap={1}>
                  <UsersChip
                    userList={userList}
                    handleDelete={(e) => deleteUsers(e)}
                  />
                </Grid>
              </Grid>
            )}

          {formValue.userType === 2 &&
            formValue?.resourceAssigneeTypeId == 0 && (
              <Grid item container mt={0} spacing={2}>
                <Grid item lg={4} xs={12} sm={12} md={4}>
                  <FormLabels label="Add User Group" isRequired={true} />
                  <SearchAutoComplete
                    name="Add User Group"
                    optionsData={allGroupsUser?.groupList ?? []}
                    typeOnchange={(event, value) =>
                      handleAllGroupSearch(event.target.value, 0, true)
                    }
                    isKeyValuePair={true}
                    value={usersGroup}
                    onChange={(e, value) => onChangeUsersGroup(value)}
                    isLoading={isUserGropLoading}
                    noOptionsTextMsg="No group"
                  />
                  {isGroupIds && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  className="smallAddBtn"
                  lg={1.5}
                  xs={12}
                  sm={12}
                  mt={3}
                  md={4}
                >
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={"Add"}
                    onClick={() => addGroupUserData()}
                    id="btnAddEquipment"
                    varientAddIconBlue={true}
                  ></Buttons>
                </Grid>
                <Grid pl={2} mt={3} container gap={1}>
                  <UsersChip
                    userList={userGroupList}
                    handleDelete={(e) => deleteUsersGroup(e)}
                  />
                </Grid>
              </Grid>
            )}

          {formValue?.resourceAssigneeTypeId == 1 && PRODUCT_ID() == 1 && (
            <Grid item container mt={0} spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormLabels label="Asset Name" isRequired={true} />
                <SearchAutoComplete2
                  name="Add Asset Name"
                  optionsData={assetOption ?? []}
                  isKeyValuePair={true}
                  value={assetsList[0]}
                  typeOnchange={(event) =>
                    searchAsset(
                      event.target.value,
                      PRODUCT_ID() == 2 ? 5 : formValue?.assetTypeId
                    )
                  }
                  onChange={(event, value) => onChangeAssets(event, value)}
                  isLoading={isLoading}
                  autoCompClear={autoCompAsset}
                  disabled={true}
                />
                {isassignAssets && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
              </Grid>
              <Grid
                className="smallAddBtn"
                item
                lg={1.6}
                xs={12}
                sm={12}
                mt={3}
                md={4}
              ></Grid>
            </Grid>
          )}
        </Grid>

        <Grid item container spacing={2} mt={2}>
          <Grid item lg={6} xs={12} sm={12} md={4}>
            <FormLabels label={"Expiration Date"} isRequired={false} />
            <DatePicker
              value={expirationDate ? expirationDate : null}
              maxDateEnable
              minDate={getCurrentDate()}
              onChangeHandler={(e) => onchangeExpirationdatePicker(e)}
            />
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={4} mt={4}>
            <Checkbox
              checked={checkBox}
              onChange={() => setcheckBox(!checkBox)}
              label={"Do not notify users about updation"}
            />
          </Grid>
        </Grid>

        <Grid container mt={3}>
          {isNewVersion && (
            <Grid item md={12} mb={3}>
              <Grid item md={6}>
                <FormLabels label="Edit Resource" isRequired={true} />
                <FormDropZone
                  paraText1={"Drop the files here ..."}
                  paraText2={"Drop the files here, "}
                  paraText3={"or Browse"}
                  handleFileChange={handleFileChange}
                  fileType={10}
                  imageUrl={formValue?.fileUrls}
                  deleteImageURL={(url) => handleDeleteImageURL()}
                  id="editResources"
                  key="editResources"
                  isMuliple={false}
                  isDoc={true}
                  isImage={false}
                  allFiles={true}
                />
                <p class="fileTypeSupport">
                  Supported file types: doc, docx, ppt, pptx, xls, xlsx,
                  zip,images, or pdf
                </p>
                {!formValue?.fileUrls && dropboxError && (
                  <div className="errorMsg">Add Resource is Required</div>
                )}
              </Grid>
            </Grid>
          )}
          {!isNewVersion && (
            <Grid item className="versionBtn" xs={12} mb={3}>
              <Buttons
                variantOutlineSections={true}
                label={"Add New Version"}
                onClick={addVersion}
                disabled={
                  viewResourceData?.resourceVersions?.length === 3
                    ? true
                    : false
                }
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <BackBtn dataBack={dataBack} />
          </Grid>
          <Grid item container mt={3} xs={12} md={6}>
            <Grid item lg={6} xs={12} sm={6} md={6}>
              <Buttons
                varientText={true}
                label="Cancel"
                onClick={() => navigate(-1)}
              />
            </Grid>
            <Grid item lg={6} xs={12} sm={6} md={6}>
              <Buttons
                varientContained={true}
                disabled={isSubmit ? true : false}
                label={"Save"}
                onClick={submitData}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default EditResource;
