import React, { useEffect, useState } from "react";
import * as credentialAction from "../../../redux/actions/credentialAction";
import CredentialLookup from "../component/credentialLookup";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import { DASHBOARD } from "../../../constant/routeContant";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { toast, ToastContainer } from "react-toastify";

export default function CredentialLookupContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendEmail, setSendEmail] = useState(false);

  const isAllowed = checkPrivileges([12, 13, 47, 48]);
  const credentialsByUsers = useSelector(
    (state) => state.getCredentialListByUserData?.data
  );
  const isEmailSuccessCredentialsByUsers = useSelector(
    (state) => state.getCredentialListByUserData?.isEmailSuccess
  );
  const credentialsByCredentialsData = useSelector(
    (state) => state.getCredentialListByCredentialsData?.data
  );
  const isEmailSuccessUsersByCredentials = useSelector(
    (state) => state.getCredentialListByCredentialsData?.isEmailSuccess
  );

  const allUsers = useSelector((state) => state.allUsers);

  const searchUsers = (searchType, searchText) => {
    dispatch(userAction.searchUsers(searchType, searchText));
  };
  function handleAllUserSearch(event, value) {
    dispatch(userAction.searchAllUsers(event.target.value));
  }

  const credentialListData = useSelector(
    (state) => state.getSearchCredentialData
  );
  const isLoading = allUsers.isLoading || credentialListData.isLoading;
  const searchOption = useSelector((state) => state.users.data);
  const getCredentials = (data) => {
    dispatch(credentialAction.getSearchCredentialList(data));
  };

  const getCredListByUser = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(credentialAction.getCredentialListByUsers(data));
  };

  const getCredListByCredential = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(credentialAction.getCredentialListByCredentials(data));
  };
  const getAllUsers = (searchText, type, divisionId) => {
    dispatch(userAction.searchAllUsers(searchText, type, divisionId));
  };
  useEffect(() => {
    if (!isAllowed) {
      navigate(DASHBOARD);
    }
    searchUsers("", "");
  }, []);

  const isUserLoading = useSelector(
    (state) => state.getCredentialListByUserData?.isLoading
  );
  const isCredLoading = useSelector(
    (state) => state.getCredentialListByCredentialsData?.isLoading
  );

  useEffect(() => {
    if (isEmailSuccessUsersByCredentials && sendEmail) {
      toast("Users by credential has been sent to registered email ID");
    }
  }, [isEmailSuccessUsersByCredentials]);

  useEffect(() => {
    if (isEmailSuccessCredentialsByUsers && sendEmail) {
      toast("Credentials by user  has been sent to registered email ID");
    }
  }, [isEmailSuccessCredentialsByUsers]);

  return (
    <>
      {checkPrivileges([12, 13, 47, 48]) ? (
        <>
          <ToastContainer />
          <CredentialLookup
            credentialsByUsers={credentialsByUsers}
            credentialsByCredentialsData={credentialsByCredentialsData}
            handleAllUserSearch={handleAllUserSearch}
            allUsers={allUsers && allUsers?.data}
            searchOption={searchOption?.users || []}
            getCredentials={getCredentials}
            credentialListData={credentialListData ?? []}
            isUserLoading={isUserLoading}
            isCredLoading={isCredLoading}
            getCredListByUser={getCredListByUser}
            getCredListByCredential={getCredListByCredential}
            isLoading={isLoading}
            onSearch={getAllUsers}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
