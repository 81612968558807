import React, { useState } from "react";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../../library/common/Loader";
import {
  PAGE_SIZE,
  ORG_ID,
  LOGIN_ORG_ID,
  USER_ID,
  ROW_PER_PAGE_SIZE,
  CLIENT_SATATUS_FILTER,
} from "../../../../constant/constant";
import NORow from "../../../../library/custom/noRow/noRow";
import TableRowAction from "../../../../library/custom/gridTable/TableRowAction";
import DialogBox from "../../../../library/common/DialogBox";
import { Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import FilterListIcon from "@mui/icons-material/FilterList";
import ServiceeFilterList from "../jha/serviceListFilter";
import downloadIcon from "../../../../assets/downloadIcon.svg";

const EquipmentTable = ({
  equipmentList,
  getEquipmentList,
  isLoading,
  filterShow,
  handleDeleteOnClick,
  handleEditOnClick,
  isProfile = false,
  divisionDropdown,
  isDivisionDropdown = true,
  equipmentCategoryData,
  isShowDowdownloadButton = true,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openFilter = Boolean(anchorEl);
  const id = openFilter ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [filterFormValue, setFilterFormValue] = useState({
    category: "",
    isActive: "",
  });

  const [isTableLoading, setisTableLoading] = useState(false);

  const [incSearchText, setIncSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [openModal, setOpenModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState("");

  const [division, setDivision] = useState(
    divisionDropdown ? divisionDropdown[0]?.key : 0
  );

  const columns = [
    { field: "makeAndModel", headerName: "Make and Model", flex: 1.5 },
    { field: "categoryValue", headerName: "Category", flex: 2 },
    { field: "equipmentId", headerName: "Equipment ID", flex: 1.5 },
    { field: "divisionName", headerName: "Division", flex: 1 },
    { field: "serviceStartDate", headerName: "Start Date", flex: 1.3 },
    { field: "isActive", headerName: "Status", flex: 1 },

    {
      field: "",
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return filterShow ? (
          <TableRowAction
            rows={rows}
            deleted={checkPrivileges([52, 12])}
            edit={checkPrivileges([53, 52, 12])}
            editOnClick={(rows) => handleEditOnClick(rows)}
            deleteOnClick={() => deleteEquipment(rows?.row)}
            editLabel="Edit Equipment"
            deleteLabel="Delete Equipment"
          />
        ) : (
          ""
        );
      },
    },
  ];

  const deletehandle = () => {
    setOpenModal(false);
    handleDeleteOnClick(selectedRow.equipmentId);
  };
  const deleteEquipment = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setIncSearchText(searchText);

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getEquipmentList({
      pageSize: pageSize,
      search: event.target.value,
      divisionId: division === 1 ? 0 : division,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      page: 1,
      isSendMail: false,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      category: filterFormValue.category === "" ? 0 : filterFormValue.category,
      statusIds: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
    });
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getEquipmentList({
      pageSize: pageSize,
      search: incSearchText,
      divisionId: division === 1 ? 0 : division,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      page: newPage + 1,
      isSendMail: false,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      category: filterFormValue.category === "" ? 0 : filterFormValue.category,
      statusIds: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
    });
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getEquipmentList({
      pageSize: newPazeSize,
      search: incSearchText,
      divisionId: division === 1 ? 0 : division,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      page: 1,
      isSendMail: false,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      category: filterFormValue.category === "" ? 0 : filterFormValue.category,
      statusIds: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
    });
  };

  const onChangeDivision = (e) => {
    setDivision(e.target.value);
    setIncSearchText("");
    const data = {
      page: 1,
      pageSize: pageSize,
      search: "",
      isSendMail: false,
      divisionId: e.target.value === 1 ? 0 : e.target.value,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      userId: isProfile ? parseInt(USER_ID()) : 0,
      category: filterFormValue.category === "" ? 0 : filterFormValue.category,
      statusIds: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
    };
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getEquipmentList(data);
    setPage(1);
  };

  const sendEmail = () => {
    const data = {
      page: 1,
      pageSize: pageSize,
      search: incSearchText,
      isSendMail: true,
      divisionId: division === 1 ? 0 : division,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      userId: isProfile ? parseInt(USER_ID()) : 0,
      category: filterFormValue.category === "" ? 0 : filterFormValue.category,
      statusIds: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
    };
    getEquipmentList(data);
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={"Are you sure you want to delete this Equipment?"}
              view
            />
          )}
        </div>

        <div className="grid_main_body divisionWithsearch">
          <div className="div-asset-filter-container">
            <div className="div-filter-left">
              <Grid container>
                <Grid item lg={5} xs={12} sm={6} md={4} mr={2} pt={3}>
                  <div className="filter_of_caselist">
                    <div className="searchcaseid">
                      <label htmlFor="search">
                        <span>
                          <SearchIcon />
                        </span>
                        <div>
                          <input
                            id="search"
                            placeholder="Search"
                            type="text"
                            value={incSearchText}
                            className=""
                            onChange={handleautoSearch}
                            autoComplete="off"
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                </Grid>

                <Grid item lg={2} xs={12} sm={6} md={3} mr={2} pt={3}>
                  <div className="filter_of_caselist">
                    <div className="filter_btn divisionWithFilter">
                      <button
                        aria-describedby={id}
                        variant="contained"
                        type="button"
                        name="btn"
                        onClick={(e) => handleClick(e)}
                      >
                        <FilterListIcon id="icn" /> Filters
                      </button>

                      <ServiceeFilterList
                        id={id}
                        open={openFilter}
                        anchorEl={anchorEl}
                        handleClose={() => {
                          setAnchorEl(null);
                          window.close();
                        }}
                        handleApply={() => {
                          setPage(1);
                          const data = {
                            page: 1,
                            pageSize: pageSize,
                            search: incSearchText,
                            divisionId: division === 1 ? 0 : division,
                            organisationId: isProfile
                              ? LOGIN_ORG_ID()
                              : ORG_ID(),
                            userId: isProfile ? parseInt(USER_ID()) : 0,
                            category:
                              filterFormValue.category === ""
                                ? 0
                                : filterFormValue.category,
                            statusIds:
                              filterFormValue.isActive === 2
                                ? 0
                                : filterFormValue.isActive,
                          };
                          setisTableLoading(true);
                          setTimeout(() => {
                            setisTableLoading(false);
                          }, 1);
                          getEquipmentList(data);
                          setAnchorEl(null);
                          window.close();
                        }}
                        firstLabel="Category"
                        secondLabel="Status"
                        firstLebelFilter={equipmentCategoryData}
                        secondLebelFilter={CLIENT_SATATUS_FILTER}
                        firstLebelValue={filterFormValue.category}
                        secondLebelValue={filterFormValue.isActive}
                        firstLebelOnChanges={(e) =>
                          setFilterFormValue({
                            ...filterFormValue,
                            category: e.target.value,
                          })
                        }
                        secondLebelOnChanges={(e) =>
                          setFilterFormValue({
                            ...filterFormValue,
                            isActive: e.target.value,
                          })
                        }
                        handleClear={() => {
                          setFilterFormValue({
                            category: 0,
                            isActive: "",
                          });
                          setIncSearchText("");
                          setPage(1);
                          const data = {
                            page: 1,
                            pageSize: pageSize,
                            search: incSearchText,
                            divisionId: division === 1 ? 0 : division,
                            organisationId: isProfile
                              ? LOGIN_ORG_ID()
                              : ORG_ID(),
                            userId: isProfile ? parseInt(USER_ID()) : 0,
                            category: 0,
                            statusIds: "",
                          };

                          setisTableLoading(true);
                          setTimeout(() => {
                            setisTableLoading(false);
                          }, 1);
                          getEquipmentList(data);
                          setAnchorEl(null);
                          window.close();
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                {isDivisionDropdown && (
                  <Grid item lg={3} xs={12} sm={4} md={3}>
                    <FormControl fullWidth>
                      <FormLabels label="Select Division" isRequired={false} />
                      <SelectMenu
                        disabledDefault={true}
                        listData={divisionDropdown}
                        value={division === 0 ? 1 : division}
                        onchangehandler={(e) => onChangeDivision(e)}
                        isgroupplaceholder={true}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </div>
            {isShowDowdownloadButton && (
              <div className="div-filter-right-download" onClick={sendEmail}>
                <span>
                  <img
                    src={downloadIcon}
                    className="DownLoadLogo"
                    alt="DownLoad Logo"
                  />
                </span>
                Download Data
              </div>
            )}
          </div>
          {isTableLoading ? (
            ""
          ) : (
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.equipmentId}
                  rows={equipmentList?.equipmentlist ?? []}
                  rowCount={equipmentList?.itemsCount ?? 0}
                  columns={columns}
                  loading={isLoading}
                  page={page}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default EquipmentTable;
