import React from "react";
import "./reports.scss";
import Buttons from "../../../library/custom/button/Button";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import {
  API_STATUS,
  LOGIN_ORG_ID,
  ORG_ID,
  SELECT_USER_TYPE,
} from "../../../constant/constant";
import ReportCredentialList from "./reportCredentialList";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";

function ReportCredentialCount({
  NavigateToLookup,
  CredentialReportData,
  userGroups,
  getCredentialReports,
  getLookupCredentialReportData,
  getLookupCredentialReports,
  isLoading,
  PAGE_SIZES,
  handleAllGroupSearch,
}) {
  const [userType, setUserType] = useState(SELECT_USER_TYPE[0].key);
  const [credentialTabId, SetcredentialTabId] = useState("");
  const [names, Setnames] = useState("");
  const [groupId, SetgroupId] = useState("");
  const [showList, setShowList] = useState(false);
  const [group, setgroup] = useState(0);

  const onChangeSelectUserType = (e) => {
    setgroup("");
    const defaultData = {
      groupId: 0,
    };
    getCredentialReports(defaultData);
    setUserType(e.target.value);
  };

  const onChangeUsersGroup = (value) => {
    const defaultData = {
      groupId: value.key,
    };
    getCredentialReports(defaultData);
    setgroup(value.key);
  };

  useEffect(() => {
    if (getLookupCredentialReportData.status === API_STATUS.SUCCESS) {
      setShowList(true);
    }
  }, [getLookupCredentialReportData]);

  const OnClickHnadler = (group, tabId, name) => {
    SetcredentialTabId(tabId);
    Setnames(name);
    SetgroupId(group);
    getLookupCredentialReports(group, tabId, "", "");
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Credential Report",
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
  });

  const isClient = ORG_ID() !== LOGIN_ORG_ID();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const clientLogo = localStorage.getItem("orgLogo");
  const clientName = localStorage.getItem("orgName");
  const logoCheck = isClient ? clientLogo : userInfo?.logo;

  return (
    <div ref={componentRef} className="innerReportAndAnalyticsPdf">
      <section className="grid_main_sec">
        <Grid item md={12} className="clientPdfDetail">
          <Grid className="clientName">
            {isClient ? clientName : userInfo?.clientName}
          </Grid>
          <Grid className="clientLogo">
            {logoCheck && <img src={logoCheck} alt="client Log" />}
          </Grid>
        </Grid>
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style repotPdfHeading">
            Credential Reports
          </div>

          <div>
            <div className="filter_btn removeElement">
              <Grid ml={50} lg={6} md={6}>
                <Buttons
                  primary={true}
                  id="btnAddClient"
                  label={"Credential Lookup"}
                  onClick={NavigateToLookup}
                ></Buttons>
              </Grid>
            </div>
          </div>
          <Grid className="removeElement">
            <Buttons
              primary={true}
              id="btnAddClient"
              label={"Download Report"}
              onClick={handlePrint}
            ></Buttons>
          </Grid>
        </div>
      </section>

      <Grid container mt={6} className="removeElement">
        <Grid item lg={4} xs={12} sm={12} md={4}>
          <FormControl fullWidth>
            <FormLabels label="Select User Type" isRequired={false} />
            <SelectMenu
              placeholder="Please Select"
              listData={SELECT_USER_TYPE}
              value={userType}
              onchangehandler={(e) => onChangeSelectUserType(e)}
            />
          </FormControl>
        </Grid>
      </Grid>
      {userType == 2 && (
        <Grid container mt={2} className="removeElement">
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels label="Search Group" isRequired={false} />

              <SearchAutoComplete
                name="Search User Group"
                optionsData={isLoading ? [] : userGroups?.groupList ?? []}
                typeOnchange={(event) =>
                  handleAllGroupSearch(event.target.value, 0, true)
                }
                isKeyValuePair={true}
                value={group}
                onChange={(e, value) => onChangeUsersGroup(value)}
                isLoading={isLoading}
                noOptionsTextMsg="No Groups"
              />
            </FormControl>
          </Grid>
        </Grid>
      )}

      <div className="reportBody">
        <div
          className="reportCardBody"
          onClick={() => {
            OnClickHnadler(group, "0", "Total Credential");
          }}
        >
          <div className="text">Total Credentials</div>
          <div className="number">
            {CredentialReportData?.data?.result?.totalCredentials}
          </div>
        </div>

        <div
          className="reportCardBody"
          onClick={() => {
            OnClickHnadler(group, "1", "Expiring Credentials");
          }}
        >
          <div className="text">Expiring Credentials</div>
          <div className="number">
            {CredentialReportData?.data?.result?.expiringCredentials}
          </div>
        </div>

        <div
          className="reportCardBody"
          onClick={() => {
            OnClickHnadler(group, "2", "Expired Credentials");
          }}
        >
          <div className="text">Expired Credentials</div>
          <div className="number">
            {CredentialReportData?.data?.result?.expiredCredentials}
          </div>
        </div>

        <div
          className="reportCardBody"
          onClick={() => {
            OnClickHnadler(group, "3", "Assigned Credentials");
          }}
        >
          <div className="text">Assigned Credentials</div>
          <div className="number">
            {CredentialReportData?.data?.result?.assignedCredentials}
          </div>
        </div>

        <div
          className="reportCardBody"
          onClick={() => {
            OnClickHnadler(group, "4", "Configured Credentials");
          }}
        >
          <div className="text">Configured Credentials</div>
          <div className="number">
            {CredentialReportData?.data?.result?.configuredCredentials}
          </div>
        </div>
        <div
          className="reportCardBody"
          onClick={() => {
            OnClickHnadler(group, "5", "Incomplete Credentials");
          }}
        >
          <div className="text">Incomplete Credentials</div>
          <div className="number">
            {CredentialReportData?.data?.result?.incompleteCredentials}
          </div>
        </div>
      </div>
      {showList && (
        <Grid>
          <ReportCredentialList
            getLookupCredentialReportData={getLookupCredentialReportData}
            getLookupCredentialReports={getLookupCredentialReports}
            // isLoading={isLoading}
            credentialTabId={credentialTabId}
            groupId={groupId}
            PAGE_SIZES={PAGE_SIZES}
            namess={names}
          />
        </Grid>
      )}
    </div>
  );
}

export default ReportCredentialCount;
